import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/styleConstants";

const SimpleDataTableStyles = makeStyles((theme) => ({
  // Table
  tableComponent: {
    maxWidth: 890,
    marginTop: 20,
    borderRadius: 10,
    overflow: "hidden",
    boxShadow: theme.shadows[3],
    marginBottom: 20,
    marginLeft: 17 
  },
  table: {
    maxWidth: 1065,
  },
  tableHead: {
    textAlign: "center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    color: theme.palette.primary.gray_30,
    textTransform: "uppercase;",
    borderBottom: `1px solid ${theme.palette.primary.divider}`,
  },
  tableBody: {
    textAlign: "center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.primary.divider}`,
    borderRadius: 20,
  },
  skeleton: {
    width: "98%",
    height: "30px",
    marginBottom: theme.spacing(1),
  },
  paid: {
    backgroundColor: theme.palette.primary.main_green_b,
    border: `1px solid ${theme.palette.primary.main_green}`,
    color: theme.palette.primary.main_green,
    textAlign: "center"
  },
  pending: {
    backgroundColor: theme.palette.primary.honey_gold_b,
    border: `1px solid ${theme.palette.primary.honey_gold}`,
    color: theme.palette.primary.honey_gold
  },
  cancelled: {
    backgroundColor: theme.palette.primary.main_red_b,
    border: `1px solid ${theme.palette.primary.main_red}`,
    color: theme.palette.primary.main_red
  },
  status: {
    height: 30,
    width: 73,
    borderRadius: '5px',
    fontSize:8,
    display: 'flex',
    justifyContent:"center",
    alignItems:"center",
    marginLeft:60
  },
}));

export default SimpleDataTableStyles;
