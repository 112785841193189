import React, { useEffect, useState } from "react";
import DrawerMenu from "../../components/DrawerMenu/Index";
import RoutesStyles from "./RoutesStyles";
import Skeleton from "@material-ui/lab/Skeleton";
import { globalStyles } from "../../theme/globalStyles/index";
import { MdLocationOn } from "react-icons/md";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import { CreateRoute } from "../../components/ModalBodies/CreateRoute/index";
import { DeleteRoute } from "../../components/ModalBodies/DeleteRoute/index";
import { RouteMap } from "../../components/ModalBodies/RouteMap/index";

import { Typography, Button, Box, Grid } from "@material-ui/core";
import CardRoutes from "../../components/sharedComponents/CardRoutes";
import { getAvailableRoutes } from "../../api/ApiRoutes";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../store/slices/clients";
import { setAvailableRoutes } from "../../store/slices/routes";
import { errors } from "../../utils/ErrorAxios";
import useLogout from "../../hooks/useLogout";

const Routes = () => {
  const classes = RoutesStyles();
  const globalClasses = globalStyles();
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  //modal para editar Ruta
  const [routesData, setRoutesData] = useState([]);
  const [openModalCreateRoute, setOpenModalCreateRoute] = useState(false);
  const handleOpenModalCreateRoute = () => {
    setOpenModalCreateRoute(true);
  };
  const handleCloseModalCreateRoute = () => {
    setOpenModalCreateRoute(false);
  };
  //Delete Route
  const [openModalDeleteRoute, setOpenModalDeleteRoute] = useState(false);
  const handleCloseModalDeleteRoute = () => {
    setActualIdRoute(0);
    setOpenModalDeleteRoute(false);
  };
  const handleOpenModalDeleteRoute = (idRoute) => {
    setOpenModalDeleteRoute(true);
    setActualIdRoute(idRoute);
  };
  //Route Map
  const [openModalRouteMap, setOpenModalRouteMap] = useState(false);
  const handleCloseModalRouteMap = () => {
    setActualIdRoute(0);
    setOpenModalRouteMap(false);
  };
  const handleOpenModalRouteMap = (idRoute) => {
    setActualIdRoute(idRoute);
    setOpenModalRouteMap(true);
  };

  //Modificaciar Ruta
  const [showListRoutes, setShowListRoutes] = useState(false);

  // const handleShowListRoutes = () => {
  //   setShowListRoutes(true);
  // };
  const [actualIdRoute, setActualIdRoute] = useState(0);
  const [logout] = useLogout();

  const handleActualIdRoute = (idRoute) => {
    setActualIdRoute(idRoute);
  };

  useEffect(() => {
    getRoutes();
    setTimeout(() => {
      getRoutes();
    }, 2000);
  }, [accessToken]);

  const getRoutes = async () => {
    try {
      const result = await getAvailableRoutes(accessToken);
      if (result.data.statusCode === 200) {
        setRoutesData(result.data.body);
        dispatch(setAvailableRoutes(result.data.body));
        setShowListRoutes(true);
      }
    } catch (error) {
      errors(error, "No se pudo obtener la información", logout);
    }
  };

  return (
    <DrawerMenu>
      <Box className={globalClasses.mainContainer}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Typography variant="h1" className={globalClasses.h1Title}>
            Listado de Rutas
          </Typography>
          <Grid item xs={12}>
            <Box className={classes.buttonsBox}>
              <Button
                className={classes.addRouteButton}
                variant="contained"
                startIcon={<MdLocationOn />}
                onClick={handleOpenModalCreateRoute}
                color="primary"
              >
                Crear Ruta
              </Button>
            </Box>
          </Grid>
          {showListRoutes ? (
            <Grid container className={classes.routesListContainer}>
              <Typography
                xs={12}
                variant="h5"
                className={globalClasses.h1SubTitle}
              >
                Rutas disponibles
              </Typography>
              <Grid container>
                {routesData.map((route) => (
                  <Grid item xs={2} sm={3} md={4} lg={4} xl={3}>
                    <CardRoutes
                      title={route.description}
                      numClients={route.total_clients}
                      idRoute={route.id_route}
                      handleOpenModalDeleteRoute={handleOpenModalDeleteRoute}
                      handleOpenModalRouteMap={handleOpenModalRouteMap}
                      handleActualIdRoute={handleActualIdRoute}

                      //  onClick={handleActualIdRoute(route.id_route)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : (
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              {Array.from(Array(8)).map((_, index) => (
                <Grid
                  item
                  xs={2}
                  sm={3}
                  md={4}
                  lg={4}
                  xl={3}
                  key={index.toString()}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    style={{ marginTop: 70, marginBottom: 100 }}
                  >
                    <Skeleton className={classes.SkeletonMapSection} />
                    <Skeleton className={classes.skeletonTitleSection} />
                    <Skeleton className={classes.skeletonSubtitleSection} />
                    <Skeleton
                      variant="text"
                      className={classes.skeletonButtonSection}
                    />
                    <Skeleton
                      variant="circle"
                      className={classes.skeletonDeleteButtonSection}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Box>

      <ModalComponent
        handleClose={handleCloseModalCreateRoute}
        open={openModalCreateRoute}
        body={
          <CreateRoute
            handleCloseModal={handleCloseModalCreateRoute}
            getRoutes={getRoutes}
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseModalDeleteRoute}
        open={openModalDeleteRoute}
        body={
          <DeleteRoute
            handleCloseModalDeleteRoute={handleCloseModalDeleteRoute}
            idRoute={actualIdRoute}
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseModalRouteMap}
        open={openModalRouteMap}
        body={
          <RouteMap
            handleCloseModalRouteMap={handleCloseModalRouteMap}
            IdRoute={actualIdRoute}
          />
        }
      />
    </DrawerMenu>
  );
};
export default Routes;
