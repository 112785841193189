import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { getCookie } from "../../utils/sessionCookie";
import { useSelector } from "react-redux";
import { selectLogedUserType } from "../../store/slices/user";

const PublicRoute = (props) => {
  const accessToken = getCookie("accessToken");
  const userType = useSelector(selectLogedUserType);

  if (accessToken) {
    return (
      <Redirect
        to={
          userType === 2
            ? ROUTES_CONSTANTS.ADMIN
            : ROUTES_CONSTANTS.PARTNER_DETAILS
        }
      />
    );
  }

  return <Route {...props} />;
};

export default PublicRoute;
