import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/styleConstants";

const InvoicesPaymentsStyles = makeStyles((theme) => ({
  main: {
    height: 490,
    width: 650,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: 20,
    overflowX: "hidden",
    overflowY: "auto",
  },
  closeIcon: {
    fontSize: 18,
    marginTop: 10,
    cursor: "pointer",
    position: 'fixed',
    marginLeft: 580 
  },
  titleTable:{
    fontSize:20, 
    color: theme.palette.primary.text_disable_medium,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    marginBottom: 18
  },
  idInvoice:{
    fontSize:12, 
    color: theme.palette.primary.disable_text,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM
  },
  invoice:{
    fontSize:14, 
    color: theme.palette.primary.disable_text,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM
  },
  //   Table
  data:{
    marginTop:40,
    paddingLeft:30 
  },

  tableComponent:{
    maxWidth: 555,
    marginTop:20,
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
    marginBottom: 100
  },
  table: {
    maxWidth: 555,
  },

  tableHead: {
    textAlign: "center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    color: theme.palette.primary.gray_70,
    textTransform: "uppercase;",
    borderBottom: `1px solid ${theme.palette.primary.divider}`,
  },
  tableBody:{
    textAlign:"center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.primary.divider}`,
    borderRadius: 20,
    color: theme.palette.primary.gray_70,
  },

}));

export default InvoicesPaymentsStyles;
