import React, { Fragment, useEffect, useState } from "react";
import AddClientsFiltersStyles from "./AddClientsFilters.styles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getPriceList, getRoutes } from "../../../api/apiAddClients";
import { errors } from "../../../utils/ErrorAxios";
import useLogout from "../../../hooks/useLogout";
import { getPaymentsFrecuency } from "../../../api/apiPayment_frequency";
import { weeklyDays } from "../../../constants/general";

const AddClientsFilters = (props) => {
  const {
    priceValue,
    setPriceValue,
    routeValue,
    setRouteValue,
    paymentValue,
    setPaymentValue,
    paymentDayValue,
    setPaymentDayValue,
    paymentsFrequency,
    setPaymentsFrequency,
  } = props;

  const classes = AddClientsFiltersStyles();
  const [logout] = useLogout();

  const [loader, setLoader] = useState(false);
  const [listPrice, setListPrice] = useState([]);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    async function getInformation() {
      setLoader(true);

      await getPriceList()
        .then((res) => {
          setListPrice(res.data.body);
        })
        .catch((err) => {
          errors(err, "No se pudo recuperar la lista de precios", logout);
        });

      await getRoutes()
        .then((res) => {
          setRoutes(res.data.body);
        })
        .catch((err) => {
          errors(err, "No se pudieron recuperar las rutas", logout);
        });

      await getPaymentsFrecuency()
        .then((res) => {
          console.log(res.data);
          setPaymentsFrequency(res.data.body);
        })
        .catch((err) => {
          errors(
            err,
            "No se pudieron recuperar las frecuencias de pago",
            logout
          );
        });

      setLoader(false);
    }

    getInformation();
  }, []);

  const handlePayment = (text) => {
    setPaymentDayValue("");
    setPaymentValue(text);
  };

  return (
    <Box className={classes.container}>
      <FormControl className={classes.formControl}>
        {!loader ? (
          <Fragment>
            <InputLabel id="demo-simple-select-label">
              Lista de precios:
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="sdfkljsdilfjsd"
              value={priceValue}
              onChange={(e) => setPriceValue(e.target.value)}
            >
              <MenuItem value="">ELIGE UNA OPCIÓN</MenuItem>
              {listPrice?.map((lp, index) => (
                <MenuItem key={index} value={lp.id_price_list}>
                  {lp.description}
                </MenuItem>
              ))}
            </Select>
          </Fragment>
        ) : (
          <Skeleton animation="wave" variant="rectangular" height={30} />
        )}
      </FormControl>
      <FormControl className={classes.formControl}>
        {!loader ? (
          <Fragment>
            <InputLabel id="demo-simple-select-label">Rutas</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="sasad"
              value={routeValue}
              onChange={(e) => setRouteValue(e.target.value)}
            >
              <MenuItem value="">ELIGE UNA OPCIÓN</MenuItem>
              {routes?.map((route, index) => (
                <MenuItem key={index} value={route.id_route}>
                  {route.description}
                </MenuItem>
              ))}
            </Select>
          </Fragment>
        ) : (
          <Skeleton animation="wave" variant="rectangular" height={30} />
        )}
      </FormControl>
      <FormControl className={classes.formControl}>
        {!loader ? (
          <Fragment>
            <InputLabel id="demo-simple-select-label">
              Frecuencia de Pago
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="sasad"
              value={paymentValue}
              onChange={(e) => handlePayment(e.target.value)}
            >
              <MenuItem value="">ELIGE UNA OPCIÓN</MenuItem>
              {paymentsFrequency?.map((payment, index) => (
                <MenuItem key={index} value={payment.payment_frequency_id}>
                  {payment.frequency_option}
                </MenuItem>
              ))}
            </Select>
          </Fragment>
        ) : (
          <Skeleton animation="wave" variant="rectangular" height={30} />
        )}
      </FormControl>
      {paymentValue === "1" && (
        <FormControl className={classes.formControl}>
          <Fragment>
            <InputLabel id="demo-simple-select-label">Día de Pago</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="sasad"
              value={paymentDayValue}
              onChange={(e) => setPaymentDayValue(e.target.value)}
            >
              <MenuItem value="">ELIGE UNA OPCIÓN</MenuItem>
              {weeklyDays?.map((day, index) => (
                <MenuItem key={index} value={day.value}>
                  {day.label}
                </MenuItem>
              ))}
            </Select>
          </Fragment>
        </FormControl>
      )}
    </Box>
  );
};

export default AddClientsFilters;
