import React from "react";
import FinanceCardStyles from "./FinanceCard.styles";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@mui/material";

const FinanceCard = ({ title, number, image, loading }) => {
  const classes = FinanceCardStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.textContainer}>
        {loading ? (
          <>
            <Skeleton variant="text" width={100} height={30} />
            <Skeleton variant="text" width={50} height={30} />
            <Skeleton variant="text" width={150} height={20} />
          </>
        ) : (
          <>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.number}>{number}</Typography>
          </>
        )}
      </Box>
      <Box className={classes.imageContainer}>
        {loading ? (
          <Skeleton variant="circular" width={60} height={60} />
        ) : (
          <img src={image} alt="imagen" />
        )}
      </Box>
    </Box>
  );
};

export default FinanceCard;
