export const ROUTES_CONSTANTS = {
  SPLASH: "/",
  LOGIN: "/iniciarSesion",
  ADMIN: "/administrador/",
  CLIENTS: "/administrador/clientes",
  ADD_CLIENTS:'/administrador/agregarClientes',
  CLIENTS_DETAILS: "/administrador/clientes/:idClient",
  ROUTES: "/administrador/rutas",
  INVOICE: "/administrador/facturas",
  INVENTORY: "/administrador/inventario",
  USERS: "/administrador/usuarios",
  PAYMENTS: "/administrador/cobranza",
  REMAINING_PAYMENTS: "/administrador/fichas",
  AUDITS: "/administrador/auditoria",
  PAYMENTS_ZONE: "/administrador/zona_cobranza",
  CLIENT_INVOICE: "/administrador/clientes/facturas/:userNameClient/:clientId",
  COMPANIES: "/administrador/cobranza/altaEmpresa",
  COMPANY_DETAILS: "/administrador/cobranza/altaEmpresa/detalles/:companyName/:companyId",
  PARTNER_DETAILS: "/partner/details",
  DAILY_COLLECTION: '/administrador/cobranza_diaria',
  PAGE_NOT_FOUND: "/page-not-found",
};
