import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/styleConstants";

const AdminCardStyles = makeStyles((theme)=> ({
    container:{
        display:"flex;",
        justifyContent:"space-around;",
        alignItems:"center;",
        width:270,
        height:103,
        background: "#fff",
        borderRadius: 5,
        borderLeft: `5px solid ${theme.palette.success.main}`,
    },
    textContainer:{

    },
    title:{
        fontSize: 16,
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
        color: theme.palette.primary.full_disable
    },
    number:{
        fontSize: 24,
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
        color: theme.palette.primary.dark
    },
    text:{
        fontSize: 11,
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        color: theme.palette.primary.main
    },
    textSecundary:{
        fontSize: 11,
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        color: theme.palette.primary.full_disable
    }
}))

export default AdminCardStyles;