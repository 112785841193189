import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useReducer, useState } from "react";
import DrawerMenu from "../../components/DrawerMenu/Index";
import { BsSearch } from "react-icons/bs";
// import DashboardCard from "../../components/sharedComponents/DashboardCard";
import invoiceStyles from "./Invoices.styles";
// import pieChart from "../../assets/images/pieChart.svg";
// import barChart from "../../assets/images/barChart.svg";
// import graphChart from "../../assets/images/graph.svg";
// import invoice from "../../assets/images/invoice.png";
// import amount from "../../assets/images/amount.svg";
// import moment from "moment";
import { createNewInvoice, getInvoices } from "../../api/ApiInvoices";
import LoaderAnimation from "../../assets/lottieFiles/loaderAnimation.json";
import { Link } from "react-router-dom";
// import { DataGrid } from "@material-ui/data-grid";
import InvoicesDatatable from "./InvoicesDatatable";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import PayInvoice from "../../components/ModalBodies/PayInvoice";
import AlertMessage from "../../components/sharedComponents/AlertMessage";
import { insertPaymentInvoice } from "../../api/ApiPayments";
import Createinvoice from "./CreateInvoice";
import { getClients } from "../../api/apiClients";
import {
  selectAccessToken,
  setAccessToken,
  setClients,
} from "../../store/slices/clients";
import { useDispatch, useSelector } from "react-redux";
import { BiAddToQueue } from "react-icons/bi";
import {
  resetProductsInformation,
  selectAreProductsOnCart,
  // selectClientForInvoice,
  // selectPaymentType,
  selectProductsSelected,
  // selectFileBase64
} from "../../store/slices/products";
import { setTotalInvoicesInfo } from "../../store/slices/invoices";
import moment from "moment";
import ContentAnimationCard from "../../components/sharedComponents/ContentAnimationCard";
import { ERROR_MESSAGES } from "../../constants/constantMessages";
import { setIsAuth } from "../../store/slices/user";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CancelInvoiceByIdModal from "../../components/ModalBodies/CancelInvoiceByIdModal";
import { errors } from "../../../src/utils/ErrorAxios";
import useLogout from "../../hooks/useLogout";
const Invoices = () => {
  const classes = invoiceStyles();
  const [selectAll, setSelectAll] = useState(true);
  const [selectDue, setSelectDue] = useState(false);
  const [selectPaid, setSelectPaid] = useState(false);
  const [selectUnpaid, setSelectUnpaid] = useState(false);
  const [selectCancelled, setSelectCancelled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const areProductsOnCart = useSelector(selectAreProductsOnCart);
  // const paymentType = useSelector(selectPaymentType);
  const selectedProducts = useSelector(selectProductsSelected);
  // const fileBase64 = useSelector(selectFileBase64)
  // const [totalInvoices, setTotalInvoices] = useState([]);
  // const [pendingInvoices, setPendingInvoices] = useState([]);
  // const [liquidateInvoices, setLiquidateInvoices] = useState([]);
  // const [currentMonthInvoices, setCurrentMonthInvoices] = useState([]);
  // const [currentMonthInvoicesAmount, setCurrentMonthInvoicesAmount] =
  //   useState(0);
  // useEffect(() => {
  //   getInvoices().then(async (res) => {
  //     let invoices = res.data.result;
  //     let currentInvoices = [];
  //     let totalCurrentInvoices = 0;
  //     let currentDate = moment("2021-11-01");
  //     const startOfMonth = currentDate.startOf("month").format("YYYY-MM-DD");
  //     const endOfMonth = currentDate.endOf("month").format("YYYY-MM-DD");
  //     await invoices.forEach((invoice) => {
  //       let invoice_date = moment(invoice.created_at);
  //       if (invoice_date.isBetween(startOfMonth, endOfMonth)) {
  //         currentInvoices.push(invoice);
  //         totalCurrentInvoices += parseInt(invoice.total_amount);
  //       }
  //     });

  //     setCurrentMonthInvoices(currentInvoices);
  //     setTotalInvoices(invoices && invoices);
  //     setPendingInvoices(
  //       invoices && invoices.filter((invoice) => invoice.status === 1)
  //     );
  //     setLiquidateInvoices(
  //       invoices && invoices.filter((invoice) => invoice.status === 0)
  //     );
  //     setCurrentMonthInvoicesAmount(totalCurrentInvoices);
  //   });
  // }, []);
  const dispatch = useDispatch();
  const [totalInvoices, setTotalInvoices] = useState([]);
  const [totalFilterData, setTotalFilterData] = useState([]);
  const [openPayInvoice, setOpenPayInvoice] = useState(false);
  const [openCreatedInvoice, setOpenCreatedInvoice] = useState(false);
  const [currentInvoiceSelected, setCurrentInvoiceSelected] = useState({});
  const handleClosePayInvoice = () => setOpenPayInvoice(false);
  // const clientSelected = useSelector(selectClientForInvoice);
  const accesToken = useSelector(selectAccessToken);
  const [openCancelInvoiceModal, setOpenCancelInvoiceModal] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [selectedClientS, setSelectedClientS] = useState({})
  const [paymentTypeS, setPaymentTypeS] = useState(2)
  const [imageBase64, setImageBase64] = useState('')
  const [comments, setComments] = useState('')
  const [finishCancel, setFinishCancel] = useState(false);
  const [finishPay, setFinishPay] = useState(false);
  const history = useHistory();
  const [clientSelected, setClientSelected] = useState('')
  const handleOpenPayInvoice = (invoice, ) => {
    setClientSelected(invoice.name_client)
    setCurrentInvoiceSelected(invoice);
    setOpenPayInvoice(true);
  };
  const handleOpenInvoiceCreated = () => {
    setOpenCreatedInvoice(true);
  };
  const handleCloseInvoiceCreated = () => {
    setOpenCreatedInvoice(false);
  };
  const [logout] = useLogout();
  useEffect(() => {
    setIsLoading(true);
    getInvoices(accesToken)
      .then((res) => {
        setTotalInvoices(res.data.body);
        setTotalFilterData(res.data.body);
        dispatch(setTotalInvoicesInfo(res.data.body));
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [accesToken, dispatch, history, finishPay, finishCancel]);

  useEffect(() => {
    getClients(accesToken)
      .then((res) => {
        dispatch(setClients(res.data.body));
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      })
  }, [accesToken, dispatch, history]);
  useEffect(() => {
    dispatch(resetProductsInformation());
  }, [dispatch]);

  if (
    !selectAll &&
    !selectDue &&
    !selectPaid &&
    !selectUnpaid &&
    !selectCancelled
  ) {
    setSelectAll(true);
  }
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setTotalFilterData(totalInvoices);
    selectDue && setSelectDue(false);
    selectPaid && setSelectPaid(false);
    selectUnpaid && setSelectUnpaid(false);
    selectCancelled && setSelectCancelled(false);
  };
  // const handleSelectDue = () => {
  //   setSelectDue(!selectDue);
  //   //Filtering invoices by due ones.
  //   // setTotalFilterData(totalInvoices.filter((data) => data.status === 1));
  //   selectAll && setSelectAll(false);
  //   selectPaid && setSelectPaid(false);
  //   selectUnpaid && setSelectUnpaid(false);
  //   selectCancelled && setSelectCancelled(false);
  // };
  const handleSelectPaid = () => {
    setSelectPaid(!selectPaid);
    //Filtering invoices by paid ones.
    setTotalFilterData(totalInvoices.filter((data) => data.status === 2));
    selectDue && setSelectDue(false);
    selectAll && setSelectAll(false);
    selectUnpaid && setSelectUnpaid(false);
    selectCancelled && setSelectCancelled(false);
  };
  const handleSelectUnpaid = () => {
    setSelectUnpaid(!selectUnpaid);
    selectPaid && setSelectPaid(false);
    //Filtering invoices by unpaid ones.
    setTotalFilterData(totalInvoices.filter((data) => data.status === 1));
    selectDue && setSelectDue(false);
    selectAll && setSelectAll(false);
    selectCancelled && setSelectCancelled(false);
  };
  const handleSelectCancelled = () => {
    setSelectCancelled(!selectCancelled);
    //Filtering invoices by unpaid ones.
    setTotalFilterData(totalInvoices.filter((data) => data.status === 3));
    selectUnpaid && setSelectUnpaid(false);
    selectPaid && setSelectPaid(false);
    selectDue && setSelectDue(false);
    selectAll && setSelectAll(false);
  };

  const tableOptions = [
    { name: "Todas", onClick: handleSelectAll, id: 1 },
    // { name: "Por vencer", onClick: handleSelectDue, id: 2 },
    { name: "Pagadas", onClick: handleSelectPaid, id: 3 },
    { name: "Sin pagar", onClick: handleSelectUnpaid, id: 4 },
    { name: "Canceladas", onClick: handleSelectCancelled, id: 5 },
  ];

  const VARIANT_CLASSES = {
    1: selectAll
      ? `${classes.linkItem} ${classes.selectedItem}`
      : classes.linkItem,
    2: selectDue
      ? `${classes.linkItems} ${classes.selectedItem}`
      : classes.linkItems,
    3: selectPaid
      ? `${classes.linkItems} ${classes.selectedItem}`
      : classes.linkItems,
    4: selectUnpaid
      ? `${classes.linkItems} ${classes.selectedItem}`
      : classes.linkItems,
    5: selectCancelled
      ? `${classes.linkItems} ${classes.selectedItem}`
      : classes.linkItems,
  };

  const headCells = [
    {
      id: "id_invoice",
      numeric: true,
      disablePadding: false,
      label: "ID",
    },
    {
      id: "name_client",
      numeric: false,
      disablePadding: true,
      label: "Cliente",
    },
    {
      id: "type_payment",
      numeric: false,
      disablePadding: false,
      label: "Tipo de pago",
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: "Fecha de emisión",
    },
    {
      id: "expiration_date",
      numeric: false,
      disablePadding: false,
      label: "Fecha de expiración",
    },
    {
      id: "total_amount",
      numeric: true,
      disablePadding: false,
      label: "Cantidad",
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Estado",
    },
    {
      id: "remaining_payment",
      numeric: true,
      disablePadding: false,
      label: "Saldo Pendiente",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Acciones",
    },
  ];

  const [open, setOpen] = useState(false);
  const [openCreateInvoice, setOpenCreateInvoice] = useState(false);

  const handleClickInvoice = () => setOpenCreateInvoice(!openCreateInvoice);

  const handleOpenAlert = () => {
    setOpen(true);
  };

  const handleClickOpenCancelInvoiceModal = (invoice) => {
    setOpenCancelInvoiceModal(true);
    setCurrentInvoice(invoice);
  };

  const handleClickCloseCancelInvoiceModal = () => {
    setOpenCancelInvoiceModal(false);
    setCurrentInvoice(null);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const snackBarReducer = (state, action) => {
    switch (action.type) {
      case "SUCCESS":
        return { ...state, ...action.value };
      case "ERROR":
        return { ...state, ...action.value };
      case "INITIAL":
        return { ...state, open: false };
      default:
        return { ...state };
    }
  };
  const [snackBarState, dispatchSnackBar] = useReducer(snackBarReducer, {
    open: false,
    type: "",
    message: "",
  });

  const handleSubmitPayment = (payload) => {
    setFinishPay(true)
    setIsLoading(true);
    insertPaymentInvoice(payload, accesToken)
      .then((res) => {
        dispatchSnackBar({
          type: "SUCCESS",
          value: {
            open: open,
            type: "success",
            message: "El abono se realizó con éxito",
          },
        });
        setIsLoading(false);
        handleClosePayInvoice();
        setFinishPay(false)
        setTimeout(() => {
          dispatchSnackBar({
            type: "INITIAL",
            value: {
              open: false,
              type: "",
              message: "",
            },
          });
        }, 6000);
      })
      .catch((err) => {
        errors(err, "No se pudo actualizar el estado del cliente", logout); 
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleClickCreateInvoice = async () => {
    let productsToSend = selectedProducts.map((prod) => ({
      idArticle: prod.idPrice,
      quantity: prod.quantity,
      price: prod.price,
      idWarehouse: 1,
    }));
  
    
    let today = moment().format("YYYY-MM-DD hh:mm:ss");
    let payload = {
      idClient: parseInt(selectedClientS.id_client),
      typePayment: paymentTypeS,
      status: 1,
      expirationDate: today,
      discount: 0,
      detailInvoice: productsToSend,
      fileBase64: imageBase64,
      comments: comments
    };
  
  
    await createNewInvoice(payload, accesToken)
      .then(async (res) => {
        let isLoading = false;
        getInvoices(accesToken)
          .then((res) => {
            dispatch(setTotalInvoices(res.data.body));
          })
          .catch(({ err }) => {
            errors(err, "No se pudo obtener la información", logout);
          })
         
        handleOpenInvoiceCreated();
        handleOpenAlert();
        setTimeout(() => {
          handleCloseInvoiceCreated();
          dispatchSnackBar({
            type: "SUCCESS",
            value: {
              open: open,
              type: "success",
              message: "La factura se creó con éxito",
            },
          });
          isLoading = true;
        }, 3000);
        setTimeout(() => {
          if (isLoading) {
            dispatchSnackBar({
              type: "INITIAL",
              value: {
                open: false,
                type: "",
                message: "",
              },
            });
          }
        }, 7000);
      })
      .catch((err) => {
        dispatchSnackBar({
          type: "INITIAL",
          value: {
            open: false,
            type: "",
            message: "",
          },
        });
        throw new Error("Something happened on insert invoices ", err);
      });
    dispatch(resetProductsInformation());
    handleClickInvoice();
  };
  const handleCancelInvoice = () => {
    dispatch(resetProductsInformation());
    handleClickInvoice();
  };
  const handleSearch = (field_name) => {
    let filteredClients = !field_name
      ? totalInvoices
      : totalInvoices.filter((invoice) =>
          invoice.name_client.toLowerCase().includes(field_name.toLowerCase())
        );
    setTotalFilterData(filteredClients);
  };

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="subtitle2" className={classes.titleContainer}>
          Facturas
        </Typography>

        <Box
          display="flex"
          alignItems="space-around"
          flexDirection="row"
          className={classes.menubarItems}
        >
          {!openCreateInvoice &&
            tableOptions.map((item) => (
              <Link
                variant="body1"
                key={item.id}
                onClick={item.onClick}
                className={VARIANT_CLASSES[item.id]}
              >
                <Typography variant="subtitle2" align="center">
                  {item.name}
                </Typography>
              </Link>
            ))}
          <Button
            variant="contained"
            color="primary"
            className={
              openCreateInvoice
                ? classes.cancelInvoiceButtonOpen
                : classes.createInvoiceButton
            }
            onClick={
              !openCreateInvoice ? handleClickInvoice : handleCancelInvoice
            }
          >
            {openCreateInvoice ? "cancelar" : " Crear factura"}
          </Button>
          {openCreateInvoice && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<BiAddToQueue />}
              className={classes.createInvoiceButtonOpen}
              onClick={handleClickCreateInvoice}
              disabled={!areProductsOnCart || !imageBase64}
            >
              Crear Factura
            </Button>
          )}
        </Box>
        {openCreateInvoice ? (
          <Createinvoice 
            setSelectedClientS={setSelectedClientS}
            paymentTypeS={paymentTypeS}
            setPaymentTypeS={setPaymentTypeS}
            setImageBase64={setImageBase64}
            setComments={setComments}
          />
        ) : (
          <React.Fragment>
            <Box
              className={classes.searchBarContainer}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <TextField
                name="searchbar"
                variant="outlined"
                placeholder="Buscar cliente por nombre"
                onChange={(e) => handleSearch(e.target.value.toLowerCase())}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="contrasena" edge="start">
                        <BsSearch />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={classes.searchBarInputfield}
              />
            </Box>
            <div>
              <InvoicesDatatable
                headCells={headCells}
                rows={totalFilterData}
                handleOpenPayInvoice={handleOpenPayInvoice}
                handleClickCancelInvoice={handleClickOpenCancelInvoiceModal}
                isLoading={isLoading}
              />
            </div>
          </React.Fragment>
        )}

        {/* <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md={3}>
            <DashboardCard
              title="Facturas totales"
              amount={totalInvoices.length}
              cardIcon={pieChart}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardCard
              title="Facturas Pendientes"
              amount={pendingInvoices.length}
              cardIcon={barChart}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DashboardCard
              title="Facturas Saldadas"
              amount={liquidateInvoices.length}
              cardIcon={graphChart}
              size="small"
            />
          </Grid>
          <Grid item md={2} />

          <Grid item xs={12} md={4} className={classes.invoiceCardsInfo}>
            <DashboardCard
              title="Facturas del mes"
              subtitle="Abril"
              cardIconStyle={classes.invoiceMonthIcon}
              amount={currentMonthInvoices.length}
              cardIcon={invoice}
              size="large"
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.invoiceCardsInfo}>
            <DashboardCard
              title="Total facturado del mes"
              subtitle="Abril"
              amount={currentMonthInvoicesAmount}
              cardIcon={amount}
              isNumber={true}
              size="large"
            />
          </Grid>

          <Grid item md={3} />
        </Grid> */}

        <AlertMessage
          open={open}
          handleClose={handleCloseAlert}
          {...snackBarState}
        />

        <ModalComponent
          handleClose={handleClosePayInvoice}
          open={openPayInvoice}
          body={
            <PayInvoice
              handleClose={handleClosePayInvoice}
              invoiceInfo={currentInvoiceSelected}
              dispatchSnackBar={dispatchSnackBar}
              openAlert={open}
              handleOpenAlert={handleOpenAlert}
              handleCloseAlert={handleCloseAlert}
              handleSubmitPayment={handleSubmitPayment}
              isLoading={isLoading}
              clientName={clientSelected}
            />
          }
        />
        <ModalComponent
          handleClose={handleCloseInvoiceCreated}
          open={openCreatedInvoice}
          body={
            <ContentAnimationCard
              cardTitle="Creando factura"
              cardBottomDescription="No cierre la pantalla por favor"
              animationFile={LoaderAnimation}
              animationStyles={classes.animationContainer}
            />
          }
        />
        {/* Cancel Invoice Modal */}
        <ModalComponent
          handleClose={handleClickCloseCancelInvoiceModal}
          open={openCancelInvoiceModal}
          body={
            <CancelInvoiceByIdModal
              handleClickCloseModal={handleClickCloseCancelInvoiceModal}
              invoiceinfo ={currentInvoice}
              clientName={clientSelected}
              setfinishCancel={setFinishCancel}
            />
          }
        />
      </Box>
    </DrawerMenu>
  );
};

export default Invoices;
