import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  IconButton,
  Button,
  Chip,
  Box,
  Tooltip,
  Typography,
  Switch,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { MdOutlineRemoveRedEye, MdPayment } from "react-icons/md";
import { LuUserX2, LuUserCheck2 } from "react-icons/lu";
import { RiLockPasswordLine } from "react-icons/ri";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { getComparator, stableSort } from "../../../utils/dataTableUtils";
import ClientsDataTableStyles from "./ClientsDataTable.styles";

const ClientsListDataTable = (props) => {
  const {
    openViewClientInfo, //ver información
    openDeleteClients, //cambiar estatus
    clients,
    filterClient,
    loading,
    openEditclients,
    openPaymentInvoicesInfo, //facturas
    massiveUpdate,
  } = props;

  const classes = ClientsDataTableStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id_client");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState("all");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setPage(0);
  };

  const handleStateChange = (client) => {
    openDeleteClients(client);
  };

  const renderSwitch = (client, state) => (
    <div style={{ display: "flex", alignItems: "center", marginLeft: 50 }}>
      <span>No</span>
      <Switch
        checked={state === 1}
        onChange={() => handleStateChange(client)}
        classes={{
          switchBase: classes.switchBase,
          checked: classes.checked,
          track: classes.track,
          thumb: classes.thumb,
        }}
        style={{ marginLeft: 5 }}
      />
      <span>Sí</span>
    </div>
  );

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, clients.length - page * rowsPerPage);

  const renderSkeletonRows = (count) => {
    return Array.from({ length: count }).map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "150px",
          }}
        >
          <Skeleton variant="circle" width={30} height={30} />
          <Skeleton
            variant="circle"
            width={30}
            height={30}
            style={{ marginLeft: 8 }}
          />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <Paper>
        <TableContainer component={Paper} style={{ overflowX: "auto" }}>
          <Table style={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "id_client"}
                    direction={orderBy === "id_client" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "id_client")}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      ID Cliente
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "name")}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      Nombre
                    </Typography>
                  </TableSortLabel>
                </TableCell>

                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "city"}
                    direction={orderBy === "city" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "city")}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      Municipio
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "zip_code"}
                    direction={orderBy === "zip_code" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "zip_code")}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      CP
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "status"}
                    direction={orderBy === "status" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "status")}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      Activo
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    className={classes.iconActionsContainer}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      {" "}
                      Acciones{" "}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? renderSkeletonRows(rowsPerPage)
                : stableSort(clients, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((client) => (
                      <TableRow key={client.id_client}>
                        <TableCell align="center">
                          <Typography className={classes.infoDataItem}>
                            {client.id_client}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.infoDataItem}>
                            {client.name.toLowerCase()}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.infoDataItem}>
                            {client.city.toLowerCase()}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.infoDataItem}>
                            {client.zip_code}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {renderSwitch(client, client.status)}
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            justifyContent="space-around"
                            alignItems="center"
                            className={classes.iconActionsContainer}
                          >
                            <Tooltip title="ver" placement="bottom">
                              <IconButton
                                className={classes.btnAction}
                                onClick={() => openViewClientInfo(client)}
                              >
                                <MdOutlineRemoveRedEye
                                  className={classes.actionIcons}
                                />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="facturas" placement="bottom">
                              <IconButton
                                disabled={client.status === 0}
                                className={classes.btnAction}
                                onClick={() =>
                                  openPaymentInvoicesInfo(
                                    client.id_client,
                                    client.name
                                  )
                                }
                              >
                                <LiaFileInvoiceDollarSolid
                                  className={`${
                                    client.status === 0
                                      ? classes.actionIconsDisabled
                                      : classes.actionIcons
                                  }`}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
              {!loading && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={clients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página:"
        />
      </Paper>
    </>
  );
};

export default ClientsListDataTable;
