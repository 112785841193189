import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/styleConstants";

const EditCompanyStyles = makeStyles((theme) => ({

  main: {
    maxHeight: 680,
    minHeight: 580,
    height: "100%",
    width: 520,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: 25,
    paddingLeft: 35,
    overflowX: "hidden",
    overflowY: "auto",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
      borderRadius: 10,
    },
  },
  closeIcon: {
    fontSize: 15,
    cursor: "pointer",
    position: "fixed",
    marginLeft: 450,
  },
  title: {
    fontSize: 24,
    color: theme.palette.primary.deep_pine,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    marginBottom: 24,
    marginTop: 15,
  },
  subTitle: {
    fontSize: 14,
    color: theme.palette.primary.text_disable_medium,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    marginTop: 1,
  },
  textQuestion: {
    fontSize: 14,
    color: theme.palette.primary.text_disable_medium,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    marginTop: 15,
  },
  input: {
    textAlign: "left",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: "12px !important",
  },
  textField: {
    "& .MuiInputLabel-root": {
      fontSize: 13,
      color: theme.palette.primary.text_disable_medium,
      fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
      // top: '50%', // Alinea verticalmente el label al centro
      // transform: 'translateY(-50%)', // Ajusta el label para que esté centrado
      // left: 10, // Ajusta la alineación horizontal del label
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 3, // Cambia el radio de borde aquí
      height: 40, // Ajusta la altura del TextField
      marginBottom: 8,
      "&.MuiInputLabel-root": {
        fontSize: 10,
        color: theme.palette.primary.disable_text,
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        top: '50%', // Alinea verticalmente el label al centro
        transform: 'translateY(-50%)', // Ajusta el label para que esté centrado
        left: 10, // Ajusta la alineación horizontal del label
      },
      "& fieldset": {
        borderColor: "#E3D4D4", // Cambia el color del borde aquí
      },
      "&:hover fieldset": {
        borderColor: "#E3D4D4", // Cambia el color del borde al hacer hover aquí
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E3D4D4", // Cambia el color del borde cuando el campo está enfocado
      },
    },
  },
  formControl: {
    "& .MuiInputLabel-root": {
      fontSize: 14,  // Tamaño de la fuente del label
      color: theme.palette.primary.disable_text, // Color del label
      fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR, // Familia de la fuente del label
      // top: '60%', // Alinea verticalmente el label al centro
      transform: 'translateY(-50%)', // Ajusta el label para que esté centrado
      left: 10, // Ajusta la alineación horizontal del label
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 3, // Radio del borde del Select
      height: 40, // Altura del Select
      "& fieldset": {
        borderColor: "#E3D4D4", // Color del borde
      },
      "&:hover fieldset": {
        borderColor: "#E3D4D4", // Color del borde al hacer hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E3D4D4", // Color del borde cuando está enfocado
      },
    },
    "& .MuiSelect-root": {
      fontSize: 14, // Tamaño de la fuente del Select
      color: theme.palette.text.primary, // Color del texto dentro del Select
      fontFamily: "Montserrat, sans-serif", // Familia de la fuente del Select
    },
  },  
  buttonC:{
    width: '323px',
    height: '40px',
    borderRadius: '2px 0px 0px 0px',
    background:theme.palette.primary.main,
    color:"#fff",
    fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize:14,
    marginTop:15,
    "&:hover": {
    background: theme.palette.primary.dark, // Cambia el color de fondo cuando se hace hover
    color: "#fff", // Cambia el color del texto cuando se hace hover
  }

  },
  helperText: {
    marginLeft: 0, // Alinea el helperText a la izquierda
    marginTop: "-5px", // Reduce el margen superior
  },


}));

export default EditCompanyStyles;
