import { makeStyles } from "@material-ui/core/styles";

const AddClientsFiltersStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  formControl: {
    width: 160,
    marginRight: 20
  },
}));

export default AddClientsFiltersStyles;
