import { makeStyles } from "@material-ui/core";

const AlertMesssageStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default AlertMesssageStyles;
