import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  availableUsers: [],
  logedUserInformation: {},
  userInformation: {
    id_user: "",
    username: "",
    name: "",
    id_user_type: "",
    password: "",
    created_at: "",
    id_route: "",
    date_deleted: "",
    status: "",
    company_id: "",
  },
  isAuth: false,
};

export const UserInformationSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setAvailableUsers(state, { payload }) {
      state.availableUsers = payload;
    },
    setSelectedUser(state, { payload }) {
      state.userInformation = payload;
    },
    setLogedUserInformation(state, { payload }) {
      state.logedUserInformation = payload;
    },
    setIsAuth(state, { payload }) {
      state.isAuth = payload;
    },
  },
});

export const {
  setAvailableUsers,
  setSelectedUser,
  setLogedUserInformation,
  setIsAuth,
} = UserInformationSlice.actions;

export const selectAvailableUsers = (state) =>
  state.users ? state.users.availableUsers : null;

export const selectCurrentUser = (state) =>
  state.users ? state.users.userInformation : null;

export const selectLogedUserInformation = (state) =>
  state.users ? state.users.logedUserInformation : null;

export const selectLogedUserType = (state) =>
  state.users ? state.users.logedUserInformation.idUserType : null;

export const selectLogedUserCompanyId = (state) =>
  state.users ? state.users.logedUserInformation.company_id : null;

export const selectUserId = (state) =>
  state.users.logedUserInformation
    ? state.users.logedUserInformation.idUser
    : null;

export const selectUserName = (state) =>
  state.users.logedUserInformation
    ? state.users.logedUserInformation.username
    : null;

export const selectIdRoute = (state) =>
  state.users.logedUserInformation
    ? state.users.logedUserInformation.idRuta
    : null;

export const selectIsAuth = (state) =>
  state.users ? state.users.isAuth : null;

export default UserInformationSlice.reducer;
