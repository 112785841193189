import toast from "react-hot-toast";
export const notification = (text, type = "error") => {
  let style = {
    borderRadius: "10px",
    background: "#333",
    color: "#fff",
    fontSize: 14,
    padding: 10,
    zIndex: 30000,
  };

  let options = {
    duration: 5000,
    position: "top-center",
    style: style,
  };

  if (type === "success") {
    return toast.success(text, options);
  } else if (type === "error") {
    return toast.error(text, options);
  } else {
    return;
  }
};
