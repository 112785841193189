import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_NAVICK_API_URL } = process.env;

export const getPaymentsFrecuency = () => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/payment_frequency`,
    headers: {
      "content-type": "application/json",
      token: getCookie("accessToken"),
    },
  };

  return axios(request);
};
