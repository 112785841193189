import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DrawerMenu from "../../components/DrawerMenu/Index";
import AuditStyles from "./Audits.styles";
import {
  selectAvailableRoutes,
  setAvailableRoutes,
} from "../../store/slices/routes";
import { CreateTablePDFAudits } from "../../utils/generalUtils";
import { getAuditReportsByRoute } from "../../api/apiClients";
import {
  resetAuditInformation,
  selectAccessToken,
  selectAuditInformation,
  selectAuditRouteSelected,
  setAuditInformation,
  setAuditRouteSelected,
} from "../../store/slices/clients";
import { formatNumber } from "../../utils/formatUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAvailableRoutes } from "../../api/ApiRoutes";

import { errors } from "../../utils/ErrorAxios";
import useLogout from "../../hooks/useLogout";
import { Skeleton } from "@material-ui/lab";
const Audits = () => {
  const classes = AuditStyles();
  const availableRoutes = useSelector(selectAvailableRoutes);
  const auditInformation = useSelector(selectAuditInformation);
  const selectedAuditRoute = useSelector(selectAuditRouteSelected);
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();

  // Estado local para manejar la lista de rutas
  const [routesData, setRoutesData] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Obtener rutas desde la API o usar las rutas del store
  const fetchRoutes = async () => {
    try {
      const result = await getAvailableRoutes(accessToken);
      if (result.data.statusCode === 200) {
        setRoutesData(result.data.body); // Rutas de la API
        dispatch(setAvailableRoutes(result.data.body));
      }
    } catch (error) {
      console.error("Error al obtener rutas desde la API:", error);
      // Si hay error, usar rutas del store (Redux)
      setRoutesData(availableRoutes);
    }
  };

  // Manejar selección de ruta
  const [logout] = useLogout();
  const handleSelectedRoute = (route) => {
    setSelectedRoute(route);
    setIsLoading(true);
    dispatch(setAuditRouteSelected(route));
    getAuditReportsByRoute(route.id_route, accessToken)
      .then((res) => {
        dispatch(setAuditInformation(res.data.body));
        setIsLoading(false);
      })
      .catch((err) => {
        errors(err, "Error fetching home data", logout);
      });
  };

  const renderSkeletonRows = (count) => {
    return Array.from({ length: count }).map((_, index) => (
      <TableRow key={index}>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
      </TableRow>
    ));
  };

  // Obtener rutas al montar el componente
  useEffect(() => {
    fetchRoutes();
    dispatch(resetAuditInformation()); // Reiniciar datos de auditorías
  }, [dispatch]);

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="h1" className={classes.auditTitle}>
          Auditorias
        </Typography>

        <Typography variant="h6" className={classes.subtitle}>
          Selecciona la ruta para la auditoria
        </Typography>

        {/* Dropdown para seleccionar la ruta */}
        <FormControl>
          <Select
            className={classes.routeDropdown}
            variant="outlined"
            value={selectedRoute || ""}
            //value={selectedAuditRoute || ""} // Usar valor seleccionado o vacío
            onChange={(e) => handleSelectedRoute(e.target.value)}
            displayEmpty
            inputProps={{
              name: "route",
              id: "route-name",
            }}
          >
            {/* Opción obligatoria que indica que debe seleccionar una ruta */}
            <MenuItem value="" disabled>
              Debes seleccionar una ruta
            </MenuItem>
            {routesData.length > 0 ? (
              routesData.map((route) => (
                <MenuItem key={route.id_route} value={route}>
                  {route.description}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="0" disabled>
                No hay rutas disponibles
              </MenuItem>
            )}
          </Select>
        </FormControl>

        {/* Botón para generar PDF */}
        <Box className={classes.createPDFButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => CreateTablePDFAudits("#audit-table", selectedRoute)}
            disabled={auditInformation && auditInformation.length <= 0}
          >
            Imprimir
          </Button>
        </Box>

        {/* Tabla de auditoría */}
        {/* <TableContainer>
          <Table id="audit-table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Saldo</TableCell>
                <TableCell>Abono</TableCell>
                <TableCell>Dirección</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auditInformation && auditInformation.length > 0 ? (
                auditInformation.map((info) => (
                  <TableRow key={info.v_id_client}>
                    <TableCell>{info.v_id_client}</TableCell>
                    <TableCell>{info.v_name || "SN"}</TableCell>
                    <TableCell>
                      ${formatNumber(info.v_remaining_payment)}
                    </TableCell>
                    <TableCell>{info.v_created_at}</TableCell>
                    <TableCell>{info.v_address}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No hay datos
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer> */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Saldo</TableCell>
                <TableCell>Abono</TableCell>
                <TableCell>Dirección</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? renderSkeletonRows(5) // Renderizar skeleton mientras está cargando
                : auditInformation.map((audit) => (
                    <TableRow key={audit.id}>
                      {/* <TableCell>{audit.id}</TableCell>
                      <TableCell>{audit.name}</TableCell>
                      <TableCell>{audit.date}</TableCell> */}
                      <TableCell>{audit.v_id_client}</TableCell>
                      <TableCell>{audit.v_name || "SN"}</TableCell>
                      <TableCell>
                        ${formatNumber(audit.v_remaining_payment)}
                      </TableCell>
                      <TableCell>{audit.v_created_at}</TableCell>
                      <TableCell>{audit.v_address}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </DrawerMenu>
  );
};

export default Audits;
