import React from "react";
import ConfirmModalStyles from "./ConfirmModalStyles";
import { Box, Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const ConfirmModal = (props) => {
  const {  title, description, handleCancel, handleConfirm, loading } = props;
  const classes = ConfirmModalStyles();
  return (
    <Box className={classes.mainContainer}>
      <IconButton className={classes.closeIconContainerMain} onClick={handleCancel}>
        <Close />
      </IconButton>
      <Box className={classes.confirmModalContainer}>
        <Typography className={classes.confirmModalTitle}>
          {title}
        </Typography>
        <Typography align="center" className={classes.confirmModalSubtitle}>
        {description}
        </Typography>
        <Box className={classes.buttonsGroupContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={`${classes.button} ${classes.cancelConfirmModalButton} ${classes.mr}`}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            className={`${classes.button} ${classes.cancelConfirmModalButton}`}
            onClick={handleConfirm}
          >
            Confirmar
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            className={`${classes.button} ${classes.cancelConfirmModalButton}`}
            onClick={handleConfirm}
            disabled={loading} // Deshabilita el botón cuando está cargando
          >
            {loading ? <CircularProgress size={24} color="white" /> : "Confirmar"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmModal;
