export const TIPO_USUARIO_VARIANTS = [
  { id: 1, desc: "super" },
  { id: 2, desc: "administrador" },
  { id: 3, desc: "editor" },
  { id: 4, desc: "visor" },
  { id: 5, desc: "app ruta" },
  { id: 6, desc: "app supervisor" },
  { id: 7, desc: "socio" },
];

export const STATUS_VARIANTS = [
  { id: 0, desc: "inactivo" },
  { id: 1, desc: "activo" },
];
