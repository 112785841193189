import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import EnhancedTableHead from "./EnhancedTableHead";
import InvoicesDatatableStyles from "./InvoicesDatatable.styles";
import { formatDate } from "../../../utils/formatUtils";
import {
  MdOutlineAttachMoney,
  MdOutlineRemoveCircleOutline,
} from "react-icons/md";
import { IconButton, Typography } from "@material-ui/core";
import CenteredContent from "../../../components/sharedComponents/CenteredContent";
import { INVOICES_STATUS } from "../../../constants/general";
import { Skeleton } from "@material-ui/lab";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function InvoicesDatatable(props) {
  const { headCells, rows, handleOpenPayInvoice, handleClickCancelInvoice, isLoading } =
    props;
  const classes = InvoicesDatatableStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const renderSkeletonRows = (count) => {
    return Array.from({ length: count }).map((_, index) => (
      <TableRow key={index}>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
      </TableRow>
    ));
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {isLoading ? (
                renderSkeletonRows(rowsPerPage) // Muestra filas de Skeleton cuando isLoading es true
              ) : rows.length <= 0 ? (
                <TableRow>
                  <TableCell colSpan={9}>
                    <CenteredContent>
                      <Typography className={classes.noData} align="center" variant="h1">
                        Sin datos
                      </Typography>
                    </CenteredContent>
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id_client)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.created_at}
                        selected={isItemSelected}
                      >
                        <TableCell align="center">{row.id_invoice}</TableCell>
                        <TableCell align="center">{row.name_client}</TableCell>
                        <TableCell align="center">{row.type_payment}</TableCell>
                        <TableCell align="center">{formatDate(row.created_at)}</TableCell>
                        <TableCell align="center">{formatDate(row.expiration_date)}</TableCell>
                        <TableCell align="center">{row.total_amount}</TableCell>
                        <TableCell align="center">{INVOICES_STATUS[row.status]}</TableCell>
                        <TableCell align="center">{row.remaining_payment}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleOpenPayInvoice(row, row.name_client)}
                            disabled={row.status === 2 || row.status === 3}
                          >
                            <MdOutlineAttachMoney
                              className={`${classes.icons} ${classes.invoiceIcon}`}
                            />
                          </IconButton>
                          <IconButton
                            disabled={row.status === 2 || row.status === 3}
                            onClick={() => handleClickCancelInvoice(row)}
                          >
                            <MdOutlineRemoveCircleOutline
                              className={`${classes.icons} ${classes.cancelIcon}`}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
            {/* {rows.length <= 0 ? (
              <TableBody className={classes.noDataBody}>
                <TableRow>
                  <TableCell colSpan={9}>
                    <CenteredContent>
                      <Typography
                        className={classes.noData}
                        align="center"
                        variant="h1"
                      >
                        Sin datos
                      </Typography>
                    </CenteredContent>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id_client)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.created_at}
                        selected={isItemSelected}
                      >
                        <TableCell align="center">{row.id_invoice}</TableCell>
                        <TableCell align="center">{row.name_client}</TableCell>
                        <TableCell align="center">{row.type_payment}</TableCell>
                        <TableCell align="center">
                          {formatDate(row.created_at)}
                        </TableCell>
                        <TableCell align="center">
                          {formatDate(row.expiration_date)}
                        </TableCell>
                        <TableCell align="center">{row.total_amount}</TableCell>
                        <TableCell align="center">
                          {INVOICES_STATUS[row.status]}
                        </TableCell>
                        <TableCell align="center">
                          {row.remaining_payment}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleOpenPayInvoice(row, row.name_client)}
                            disabled={row.status === 2 || row.status === 3}
                          >
                            <MdOutlineAttachMoney
                              className={`${classes.icons} ${classes.invoiceIcon}`}
                            />
                          </IconButton>
                          <IconButton
                            disabled={row.status === 2 || row.status === 3}
                            onClick={() =>
                              handleClickCancelInvoice(row)
                            }
                          >
                            <MdOutlineRemoveCircleOutline
                              className={`${classes.icons} ${classes.cancelIcon}`}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )} */}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
