import DrawerMenu from "../../components/DrawerMenu/Index";
import { Box, IconButton, Typography } from "@material-ui/core";
import AdministratorStyles from "./Administrator.styles";
import AdminCard from "../../components/sharedComponents/AdminCard";
import CustomBreadcrumbs from "../../components/sharedComponents/CustomBreadcrumbs";
import imageDevice from "../../assets/images/homeDevice.svg";
import imageDollar from "../../assets/images/HomeDollar.svg";
import imageChart from "../../assets/images/homeGraphic.svg";
import PieActiveArc from "../../components/sharedComponents/PieChart";
import HorizontalBars from "../../components/sharedComponents/BarChart";
import SimpleDataTable from "../../components/sharedComponents/SimpleDataTable";
import { GrRefresh } from "react-icons/gr";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectClientsHome,
  selectInvoicesHome,
  selectLastInvoices,
  selectLastUpdateInfo,
  selectMonthlySales,
  selectTotalInvoices,
  selectWeeklyCollection,
  setClientsHome,
  setInvoicesHome,
  setLastInvoices,
  setLastUpdateInfo,
  setMonthlySales,
  setTotalInvoices,
  setWeeklyCollection,
} from "../../store/slices/home";
import { getInfoHome } from "../../api/home";
import { selectAccessToken } from "../../store/slices/clients";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { errors } from "../../utils/ErrorAxios"
import useLogout from "../../hooks/useLogout";


const Administrator = () => {
  const classes = AdministratorStyles();
  const breadcrumbLinks = [
    { href: "/administrador", text: "Administrador" },
    { href: "/administrador", text: "Inicio" },
  ];
  const [loading, setLoading] = useState(false);
  const isUp = true;
  const clientsHome = useSelector(selectClientsHome);
  const weeklyCollection = useSelector(selectWeeklyCollection);
  const invoicesHome = useSelector(selectInvoicesHome);
  const totalInvoices = useSelector(selectTotalInvoices);
  const monthlySales = useSelector(selectMonthlySales);
  const lastInvoices = useSelector(selectLastInvoices);
  const accesToken = useSelector(selectAccessToken);
  const lastUpdate = useSelector(selectLastUpdateInfo);
  const dispatch = useDispatch();

  const [logout] = useLogout();


  // UseEffect para manejar el loading
  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleGetInformation = () => {
    const currentDateTime = moment().tz('America/Mexico_City').format('D [de] MMMM [del] YYYY [a las] h:mm A');
    setLoading(true);
    getInfoHome(accesToken)
      .then((res) => {
        if (res && res.data) {
          const homeData = res.data.body;
          dispatch(setClientsHome(homeData.clients));
          dispatch(setWeeklyCollection(homeData.weeklyCollection));
          dispatch(setInvoicesHome(homeData.invoices));
          dispatch(setTotalInvoices(homeData.totalInvoices));
          dispatch(setMonthlySales(homeData.monthlySales));
          dispatch(setLastInvoices(homeData.lastInvoices));
          dispatch(setLastUpdateInfo(currentDateTime));
          setLoading(false);
        } else {
          console.error("No data received from getInfoHome");
        }
      })
      .catch((err) => {
        errors(err, "Error fetching home data", logout);
      });
  };

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Box style={{ marginLeft: 10 }}>
          <CustomBreadcrumbs links={breadcrumbLinks} />
        </Box>
        <Typography className={classes.title}>Resúmen</Typography>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography className={classes.lastUpdate}>
            Última actualización: <span>{lastUpdate}</span>
          </Typography>
          <IconButton aria-label="delete">
            <GrRefresh onClick={handleGetInformation} />
          </IconButton>
        </Box>
        <Box className={classes.cardsContainer}>
          <AdminCard
            title="Clientes"
            number={clientsHome.totalClients}
            newAvance={clientsHome.newWeeklyClients}
            image={imageChart}
            message="nuevos esta semana"
            loading={loading}
            isUp={isUp}
          />
          <AdminCard
            title="Cobranza Semanal"
            number={`$${weeklyCollection.totalWeely}`}
            newAvance={`${weeklyCollection.newWeeklyCollection}%`}
            image={imageDollar}
            message="de alcance semanal"
            loading={loading}
          />
          <AdminCard
            title="Facturas"
            number={invoicesHome.totalInvoices}
            newAvance={invoicesHome.newWeeklyInvoices}
            image={imageDevice}
            message="nuevos esta semana"
            loading={loading}
            isUp={isUp}
          />
        </Box>

        <div className={classes.chartsContainer}>
          <Box className={classes.pieContainer}>
            {loading? (
              <>
                <Skeleton style={{paddingRight:100, paddingTop:15}} className={classes.titlePie} variant="text" width={200} height={30} />
              </>
            ):(
              <Typography className={classes.titlePie}>Estádisticas de facturas</Typography>
            )
          }
          <PieActiveArc loading={loading} totalInvoices={totalInvoices} />
          </Box>
          <Box className={classes.barContainer}>
            <HorizontalBars loading={loading} monthlySales={monthlySales} />
          </Box>
        </div>
        <Typography className={classes.title2}>Ultimas facturas</Typography>
        <SimpleDataTable loading={loading} invoices={lastInvoices} />
      </Box>
    </DrawerMenu>
  );
};

export default Administrator;
