import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_NAVICK_API_URL } = process.env;

const accessToken = getCookie("accessToken");

export const getUsers = async () => {
  try {
    const request = {
      method: "GET",
      url: `${REACT_APP_NAVICK_API_URL}/users`,
      headers: {
        "content-type": "application/json",
        token: accessToken,
      },
    };

    const respuesta = await axios(request);

    return respuesta.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const changeUserStatus = (idUser, status) => {
  const payload = {
    idUser,
    status,
  };

  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    url: `${REACT_APP_NAVICK_API_URL}/users/change_status`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const createUser = (user) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    url: `${REACT_APP_NAVICK_API_URL}/users/insert`,
    data: user,
    body: JSON.stringify(user),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const updateUserInformation = (userInformation) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "PUT",
    url: `${REACT_APP_NAVICK_API_URL}/users/update`,
    data: userInformation,
    body: JSON.stringify(userInformation),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const deleteUser = (idUser) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "DELETE",
    url: `${REACT_APP_NAVICK_API_URL}/usuarios/${idUser}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const changeUserPassword = (userId, newPassword) => {
  const newPasswordInfo = {
    userId,
    newPassword,
  };
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    url: `${REACT_APP_NAVICK_API_URL}/users/change_password`,
    data: newPasswordInfo,
    body: JSON.stringify(newPasswordInfo),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
