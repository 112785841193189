import {
  Card,
  Box,
  CardContent,
  Typography,
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";
import React from "react";
import cardRoutesStyles from "./CardRoutes.style";
import DeleteIcon from "../../../assets/images/delete.png";
import CenteredContent from "../../../components/sharedComponents/CenteredContent";
const CardRoutes = (props) => {
  const {
    title,
    numClients,
    handleOpenModalDeleteRoute,
    handleOpenModalRouteMap,
    idRoute,
  } = props;
  const classes = cardRoutesStyles();
  return (
    <Card className={classes.mainContainer}>
      <Box className={classes.mapContainer}></Box>
      <CardContent container>
        <Grid container>
          <Grid item xs={8}>
            <Typography className={classes.title} variant="subtitle2">
              {title}
            </Typography>
            <Typography className={classes.subtitle} variant="body1">
              Clientes en Ruta: {numClients}
            </Typography>
            <Button
              onClick={() => handleOpenModalRouteMap(idRoute)}
              className={classes.showMapButton}
            >
              Mapa
            </Button>
          </Grid>
          {/* <Grid item xs={4} className={classes.deleteRouteBox}>
            <CenteredContent className={classes.deleteRouteButtonContainer}>
              <IconButton onClick={() => handleOpenModalDeleteRoute(idRoute)}>
                <img
                  src={DeleteIcon}
                  alt="delete-route"
                  className={classes.deleteRouteButton}
                />
              </IconButton>
            </CenteredContent>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardRoutes;
