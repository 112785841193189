import { makeStyles } from "@material-ui/core";
import { MONTSERRAT_FONTS } from "../../../constants/styleConstants";

const getStatusBorderColor = (status, theme) => {
  switch (status) {
    case "cancel":
      return theme.palette.primary.main_red;
    case "paid":
      return theme.palette.primary.light;
    case "pending":
      return theme.palette.primary.honey_gold;
    default:
      return theme.palette.primary.light;
  }
};

const InvoiceCardStatusStyle = makeStyles((theme) => ({
  card: ({ status }) => ({
    width: "100%",
    height: 98,
    background: "#fff",
    display: "flex;",
    alignItems: "center;",
    justifyContent: "space-around;",
    borderRadius: 20,
    border: `1px solid ${getStatusBorderColor(status, theme)}`,
  }),
  boxTitle: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 16,
  },
  boxInfo: {
    display: "flex",
  },
  total: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
  },
  value: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    marginRight: 20,
    marginLeft: 6,
    color: theme.palette.primary.gray_30,
  },
  cancelValue: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    marginRight: 20,
    marginLeft: 6,
    color: theme.palette.primary.main_red,
  },
  green: {
    color: theme.palette.primary.light,
  },
}));

export default InvoiceCardStatusStyle;
