import { headersFields } from "./headersFields";

export const validateRow = (header, value) => {
  let flagValidation = false;

  if (headersFields.indexOf(header) === -1) {
    flagValidation = true;
  }

  if (
    (header === "Nombre" ||
      header === "Calle" ||
      header === "Numero_Exterior" ||
      header === "Colonia" ||
      header === "Ciudad" ||
      header === "Estado") &&
    value === undefined
  ) {
    flagValidation = true;
  }

  if (
    header === "Calular" &&
    (isNaN(value) || value.toString().length !== 10)
  ) {
    flagValidation = true;
  }

  if (
    header === "Telefono_Casa" &&
    (isNaN(value) || value.toString().length !== 10)
  ) {
    flagValidation = true;
  }

  if (header === "CP" && (isNaN(value) || value.toString().length !== 5)) {
    flagValidation = true;
  }

  return flagValidation;
};
