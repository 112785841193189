import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ViewClientInfoStyles from "./ViewClientInfo.styles";
import Map from "../../Map";
import { GENERAL_CONSTANTS } from "../../../constants/credentials";
import { Close } from "@material-ui/icons";
import {
  createNewClient,
  getClients,
  updateClient,
} from "../../../api/apiClients";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccessToken,
  selectClientInformation,
  setClients,
} from "../../../store/slices/clients";

import CenteredContent from "../../sharedComponents/CenteredContent";
import ModalLoader from "../../sharedComponents/ModalLoader";
import { errors } from "../../../utils/ErrorAxios";
import useLogout from "../../../hooks/useLogout";

const ViewClientInfo = (props) => {
  const {
    disabled,
    handleClose,
    createNew,
    type,
    setFilteredClients,
    availableRoutes,
    priceList,
    paymentFrecuency,
  } = props;
  const [currentClientInfo, setCurrentClientInfo] = useState({});
  const userInformation = useSelector(selectClientInformation);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const accessToken = useSelector(selectAccessToken);
  const [handleEdit, setHandleEdit] = useState(false);
  const [shouldDisableFields, setshouldDisableFields] = useState(
    type === "view"
  );

  const isView = type === "view";

  const dispatch = useDispatch();
  const [logout] = useLogout();

  const paymentFrequencies = [
    { value: 1, label: "Semanalmente" },
    { value: 2, label: "Quincenalmente" },
    { value: 3, label: "Mensualmente" },
  ];

  const weeklyDays = [
    { value: 1, label: "Lunes" },
    { value: 2, label: "Martes" },
    { value: 3, label: "Miércoles" },
    { value: 4, label: "Jueves" },
    { value: 5, label: "Viernes" },
    { value: 6, label: "Sabado" },
  ];

  const biweeklyDays = [{ value: 0, label: "Cada Quince Días" }];

  const mensualDays = [{ value: 0, label: "Cada Mes" }];

  useEffect(() => {
    setCurrentClientInfo({
      idClient: createNew ? "" : userInformation.id_client,
      name: createNew ? "" : userInformation.name,
      idRoute: createNew ? "" : userInformation.id_route,
      street: createNew ? "" : userInformation.street,
      externalNumber: createNew ? "" : userInformation.external_number,
      internalNumber: createNew ? "" : userInformation.internal_number || "",
      neighborhood: createNew ? "" : userInformation.neighborhood,
      city: createNew ? "" : userInformation.city,
      state: createNew ? "" : userInformation.state,
      zipCode: createNew ? "" : userInformation.zip_code,
      personalPhoneNumber: createNew
        ? ""
        : userInformation.personal_phonenumber,
      homePhoneNumber: createNew ? "" : userInformation.home_phonenumber,
      email: createNew ? "" : userInformation.email || "",
      idPriceList: createNew ? "" : userInformation.id_price_list,
      status: createNew ? "" : userInformation.status,
      payDays: createNew ? "" : userInformation.pay_days || 0,
      latitude: createNew ? "" : userInformation.latitude || "",
      longitude: createNew ? "" : userInformation.longitude || "",
      comments: createNew ? "" : userInformation.comments,
      payFrequency: createNew ? "" : userInformation.payment_frequency_id,
      payDaysNew: createNew ? "" : userInformation.preference_day,
    });
  }, [
    createNew,
    userInformation.city,
    userInformation.comments,
    userInformation.email,
    userInformation.external_number,
    userInformation.home_phonenumber,
    userInformation.id_client,
    userInformation.id_price_list,
    userInformation.id_route,
    userInformation.internal_number,
    userInformation.latitude,
    userInformation.longitude,
    userInformation.name,
    userInformation.neighborhood,
    userInformation.pay_days,
    userInformation.personal_phonenumber,
    userInformation.state,
    userInformation.status,
    userInformation.street,
    userInformation.zip_code,
  ]);

  const {
    name,
    idRoute,
    street,
    externalNumber,
    internalNumber,
    neighborhood,
    city,
    state,
    zipCode,
    personalPhoneNumber,
    homePhoneNumber,
    email,
    idPriceList,
    status,
    payDays,
    latitude,
    longitude,
    comments,
    payFrequency,
    payDaysNew,
  } = currentClientInfo;

  const createDisabled =
    name &&
    idRoute &&
    street &&
    externalNumber &&
    neighborhood &&
    city &&
    state &&
    zipCode &&
    personalPhoneNumber &&
    comments &&
    payFrequency
      ? false
      : true;

  const handleUpdate = () => {
    setLoading(true);

    const updatedClientInfo = {
      ...currentClientInfo,
      payment_frequency_id: currentClientInfo.payFrequency,
      // Asigna 0 a preference_day si payFrequency es 2 o 3
      preference_day:
        currentClientInfo.payFrequency === 2 ||
        currentClientInfo.payFrequency === 3
          ? 0
          : currentClientInfo.payDaysNew,
    };

    delete updatedClientInfo.payFrequency;
    delete updatedClientInfo.payDaysNew;

    updateClient(updatedClientInfo, accessToken)
      .then((res) => {
        let closed = false;
        setTimeout(() => {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            closed = true;
            handleClose();
            window.location.reload();
          }, 2000);
        }, 3000);
        closed && setSuccess(false);
      })
      .catch((err) => {
        errors(err, "No se pudo actualizar el estado del cliente", logout);
      });
  };

  const handleCreateUser = () => {
    const newUser = {
      name,
      idRoute: parseInt(idRoute),
      street,
      externalNumber,
      internalNumber,
      neighborhood,
      city,
      state,
      zipCode: parseInt(zipCode),
      personalPhoneNumber,
      homePhoneNumber,
      email,
      idPriceList: 1,
      status: 1,
      payDays: parseInt(payDays),
      latitude,
      longitude,
      comments,
      payment_frequency_id: payFrequency,
      preference_day: payFrequency === 2 || payFrequency === 3 ? 0 : payDaysNew,
    };

    setLoading(true);
    createNewClient(newUser, accessToken)
      .then((res) => {
        let closed = false;
        setTimeout(() => {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            closed = true;
            handleClose();
          }, 2000);
        }, 3000);
        closed && setSuccess(false);
        getClients(accessToken);
        if (res.data.statusCode === 200) {
          setFilteredClients(res.data.result);
          dispatch(setClients(res.data.result));
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        errors(error, "No se pudo actualizar el estado del cliente", logout);
        setTimeout(() => {
          handleClose();
        }, 3000);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCurrentClientInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if ((name === "payFrequency") === 1) {
      setCurrentClientInfo((prevState) => ({
        ...prevState,
        payDaysNew: "", // Resetea payDaysNew al cambiar payFrequency
      }));
    }
  };

  const [errorsI, setErrorsI] = useState({});

  const requiredFields = [
    "name",
    "status",
    "idPriceList",
    "street",
    "externalNumber",
    "internalNumber",
    "neighborhood",
    "state",
    "city",
    "idRoute",
    "comments",
    "payFrequency",
    "payDaysNew",
  ];
  const validateField = (name, value) => {
    let newErrors = { ...errorsI };

    if (requiredFields.includes(name) && !value) {
      newErrors[name] = "El campo es obligatorio";
    } else if (name === "zipCode" && (value.length !== 5 || isNaN(value))) {
      newErrors[name] = "obligatorio 5 dígitos";
    } else if (
      name === "personalPhoneNumber" &&
      (value.length !== 10 || isNaN(value))
    ) {
      newErrors[name] = "10 digitos numericos";
    } else if (
      name === "homePhoneNumber" &&
      (value.length !== 10 || isNaN(value))
    ) {
      newErrors[name] = "10 digitos numericos";
    } else {
      delete newErrors[name]; // Eliminar el error si ya no aplica
    }

    setErrorsI(newErrors);
  };

  const hasError = (fieldName) => !!errorsI[fieldName];

  const handleEditChange = () => {
    setHandleEdit(!handleEdit);
    if (isView) {
      setshouldDisableFields(!shouldDisableFields);
    }
  };

  const RenderUpdateButton = () => {
    return (
      <>
        {isView && !shouldDisableFields && (
          <Button
            className={classes.saveButton}
            type="contained"
            color="primary"
            onClick={handleUpdate}
          >
            <Typography className={classes.updateInfoBtn}>
              Actualizar
            </Typography>
          </Button>
        )}
      </>
    );
  };

  const classes = ViewClientInfoStyles(props);

  return (
    <Box
      className={classes.mainContainer}
      display={(loading || success || error) && "flex"}
      justifyContent={(loading || success || error) && "center"}
      alignItems={(loading || success || error) && "center"}
    >
      {userInformation && !loading && !success && !error ? (
        <React.Fragment>
          <Grid container spacing={2} className={classes.content}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.titleModal}>
                {type === "new"
                  ? "Agregar nuevo cliente"
                  : "Actualizar cliente"}
              </Typography>
              <Box className={classes.closeIconContainer}>
                <IconButton onClick={handleClose} className={classes.closeIcon}>
                  <Close />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.title}>
                Información General
              </Typography>
            </Grid>
            {/* Form */}

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Nombre"
                name="name"
                value={name && name}
                onChange={(e) => handleChange(e)}
                type="text"
                className={classes.textFieldAll}
                fullWidth
                inputProps={{ className: classes.input }}
                disabled={shouldDisableFields}
                error={hasError("name")}
                helperText={errorsI.name}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Teléfono casa"
                name="homePhoneNumber"
                value={homePhoneNumber && homePhoneNumber}
                onChange={(e) => handleChange(e)}
                type="tel"
                className={classes.textFieldAll}
                fullWidth
                inputProps={{ className: classes.input }}
                disabled={shouldDisableFields}
                error={hasError("homePhoneNumber")}
                helperText={errorsI.homePhoneNumber}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Teléfono celular"
                name="personalPhoneNumber"
                value={personalPhoneNumber && personalPhoneNumber}
                onChange={(e) => handleChange(e)}
                type="tel"
                className={classes.textFieldAll}
                fullWidth
                inputProps={{ className: classes.input }}
                error={hasError("personalPhoneNumber")}
                helperText={errorsI.personalPhoneNumber}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
                disabled={shouldDisableFields}
              />
            </Grid>

            {/* Select para Frecuencia de Pago */}
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Frecuencia Pago</InputLabel>
                <Select
                  variant="outlined"
                  name="payFrequency"
                  value={Number(currentClientInfo.payFrequency)}
                  onChange={handleChange}
                  label="Frecuencia de Pago"
                  error={hasError("payFrequency")}
                  disabled={shouldDisableFields}
                >
                  {paymentFrecuency && paymentFrecuency.length > 0 ? (
                    paymentFrecuency.map((freq) => (
                      <MenuItem
                        key={parseInt(freq.payment_frequency_id)}
                        value={parseInt(freq.payment_frequency_id)}
                      >
                        {freq.frequency_option
                          ? freq.frequency_option.toLowerCase()
                          : "Sin descripción"}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No hay frecuencias disponibles</MenuItem>
                  )}
                </Select>
                {errors.payFrequency && (
                  <p style={{ color: "red" }}>{errors.payFrequency}</p>
                )}
              </FormControl>
            </Grid>

            {/* Select para Día de Pago */}
            {currentClientInfo.payFrequency === 1 && (
              <Grid item xs={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>Día Pago</InputLabel>
                  <Select
                    variant="outlined"
                    name="payDaysNew"
                    value={currentClientInfo.payDaysNew}
                    onChange={handleChange}
                    label="Día de Pago"
                    disabled={
                      !currentClientInfo.payFrequency || shouldDisableFields
                    }
                    error={hasError("payDaysNew")}
                  >
                    {(currentClientInfo.payFrequency === 1
                      ? weeklyDays
                      : currentClientInfo.payFrequency === 2
                      ? biweeklyDays
                      : currentClientInfo.payFrequency === 3
                      ? mensualDays
                      : []
                    ).map((day) => (
                      <MenuItem key={day.value} value={day.value}>
                        {day.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.payDaysNew && (
                    <p style={{ color: "red" }}>{errors.payDaysNew}</p>
                  )}
                </FormControl>
              </Grid>
            )}

            {type === "view" && (
              <>
                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Estado
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className={classes.textField}
                      name="status"
                      value={parseInt(status)}
                      onChange={(e) => handleChange(e)}
                      label="Estado"
                      disabled={shouldDisableFields}
                      error={hasError("status")}
                      helperText={errorsI.status}
                      // inputProps={{ className: classes.input }}
                      FormHelperTextProps={{
                        classes: {
                          root: classes.helperText,
                        },
                      }}
                    >
                      <MenuItem value={1}>Activo</MenuItem>
                      <MenuItem value={0}>Inactivo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                    <Select
                      variant="outlined"
                      label="Precios"
                      name="idPriceList"
                      className={classes.textField}
                      value={idPriceList ? parseInt(idPriceList) : 0}
                      onChange={(e) => handleChange(e)}
                      disabled={shouldDisableFields}
                      error={hasError("idPriceList")}
                      helperText={errorsI.idPriceList}
                      FormHelperTextProps={{
                        classes: {
                          root: classes.helperText,
                        },
                      }}
                    >
                      {priceList && priceList.length > 0 ? (
                        priceList.map((price) => (
                          <MenuItem
                            key={parseInt(price.id_price_list)}
                            value={parseInt(price.id_price_list)}
                          >
                            {price.description
                              ? price.description.toLowerCase()
                              : "Sin descripción"}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No hay rutas disponibles</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            {/* Form */}

            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                className={`${classes.title} ${classes.addressInformation}`}
              >
                Dirección
              </Typography>
            </Grid>
            {/* Address information */}

            <Grid item xs={8}>
              <TextField
                disabled={shouldDisableFields}
                label="Calle"
                variant="outlined"
                name="street"
                onChange={(e) => handleChange(e)}
                value={street && street}
                inputProps={{ className: classes.input }}
                className={classes.textFieldAll}
                fullWidth
                error={hasError("street")}
                helperText={errorsI.street}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled={shouldDisableFields}
                label="No exterior"
                variant="outlined"
                name="externalNumber"
                onChange={(e) => handleChange(e)}
                value={externalNumber && externalNumber}
                inputProps={{ className: classes.input }}
                className={classes.textFieldAll}
                fullWidth
                error={hasError("externalNumber")}
                helperText={errorsI.externalNumber}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled={shouldDisableFields}
                label="No interior"
                variant="outlined"
                name="internalNumber"
                onChange={(e) => handleChange(e)}
                value={internalNumber && internalNumber}
                inputProps={{ className: classes.input }}
                className={classes.textFieldAll}
                fullWidth
                error={hasError("internalNumber")}
                helperText={errorsI.internalNumber}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                disabled={shouldDisableFields}
                label="Colonia"
                variant="outlined"
                name="neighborhood"
                value={neighborhood && neighborhood}
                onChange={(e) => handleChange(e)}
                inputProps={{ className: classes.input }}
                className={classes.textFieldAll}
                fullWidth
                error={hasError("neighborhood")}
                helperText={errorsI.neighborhood}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={shouldDisableFields}
                label="Municipio"
                variant="outlined"
                name="state"
                onChange={(e) => handleChange(e)}
                value={state && state}
                inputProps={{ className: classes.input }}
                className={classes.textFieldAll}
                fullWidth
                error={hasError("state")}
                helperText={errorsI.state}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={shouldDisableFields}
                label="Estado"
                variant="outlined"
                name="city"
                onChange={(e) => handleChange(e)}
                value={city && city}
                inputProps={{ className: classes.input }}
                className={classes.textFieldAll}
                fullWidth
                error={hasError("city")}
                helperText={errorsI.city}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={shouldDisableFields}
                label="Código postal"
                variant="outlined"
                name="zipCode"
                type="number"
                onChange={(e) => handleChange(e)}
                value={zipCode && zipCode}
                inputProps={{ className: classes.input }}
                className={classes.textFieldAll}
                fullWidth
                error={hasError("zipCode")}
                helperText={errorsI.zipCode}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline={true}
                disabled={shouldDisableFields}
                label="Referencias de dirección"
                variant="outlined"
                name="comments"
                onChange={(e) => handleChange(e)}
                value={comments}
                fullWidth
                inputProps={{ className: classes.inputMultiline }}
                className={classes.textFieldAll}
                error={hasError("comments")}
                helperText={errorsI.comments}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Rutas</InputLabel>
                <Select
                  variant="outlined"
                  disabled={shouldDisableFields}
                  label="Asignar ruta"
                  name="idRoute"
                  value={parseInt(idRoute)}
                  onChange={(e) => handleChange(e)}
                  inputProps={{ className: classes.input }}
                  className={classes.textFieldAll}
                  error={hasError("idRoute")}
                  helperText={errorsI.idRoute}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                >
                  {availableRoutes && availableRoutes.length > 0 ? (
                    availableRoutes.map((route) => (
                      <MenuItem
                        key={parseInt(route.id_route)}
                        value={parseInt(route.id_route)}
                      >
                        {route.description
                          ? route.description.toLowerCase()
                          : "Sin descripción"}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No hay rutas disponibles</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Empresas</InputLabel>
                <Select ctrl z
                  variant="outlined"
                  disabled={shouldDisableFields}
                  label="Asignar ruta"
                  name="idRoute"
                  value={parseInt(idRoute)}
                  onChange={(e) => handleChange(e)}
                  inputProps={{ className: classes.input }}
                  className={classes.textFieldAll}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                >
                  {routes &&
                    routes.map((route) => (
                      <MenuItem key={route.id_route} value={route.id_route}>
                        {route.description}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid> */}

            {isView && (
              <Grid item xs={8}>
                <Typography>Activa para actualizar el usuario</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={handleEdit}
                      onChange={handleEditChange}
                      name="edit-info"
                    />
                  }
                  label="Editar"
                />
              </Grid>
            )}

            <Grid item xs={12} className={classes.botonContent}>
              <RenderUpdateButton />
            </Grid>

            {!isView && (
              <Grid item xs={12} className={classes.botonContent}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={createDisabled}
                  className={classes.saveButton}
                  onClick={createNew ? handleCreateUser : handleUpdate}
                >
                  {!createNew ? "Guardar" : "Crear Usuario"}
                </Button>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      ) : (
        !loading &&
        !success &&
        !error && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h1">Este usuario no tiene datos</Typography>
          </Box>
        )
      )}
      {(loading || success || error) && (
        <Box>
          <Typography
            variant="h1"
            align="center"
            className={classes.savingData}
          >
            {loading && "Guardando datos"}
            {success && "¡Listo!"}
            {error && "¡Oh no!"}
          </Typography>
          <ModalLoader
            isLoading={loading}
            isSuccessfully={success}
            onError={error}
          />
          <Typography variant="body1" align="center">
            {loading && "Por favor espera, no cierres la pantalla."}
            {success && "Operación realizada con exito"}
            {error && errorMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ViewClientInfo;
