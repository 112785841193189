import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientsHome: {},
  weeklyCollection: {},
  invoices: {},
  totalInvoices: {},
  monthlySales: {},
  lastInvoices: [],
  lastUpdateInfo: ""
};
export const homeReducer = createSlice({
  name: "home",
  initialState,
  reducers: {
    setClientsHome(state, { payload }) {
      state.clientsHome = payload;
    },
    setWeeklyCollection(state, { payload }) {
      state.weeklyCollection = payload;
    },
    setInvoicesHome(state, { payload }) {
      state.invoices = payload;
    },
    setTotalInvoices(state, { payload }) {
      state.totalInvoices = payload;
    },
    setMonthlySales(state, { payload }) {
      state.monthlySales = payload;
    },
    setLastInvoices(state, { payload }) {
      state.lastInvoices = payload;
    },
    setLastUpdateInfo(state, { payload }) {
      state.lastUpdateInfo = payload;
    },
  },

});
export const {
    setClientsHome,
    setWeeklyCollection,
    setInvoicesHome,
    setTotalInvoices,
    setMonthlySales,
    setLastInvoices,
    setLastUpdateInfo
  } = homeReducer.actions;

export const selectClientsHome = (state) => state.home.clientsHome;
export const selectWeeklyCollection = (state) => state.home.weeklyCollection;
export const selectInvoicesHome = (state) => state.home.invoices;
export const selectTotalInvoices = (state) => state.home.totalInvoices;
export const selectMonthlySales = (state) => state.home.monthlySales;
export const selectLastInvoices = (state) => state.home.lastInvoices;
export const selectLastUpdateInfo = (state) => state.home.lastUpdateInfo;

export default homeReducer.reducer;