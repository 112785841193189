import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { formatDateToUTC } from "../../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccessToken,
} from "../../../store/slices/clients";
import { getPaymentsByIdInvoice } from "../../../api/ApiPayments";
import { selectAvailableUsers} from "../../../store/slices/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useLogout from "../../../hooks/useLogout";
import { errors } from "../../../utils/ErrorAxios";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { invoice } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const accessToken = useSelector(selectAccessToken);
  const [payments, setPayments] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [logout] = useLogout();
  useEffect(() => {
    getPaymentsByIdInvoice(invoice.id_invoice, accessToken)
      .then((res) => {
        setPayments(res.data.result);
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      })
     
  }, [accessToken, dispatch, history, invoice.id_invoice]);

  const availableUsers = useSelector(selectAvailableUsers);
  const getUserName = (idUser) => {
    const user = availableUsers.filter(
      (user) => parseInt(user.id_user) === parseInt(idUser)
    );
    if (user.length === 0) {
      return "Sin usuario";
    } else {
      return user[0].name;
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{invoice.id_invoice}</TableCell>
        <TableCell align="center">{invoice.status}</TableCell>
        <TableCell align="center">{invoice.total_amount}</TableCell>
        <TableCell align="center">{invoice.remaining_payment}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Abonos efectuados
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ID abono</TableCell>
                    <TableCell align="center">ID factura</TableCell>
                    <TableCell align="center">Fecha</TableCell>
                    <TableCell align="center">Abono ($)</TableCell>
                    <TableCell align="center">Usuario</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments && payments.length > 0 ? (
                    payments.map((pay) => (
                      <TableRow key={pay.id_payment}>
                        <TableCell align="center">{pay.id_payment}</TableCell>
                        <TableCell align="center">{pay.id_invoice}</TableCell>
                        <TableCell align="center">
                          {formatDateToUTC(pay.created_at)}
                        </TableCell>
                        <TableCell align="center">
                          {pay.total_payment}
                        </TableCell>
                        <TableCell align="center">
                          {getUserName(pay.id_user)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}> Sin abonos</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function InvoicesDetailInformation(props) {
  const { invoicesList } = props;

  return (
    <TableContainer component={Paper} style={{ width: 1000, marginTop: 30 }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">ID factura</TableCell>
            <TableCell align="center">Estado</TableCell>
            <TableCell align="center">Total</TableCell>
            <TableCell align="center">Adeudo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoicesList &&
            invoicesList.length > 0 &&
            invoicesList.map((invoice) => {
              return <Row invoice={invoice} key={invoice.id_invoice} />;
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
