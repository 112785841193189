import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import SimpleDataTableStyles from "./SimpleDataTable.styles";
import { Skeleton } from "@mui/material";
import moment from "moment-timezone";
import "moment/locale/es"; // Importa la localización en español

moment.locale("es"); // Configura moment para usar español

const SimpleDataTable = ({ loading, invoices }) => {
  const classes = SimpleDataTableStyles();

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return classes.pending;
      case 2:
        return classes.paid;
      case 3:
        return classes.cancelled;
      default:
        return "";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Pendiente";
      case 2:
        return "Pagada";
      case 3:
        return "Cancelada";
      default:
        return "";
    }
  };

  return (
    <Box>
      <TableContainer component={Paper} className={classes.tableComponent}>
        <Table className={classes.table} aria-label="invoice table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>ID FACTURA</TableCell>
              <TableCell className={classes.tableHead}>CREADA</TableCell>
              <TableCell className={classes.tableHead}>CANTIDAD</TableCell>
              <TableCell className={classes.tableHead}>CLIENTE</TableCell>
              <TableCell className={classes.tableHead}>ESTADO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from(new Array(2)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={7}>
                      <Skeleton className={classes.skeleton} />
                    </TableCell>
                  </TableRow>
                ))
              : invoices.map((invoice) => (
                  <TableRow key={invoice.id_invoice}>
                    <TableCell className={classes.tableBody}>
                      {invoice.id_invoice}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {moment(invoice.created_at)
                        .tz("America/Mexico_City")
                        .locale("es")
                        .format("D [de] MMMM [del] YYYY")}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {`$${invoice.total_amount}`}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {invoice.client}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      <span
                        className={`${classes.status} ${getStatusClass(
                          invoice.status
                        )}`}
                      >
                        {getStatusText(invoice.status)}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SimpleDataTable;
