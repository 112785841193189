import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Skeleton } from "@mui/material";
import { Box } from "@material-ui/core";

const HorizontalBars = ({ loading, monthlySales }) => {
  Object.keys(monthlySales).forEach((key) => {
    if (isNaN(monthlySales[key])) {
      console.warn(`El valor de ${key} no es un número:`, monthlySales[key]);
    }
  });

  const chartSetting = {
    xAxis: [
      {
        label: "total ventas (mes)",
      },
    ],
    width: 500,
    height: 400,
  };
  const dataset = [
    { mes: "Ene", ventas: parseInt(monthlySales.january) || 0 },
    { mes: "Feb", ventas: parseInt(monthlySales.frebruary) || 0 },
    { mes: "Mar", ventas: parseInt(monthlySales.march) || 0 },
    { mes: "Abr", ventas: parseInt(monthlySales.april) || 0 },
    { mes: "May", ventas: parseInt(monthlySales.may) || 0 },
    { mes: "Jun", ventas: parseInt(monthlySales.june) || 0 },
    { mes: "Jul", ventas: parseInt(monthlySales.july) || 0 },
    { mes: "Ago", ventas: parseInt(monthlySales.august) || 0 },
    { mes: "Sep", ventas: parseInt(monthlySales.september) || 0 },
    { mes: "Oct", ventas: parseInt(monthlySales.october) || 0 },
    { mes: "Nov", ventas: parseInt(monthlySales.november) || 0 },
    { mes: "Dic", ventas: parseInt(monthlySales.december) || 0 },
  ];
  const seriesConfig = [
    {
      dataKey: "ventas",
      label: "Ventas Mensuales",
      valueFormatter: (value) => {
        return `$${value} pesos`;
      },

      colorMap: {
        type: "ordinal",
        colors: ["#FFB6C1", "#87CEFA", "#32CD32", "#FFA07A"],
      },
      fill: "#159E60",
      stroke: "#000000",
      disableLine: true,
    },
  ];
  const xAxisConfig = [
    {
      dataKey: "ventas",
      scaleType: "linear",
      position: "bottom",
      tickFontSize: 12,
      tickInterval: "auto",
      tickPlacement: "start",
      tickLabelStyle: { fontWeight: "bold", fill: "#555555" },
      tickSize: 6,
      valueFormatter: (value) => `$${value}`,
      label: "Ventas",
      labelFontSize: 14,
      labelStyle: { fontWeight: "bold" },
      min: 0,
      max: 100,
    },
  ];
  const yAxisConfig = [
    {
      dataKey: "mes",
      scaleType: "band",
      tickFontSize: 12,
      tickPlacement: "middle",
      tickSize: 5,
    },
  ];
  return loading ? (
    <Box style={{ width: 400, paddingLeft: 40 }}>
      <Skeleton variant="text" width={120} height={15} />
      <Skeleton variant="text" width={160} height={15} />
      <Skeleton variant="text" width={140} height={15} />
      <Skeleton variant="text" width={180} height={15} />
      <Skeleton variant="text" width={120} height={15} />
      <Skeleton variant="text" width={200} height={15} />
      <Skeleton variant="text" width={130} height={15} />
      <Skeleton variant="text" width={190} height={15} />
      <Skeleton variant="text" width={120} height={15} />
      <Skeleton variant="text" width={130} height={15} />
      <Skeleton variant="text" width={190} height={15} />
      <Skeleton variant="text" width={120} height={15} />
    </Box>
  ) : (
    <BarChart
      borderRadius={5}
      style={{ marginLeft: 0, width: "90%", padding: 60 }}
      dataset={dataset}
      yAxis={yAxisConfig}
      xAxis={xAxisConfig}
      series={seriesConfig}
      layout="horizontal"
      {...chartSetting}
    />
  );
};
export default HorizontalBars;
