import { makeStyles } from "@material-ui/core/styles";
import {
  COLOR_CONSTANTS,
  MONTSERRAT_FONTS,
} from "../../../constants/styleConstants";

const ConfirmModalStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 450,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    position: "relative", 
  },
  confirmModalTitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 18,
    color: COLOR_CONSTANTS.TEXT_DARK_MEDIUM,
    marginTop: 20,
  },
  confirmModalSubtitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 12,
    color: COLOR_CONSTANTS.TEXT_DARK_DISABLED,
    maxWidth: 350,
    marginTop: 20,
  },
  confirmModalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  button: {
    textTransform: "none",
  },
  cancelConfirmModalButton: {
    width: 160,
    height: 40,
  },
  buttonsGroupContainer: {
    display: "flex",
    padding: 20,
    marginTop: 20,
  },
  mr: {
    marginRight: 40,
  },
  closeIconContainerMain: {
    position: "absolute", 
    top: 10, 
    right: 10, 
  },
}));

export default ConfirmModalStyles;
