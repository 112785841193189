import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EditCompanyStyles from "./EditCompany.Styles";
import { GrClose } from "react-icons/gr";
import { getCompanyById, updateCompany } from "../../../api/apiCompanies";
import { selectAccessToken } from "../../../store/slices/clients";
import { useDispatch, useSelector } from "react-redux";
import { setDataTableCompanies } from "../../../store/slices/companies";
import { notification } from "../../../utils/notifications";

const EditCompany = (props) => {
  const { handleClose, companyId, setChangeCompleted } = props;
  const classes = EditCompanyStyles();
  const accesToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const radioDisable = true;
  const handleEditChange = (event) => {
    setIsDisabled(!event.target.checked); // Invierte el valor de isDisabled
  };

  const [data, setData] = useState({
    legal_name: "",
    comercial_name: "",
    rfc: "",
    company_type: "",
    sector: "",
    titular_name: "",
    workstation: "",
    home_phone: "",
    personal_phone: "",
    email: "",
    street: "",
    neighborhood: "",
    interior_number: "",
    exterior_number: "",
    zip_code: "",
    municipality: "",
    state: "",
    is_constituted: "",
  });
  useEffect(() => {
    getCompanyById(accesToken, companyId).then((res) => {
      if (res.data.statusCode === 200) {
        const datar = res.data.body[0];
        setData({
          legal_name: datar.legal_name || "",
          comercial_name: datar.comercial_name || "",
          rfc: datar.rfc || "",
          company_type: datar.company_type || "",
          sector: datar.sector || "",
          titular_name: datar.titular_name || "",
          workstation: datar.workstation || "",
          home_phone: datar.home_phone || "",
          personal_phone: datar.personal_phone || "",
          email: datar.email || "",
          street: datar.street || "",
          neighborhood: datar.neighborhood || "",
          interior_number: datar.interior_number || "",
          exterior_number: datar.exterior_number || "",
          zip_code: datar.zip_code || "",
          municipality: datar.municipality || "",
          state: datar.state || "",
          is_constituted: datar.is_constituted ? "true" : "false",
        });
      }
    });
  }, [companyId, accesToken]);

  // Estado para almacenar la selección del usuario
  const [empresaConstituida, setEmpresaConstituida] = useState(true);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const maxLengths = {
    rfc: 13,
    home_phone: 10,
    personal_phone: 10,
    zip_code: 5,
  };

  const requiredFields = {
    legal_name: "El campo es requerido",
    comercial_name: "El campo es requerido",
    rfc: "El campo es requerido",
    company_type: "El campo es requerido",
    sector: "El campo es requerido",
    titular_name: "El campo es requerido",
    workstation: "El campo es requerido",
    home_phone: "El campo es requerido",
    personal_phone: "El campo es requerido",
    email: "El campo es requerido",
    street: "El campo es requerido",
    neighborhood: "El campo es requerido",
    interior_number: "El campo es requerido",
    exterior_number: "El campo es requerido",
    zip_code: "El campo es requerido",
    municipality: "El campo es requerido",
    state: "El campo es requerido",
  };

  const validateForm = () => {
    let requiredFields = [];

    // Campos comunes que siempre son requeridos
    const commonFields = [
      data.titular_name,
      data.workstation,
      data.home_phone,
      data.personal_phone,
      data.street,
      data.neighborhood,
      data.interior_number,
      data.exterior_number,
      data.zip_code,
      data.state,
      data.municipality,
      data.sector,
    ];

    // Si la empresa está constituida, agrega estos campos
    if (empresaConstituida === true) {
      requiredFields = [
        data.legal_name,
        data.comercial_name,
        data.rfc,
        data.company_type,
        data.email,
        ...commonFields, // Agrega los campos comunes también
      ];
    }
    // Si no está constituida, solo algunos campos son requeridos
    else if (empresaConstituida === false) {
      requiredFields = [data.comercial_name, ...commonFields];
    }

    // Valida que todos los campos requeridos estén llenos
    const allFieldsFilled = requiredFields.every(
      (field) => field && field.trim() !== ""
    );

    // Si todos los campos requeridos están llenos, habilita el botón
    setIsButtonDisabled(!allFieldsFilled);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Verificar si el campo tiene una longitud máxima definida
    if (maxLengths[name] && value.length > maxLengths[name]) {
      return;
    }

    // Actualiza el estado de los datos
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Validaciones específicas para cada campo
    switch (name) {
      case "zip_code":
        if (value.length !== 5 || isNaN(value)) {
          setErrors((prevState) => ({
            ...prevState,
            zip_code: "obligatorio 5 dígitos",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            zip_code: "",
          }));
        }
        break;

      case "rfc":
        if (value.length < 12) {
          setErrors((prevState) => ({
            ...prevState,
            rfc: "mínimo 12 dígitos",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            rfc: "",
          }));
        }
        break;

      case "home_phone":
      case "personal_phone":
        if (value.length < 10 || isNaN(value)) {
          setErrors((prevState) => ({
            ...prevState,
            [name]: "10 dígitos numericos",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            [name]: "",
          }));
        }
        break;

      default:
        // Valida si el campo actual está vacío solo si es requerido
        if (value.trim() === "" && requiredFields[name]) {
          setErrors((prevState) => ({
            ...prevState,
            [name]: requiredFields[name], // Mensaje de error personalizado
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            [name]: "",
          }));
        }
        break;
    }

    // Valida el formulario completo
    validateForm();
  };

  // cambio en la selección del radio button
  const handleRadioChange = (event) => {
    const value = event.target.value === "true"; // Convierte el string a booleano
    setEmpresaConstituida(value);
    // Limpiar los campos del formulario
    // setData({
    //   legal_name: "",
    //   comercial_name: "",
    //   rfc: "",
    //   company_type: "",
    //   sector: "",
    //   titular_name: "",
    //   workstation: "",
    //   home_phone: "",
    //   personal_phone: "",
    //   email: "",
    //   street: "",
    //   neighborhood: "",
    //   interior_number: "",
    //   exterior_number: "",
    //   zip_code: "",
    //   municipality: "",
    //   state: "",
    // });

    // Valida el formulario después de cambiar el radio button
    validateForm();
  };

  const handleSubmit = async () => {
    setLoading(true);
    setChangeCompleted(true);
    const payload = {
      company_id: companyId,
      is_constituted: empresaConstituida,
      legal_name: data.legal_name,
      comercial_name: data.comercial_name,
      rfc: data.rfc,
      company_type: data.company_type,
      sector: data.sector,
      titular_name: data.titular_name,
      workstation: data.workstation,
      home_phone: data.home_phone,
      personal_phone: data.personal_phone,
      email: data.email,
      street: data.street,
      neighborhood: data.neighborhood,
      interior_number: data.interior_number,
      exterior_number: data.exterior_number,
      zip_code: data.zip_code,
      municipality: data.municipality,
      state: data.state,
    };
    updateCompany(accesToken, payload)
      .then((res) => {
        setLoading(false);
        handleClose();
        setChangeCompleted(false);
        notification("empresa actualizada correctamente", "success");
      })
      .catch((err) => {
        notification("No se puede actualizar", "error");
        setLoading(false);
        setChangeCompleted(false);
        handleClose();
      });
  };

  return (
    <Box className={classes.main}>
      <GrClose
        color="primary"
        className={classes.closeIcon}
        onClick={handleClose}
      />
      <Box className={classes.data}>
        <Typography className={classes.title}>Editar empresa</Typography>
        <Typography className={classes.subTitle}>
          Información General
        </Typography>

        {/* Radio */}
        <FormControl style={{ marginBottom: 10 }} disabled={radioDisable}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            className={classes.textQuestion}
          >
            ¿Es una empresa constituida?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={data.is_constituted}
            onChange={handleRadioChange} // Llama al manejador de cambios
          >
            <FormControlLabel value="true" control={<Radio />} label="Si" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {/* Form */}

        <Grid container spacing={1}>
          {/* Si es empresa constituida */}
          {data.is_constituted === "true" && (
            <>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="Nombre Legal"
                  name="legal_name"
                  value={data.legal_name}
                  onChange={handleChange}
                  type="text"
                  className={classes.textField}
                  fullWidth
                  inputProps={{ className: classes.input }}
                  helperText={errors.legal_name}
                  error={!!errors.legal_name}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="Nombre Comercial"
                  name="comercial_name"
                  value={data.comercial_name}
                  onChange={handleChange}
                  type="text"
                  className={classes.textField}
                  fullWidth
                  inputProps={{ className: classes.input }}
                  helperText={errors.comercial_name}
                  error={!!errors.comercial_name}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label="RFC"
                  name="rfc"
                  value={data.rfc}
                  onChange={handleChange}
                  type="text"
                  className={classes.textField}
                  fullWidth
                  inputProps={{ className: classes.input }}
                  helperText={errors.rfc}
                  error={!!errors.rfc}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className={classes.textField}
                    name="company_type"
                    value={data.company_type}
                    onChange={handleChange}
                    label="tipo"
                    defaultValue={data.company_type}
                    disabled={isDisabled}
                  >
                    <MenuItem value="S.A">S.A</MenuItem>
                    <MenuItem value="S.A. de C.V.">S.A. de C.V.</MenuItem>
                    <MenuItem value="SAS">SAS</MenuItem>
                  </Select>
                  <FormHelperText>{errors.company_type}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="Sector o giro"
                  name="sector"
                  value={data.sector}
                  onChange={handleChange}
                  type="text"
                  className={classes.textField}
                  fullWidth
                  inputProps={{ className: classes.input }}
                  helperText={errors.sector}
                  error={!!errors.sector}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                  disabled={isDisabled}
                />
              </Grid>
            </>
          )}
          {/* No es empresa constituida */}
          {data.is_constituted === "false" && (
            <>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="Nombre Comercial"
                  name="comercial_name"
                  value={data.comercial_name}
                  onChange={handleChange}
                  type="text"
                  className={classes.textField}
                  fullWidth
                  inputProps={{ className: classes.input }}
                  helperText={errors.comercial_name}
                  error={!!errors.comercial_name}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="Sector o giro"
                  name="sector"
                  value={data.sector}
                  onChange={handleChange}
                  type="text"
                  className={classes.textField}
                  fullWidth
                  inputProps={{ className: classes.input }}
                  helperText={errors.sector}
                  error={!!errors.sector}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                  disabled={isDisabled}
                />
              </Grid>
            </>
          )}
          {/* Información de contacto */}
          <Grid item xs={12}>
            <Typography className={classes.subTitle}>
              Información de contácto
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="Nombre del representante"
              name="titular_name"
              value={data.titular_name}
              onChange={handleChange}
              type="text"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.titular_name}
              error={!!errors.titular_name}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="Cargo del representante"
              name="workstation"
              value={data.workstation}
              onChange={handleChange}
              type="text"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.workstation}
              error={!!errors.workstation}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              variant="outlined"
              label="Teléfono casa"
              name="home_phone"
              value={data.home_phone}
              onChange={handleChange}
              type="tel"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.home_phone}
              error={!!errors.home_phone}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              variant="outlined"
              label="Teléfono celular"
              name="personal_phone"
              value={data.personal_phone}
              onChange={handleChange}
              type="tel"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.personal_phone}
              error={!!errors.personal_phone}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          {data.is_constituted === "true" && (
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                label="Correo electrónico"
                name="email"
                value={data.email}
                onChange={handleChange}
                type="email"
                className={classes.textField}
                fullWidth
                inputProps={{ className: classes.input }}
                helperText={errors.email}
                error={!!errors.email}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
                disabled={isDisabled}
              />
            </Grid>
          )}

          {/* Dirección */}
          <Grid item xs={12}>
            <Typography className={classes.subTitle}>Dirección</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="Calle"
              name="street"
              value={data.street}
              onChange={handleChange}
              type="text"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.street}
              error={!!errors.street}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="Colonia"
              name="neighborhood"
              value={data.neighborhood}
              onChange={handleChange}
              type="text"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.neighborhood}
              error={!!errors.neighborhood}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              label="No interior"
              name="interior_number"
              value={data.interior_number}
              onChange={handleChange}
              type="text"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.interior_number}
              error={!!errors.interior_number}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              label="No exterior"
              name="exterior_number"
              value={data.exterior_number}
              onChange={handleChange}
              type="text"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.exterior_number}
              error={!!errors.exterior_number}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              label="Código Postal"
              name="zip_code"
              value={data.zip_code}
              onChange={handleChange}
              type="number"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.zip_code}
              error={!!errors.zip_code}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="Estado"
              name="state"
              value={data.state}
              onChange={handleChange}
              type="text"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.state}
              error={!!errors.state}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="Municipio"
              name="municipality"
              value={data.municipality}
              onChange={handleChange}
              type="text"
              className={classes.textField}
              fullWidth
              inputProps={{ className: classes.input }}
              helperText={errors.municipality}
              error={!!errors.municipality}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Activa para actualizar la empresa</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={!isDisabled}
                  onChange={handleEditChange}
                  name="edit-info"
                />
              }
              label="Editar"
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              className={classes.buttonC}
              disabled={loading || isDisabled}
              onClick={handleSubmit}
            >
              <Box display="flex" alignItems="center">
                Actualizar empresa
                {loading && (
                  <CircularProgress
                    size={24}
                    color="inherit"
                    style={{ marginLeft: 10 }} // Espacio entre el texto y el loader
                  />
                )}
              </Box>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditCompany;
