import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_NAVICK_API_URL } = process.env;

export const insertPaymentInvoice = (payload) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_NAVICK_API_URL}/payments/insert`,
    headers: {
      "content-type": "application/json",
      token: getCookie("accessToken"),
    },
  };
  return axios(request);
};
export const updatePrintedPaymentInformation = (payload, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "PUT",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_NAVICK_API_URL}/payments`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };
  return axios(request);
};

export const getPaymentsByDay = (selectedDate, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    data: selectedDate,
    url: `${REACT_APP_NAVICK_API_URL}/payments/by_day/${selectedDate}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
export const getPaymentsByIdInvoice = (idInvoice) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    data: idInvoice,
    url: `${REACT_APP_NAVICK_API_URL}/payments/by_invoice/${idInvoice}`,
    headers: {
      "content-type": "application/json",
      token: getCookie("accessToken"),
    },
  };

  return axios(request);
};
