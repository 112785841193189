export const headers = [
  {
    id: "NAME",
    numeric: false,
    disablePadding: true,
    label: "Nombre",
  },
  {
    id: "personalPhone",
    numeric: false,
    disablePadding: false,
    label: "Celular",
  },
  {
    id: "homePhone",
    numeric: false,
    disablePadding: false,
    label: "Teléfono de casa",
  },
  {
    id: "street",
    numeric: false,
    disablePadding: true,
    label: "Calle",
  },
  {
    id: "externalNumber",
    numeric: false,
    disablePadding: true,
    label: "Número exterior",
  },
  {
    id: "internalNumber",
    numeric: false,
    disablePadding: true,
    label: "Número interior",
  },
  {
    id: "neighborhood",
    numeric: false,
    disablePadding: true,
    label: "Colonia",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: true,
    label: "Ciudad",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: true,
    label: "Estado",
  },
  {
    id: "zipCode",
    numeric: false,
    disablePadding: true,
    label: "Código Postal",
  },
  {
    id: "reference",
    numeric: false,
    disablePadding: false,
    label: "Referencia",
  },
  {
    id: "paymentFrequency",
    numeric: false,
    disablePadding: false,
    label: "Frecuencia de Pago",
  },
  {
    id: "paymentDay",
    numeric: false,
    disablePadding: false,
    label: "Día de Pago",
  },
];
