import React from 'react';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    margin: theme.spacing(1),
    marginTop:50
  },
}));

const CustomBreadcrumbs = ({ links, current }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
      {links.map((link, index) => (
        <Link key={index} underline="hover" color="inherit" href={link.href}>
          {link.text}
        </Link>
      ))}
      <Typography color="text.primary">{current}</Typography>
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
