import React, { useEffect, useReducer, useState } from "react";
import DrawerMenu from "../../components/DrawerMenu/Index";
import CompanyDetailsStyles from "./CompanyDetails.styles";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  Button,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CustomBreadcrumbs from "../../components/sharedComponents/CustomBreadcrumbs";
import InvoiceCardStatus from "../../components/sharedComponents/InvoiceCardStatus";
import imgPaid from "../../assets/images/Check.svg";
import imgDebt from "../../assets/images/Clock.svg";
import imgCancel from "../../assets/images/cancel.svg";
import AdminCard from "../../components/sharedComponents/AdminCard";
import imageChart from "../../assets/images/homeGraphic.svg";
import imageDevice from "../../assets/images/homeDevice.svg";
import show from "../../assets/images/show.svg";
import paid from "../../assets/images/paid.svg";
import cancel from "../../assets/images/cancelPaid.svg";
import finance from "../../assets/images/finance.svg";
import percentage from "../../assets/images/percentage.svg";
import collect from "../../assets/images/collect.svg";

import StatusTabsInvoice from "../../components/sharedComponents/StatusTabsInvoice";
import { INVOICE_STATES } from "../../constants/general";
import { getTotalDataCompany } from "../../api/apiCompanies";
import moment from "moment-timezone";
import "moment/locale/es"; // Importa la localización en español
import InvoicesPayments from "../../components/ModalBodies/InvoicesPayments";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import {
  getPaymentsByIdInvoice,
  insertPaymentInvoice,
} from "../../api/ApiPayments";

import { getInvoices } from "../../api/ApiInvoices";
import PayInvoice from "../../components/ModalBodies/PayInvoice";
import AlertMessage from "../../components/sharedComponents/AlertMessage";
import CancelInvoiceByIdModal from "../../components/ModalBodies/CancelInvoiceByIdModal";
import FinanceCard from "../../components/sharedComponents/FinanceCard";
import useLogout from "../../hooks/useLogout";
import { errors } from "../../utils/ErrorAxios";
moment.locale("es"); // Configura moment para usar español

const CompanyDetails = () => {
  const { companyName, companyId } = useParams();
  const history = useHistory();
  const classes = CompanyDetailsStyles();

  const isUp = true;
  const [loading, setLoading] = useState(false);
  const [availableInvoices, setAvailableInvoices] = useState([]);
  const [companyDetails, setCompanyDetails] = useState("");
  const [clientsCompany, setClientsCompany] = useState("");
  const [invoicesCompany, setInvoicesCompany] = useState("");
  const [invoicesPaid, setInvoicesPaid] = useState(0);
  const [invoicesPending, setInvoicesPending] = useState("");
  const [invoicesCanceled, setInvoicesCanceled] = useState("");
  const [tableInvoices, setTableInvoices] = useState([]);
  const [clientName, setClientName] = useState("");

  /* Modal info invoices */
  const [openPaymentInvoicesInfo, setopenPaymentInvoicesInfo] = useState(false);
  const [idPaymentSelected, setIdPaymentSelected] = useState("");
  const [payments, setPayments] = useState([]);
  const [loaderInvoiceInfo, setloaderInvoiceInfo] = useState(false);

  /* Modal Pay Invoices */
  const handleClosePayInvoice = () => setOpenPayInvoice(false);
  const [currentInvoiceSelected, setCurrentInvoiceSelected] = useState({});
  const [openPayInvoice, setOpenPayInvoice] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingPay, setLoadingPay] = useState(false);

  /* Modal Cancel Invoice */
  const [openCancelInvoiceModal, setOpenCancelInvoiceModal] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [finishCancel, setfinishCancel] = useState(false);

  //paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [logout] = useLogout();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return classes.pending;
      case 2:
        return classes.paid;
      case 3:
        return classes.cancelled;
      default:
        return "";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Pendiente";
      case 2:
        return "Pagada";
      case 3:
        return "Cancelada";
      default:
        return "TODAS";
    }
  };

  const getPaymentTypeText = (description) => {
    switch (description) {
      case "CREDITO":
        return "CRÉDITO";
      case "CONTADO":
        return "CONTADO";
      default:
        return "TODAS";
    }
  };

  const breadcrumbLinks = [
    { href: "/administrador", text: "Administrador" },
    { href: "/administrador", text: "Cobranza" },
    { href: "/administrador/cobranza/altaEmpresa", text: "Alta empresa" },
    { href: "/administrador/cobranza/altaEmpresa/detalles", text: "detalles" },
  ];

  useEffect(() => {
    setLoading(true);
    getTotalDataCompany(companyId)
      .then((res) => {
        if (res.data.statusCode === 200) {
          const result = res.data.body;
          setCompanyDetails(result.companyInformation);
          setClientsCompany(result.clients);
          setInvoicesCompany(result.invoices);
          setInvoicesPaid(result.invoices.paid.paidInvoices);
          setInvoicesPending(result.invoices.pending.pendingInvoices);
          setInvoicesCanceled(result.invoices.canceled.canceledInvoices);
          setTableInvoices(result.invoicesDataTable);
          setAvailableInvoices(result.invoicesDataTable);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      });
  }, [companyId, finishCancel]);

  const handleStatusChange = (statusIndex) => {
    const selectdStatus = INVOICE_STATES[statusIndex];

    if (selectdStatus === "TODAS") {
      setTableInvoices(availableInvoices);
    } else if (selectdStatus === "CRÉDITO" || selectdStatus === "CONTADO") {
      const filtered = availableInvoices.filter(
        (invoice) => getPaymentTypeText(invoice.description) === selectdStatus
      );
      setTableInvoices(filtered);
    } else {
      const filtered = availableInvoices.filter(
        (invoice) => getStatusText(invoice.status) === selectdStatus
      );
      setTableInvoices(filtered);
    }
  };

  /* Close View Invoices */
  const handleCloseUserInvoicesInformation = () => {
    setopenPaymentInvoicesInfo(false);
    setIdPaymentSelected("");
    setPayments([]);
  };

  /* Open View Invoices */
  const handleOpenUserInvoicesInformation = (InvoiceId) => {
    setloaderInvoiceInfo(true);
    setIdPaymentSelected(InvoiceId);
    setopenPaymentInvoicesInfo(true);
    getPaymentsByIdInvoice(InvoiceId)
      .then((res) => {
        setPayments(res.data.body);
        setloaderInvoiceInfo(false);
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      })
      .finally(() => {
        setloaderInvoiceInfo(false);
      });
  };

  /* Open pay Invoice */

  const snackBarReducer = (state, action) => {
    switch (action.type) {
      case "SUCCESS":
        return { ...state, ...action.value };
      case "ERROR":
        return { ...state, ...action.value };
      case "INITIAL":
        return { ...state, open: false };
      default:
        return { ...state };
    }
  };

  const [snackBarState, dispatchSnackBar] = useReducer(snackBarReducer, {
    open: false,
    type: "",
    message: "",
  });

  const handleOpenPayInvoice = (invoice) => {
    setCurrentInvoiceSelected(invoice);
    setClientName(invoice.name);
    setOpenPayInvoice(true);
  };

  const handleOpenAlert = () => {
    setOpen(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmitPayment = (payload) => {
    setLoadingPay(true);
    insertPaymentInvoice(payload)
      .then((res) => {
        dispatchSnackBar({
          type: "SUCCESS",
          value: {
            open: open,
            type: "success",
            message: "El abono se realizó con éxito",
          },
        });
        setLoadingPay(false);
        handleClosePayInvoice();
        setTimeout(() => {
          dispatchSnackBar({
            type: "INITIAL",
            value: {
              open: false,
              type: "",
              message: "",
            },
          });
        }, 6000);
        getInvoices().then((res) => {
          // dispatch(setTotalInvoicesInfo(res.data.result));
          console.log(res.data.body);
        });
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      })
      .finally(() => {
        setLoadingPay(false);
      });
  };

  /* Open Cancel Invoice */

  const handleClickOpenCancelInvoiceModal = (invoice) => {
    setOpenCancelInvoiceModal(true);
    setCurrentInvoice(invoice);
  };

  const handleClickCloseCancelInvoiceModal = () => {
    setOpenCancelInvoiceModal(false);
    setCurrentInvoice(null);
  };

  return (
    <div>
      <DrawerMenu>
        <Box className={classes.mainContainer}>
          <CustomBreadcrumbs links={breadcrumbLinks} />
          <Typography className={classes.companyName}>
            {loading ? (
              <Skeleton variant="rectangular" width={500} height={30} />
            ) : (
              companyName
            )}
          </Typography>
          {/* Sección de Información de la Empresa */}
          <Grid container spacing={5} className={classes.companyInfoContainer}>
            <Grid item xs={12} md={6}>
              <Card className={classes.infoCard}>
                {loading ? (
                  <CardContent>
                    <Skeleton
                      className={classes.skeletonInfoCompany}
                      variant="text"
                      width="80%"
                    />
                    <Skeleton
                      className={classes.skeletonInfoCompany}
                      variant="text"
                      width="60%"
                    />
                    <Skeleton
                      className={classes.skeletonInfoCompany}
                      variant="text"
                      width="80%"
                    />
                    <Skeleton
                      className={classes.skeletonInfoCompany}
                      variant="text"
                      width="60%"
                    />
                    <Skeleton
                      className={classes.skeletonInfoCompany}
                      variant="text"
                      width="50%"
                    />
                  </CardContent>
                ) : (
                  <CardContent>
                    <Typography className={classes.txtInfoCompany} variant="h6">
                      Información de la empresa
                    </Typography>
                    <Box className={classes.information}>
                      <Box className={classes.section}>
                        <Typography
                          className={classes.txtSeccion}
                          variant="body1"
                        >
                          Contacto
                        </Typography>
                        <Typography
                          className={classes.txtValue}
                          variant="body2"
                        >
                          <strong className={classes.txtKey}>
                            Responsable:
                          </strong>{" "}
                          {companyDetails.titular_name}
                        </Typography>
                        <Typography
                          className={classes.txtValue}
                          variant="body2"
                        >
                          <strong className={classes.txtKey}>Cargo:</strong>{" "}
                          {companyDetails.workstation}
                        </Typography>
                        <Typography
                          className={classes.txtValue}
                          variant="body2"
                        >
                          <strong className={classes.txtKey}>Teléfono:</strong>{" "}
                          {companyDetails.personal_phone}
                        </Typography>
                      </Box>
                      <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.verticalDivider}
                      />
                      <Box className={classes.section}>
                        <Typography
                          className={classes.txtSeccion}
                          variant="body1"
                        >
                          Dirección
                        </Typography>
                        <Typography
                          className={classes.txtValue}
                          variant="body2"
                        >
                          <strong className={classes.txtKey}>Calle:</strong>{" "}
                          {companyDetails.street}
                        </Typography>
                        <Typography
                          className={classes.txtValue}
                          variant="body2"
                        >
                          <strong className={classes.txtKey}>Colonia:</strong>{" "}
                          {companyDetails.neighborhood}
                        </Typography>
                        <Typography
                          className={classes.txtValue}
                          variant="body2"
                        >
                          <strong className={classes.txtKey}>No:</strong>{" "}
                          {companyDetails.exterior_number}
                        </Typography>
                        <Typography
                          className={classes.txtValue}
                          variant="body2"
                        >
                          <strong className={classes.txtKey}>CP:</strong>{" "}
                          {companyDetails.zip_code}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                )}
              </Card>
            </Grid>

            {/*  clientes y facturas */}

            <Grid item xs={12} md={6}>
              <Box className={classes.cardsContainer}>
                <AdminCard
                  title="Clientes"
                  number={clientsCompany.totalClientsCompany}
                  newAvance={clientsCompany.newWeeklyClientsCompany}
                  image={imageChart}
                  message="nuevos esta semana"
                  loading={loading}
                  isUp={isUp}
                />
                <Box mt={3}>
                  <AdminCard
                    title="Facturas"
                    number={invoicesCompany.totalInvoicesCompany}
                    newAvance={invoicesCompany.newWeeklyInvoices}
                    image={imageDevice}
                    message="nuevos esta semana"
                    loading={loading}
                    isUp={isUp}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* Sección de Facturas */}
          <Box>
            <Typography className={classes.titleSection} variant="h5">
              Resúmen financiero
            </Typography>

            <Box className={classes.containerInvoiceCardStatus}>
              <FinanceCard
                title="Cobranza mensual"
                number={2000}
                image={finance}
                loading={loading}
              />
              <FinanceCard
                title="Comisión"
                number={500}
                image={percentage}
                loading={loading}
              />
              <FinanceCard
                title="Total por cobrar"
                number={1500}
                image={collect}
                loading={loading}
              />
            </Box>

            <Typography className={classes.titleSection} variant="h5">
              Facturas de la empresa
            </Typography>

            <Box className={classes.containerInvoiceCardStatus}>
              <InvoiceCardStatus
                img={imgPaid}
                title="Pagadas"
                total={
                  invoicesPaid.total_invoices &&
                  invoicesPaid.total_invoices.length > 0
                    ? invoicesPaid.total_invoices
                    : 0
                }
                value={
                  invoicesPaid.total_amount &&
                  invoicesPaid.total_amount.length > 0
                    ? invoicesPaid.total_amount
                    : 0
                }
                status="paid"
                loading={loading}
              />
              <InvoiceCardStatus
                img={imgDebt}
                title="Sin pagar"
                total={
                  invoicesPending.total_invoices &&
                  invoicesPending.total_invoices.length > 0
                    ? invoicesPending.total_invoices
                    : 0
                }
                value={
                  invoicesPending.remaining_amount &&
                  invoicesPending.remaining_amount.length > 0
                    ? invoicesPending.remaining_amount
                    : 0
                }
                status="pending"
                loading={loading}
              />
              <InvoiceCardStatus
                img={imgCancel}
                title="Canceladas"
                total={
                  invoicesCanceled.total_invoices &&
                  invoicesCanceled.total_invoices.length > 0
                    ? invoicesCanceled.total_invoices
                    : 0
                }
                value={
                  invoicesCanceled.remaining_amount &&
                  invoicesCanceled.remaining_amount.length > 0
                    ? invoicesCanceled.remaining_amount
                    : 0
                }
                status="cancel"
                loading={loading}
              />
            </Box>
            {/* Tabs filter*/}
            <StatusTabsInvoice onChangeStatus={handleStatusChange} />

            {/* Data Table */}

            <TableContainer
              component={Paper}
              className={classes.tableComponent}
            >
              <Table className={classes.table} aria-label="invoice table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      ID FACTURA
                    </TableCell>
                    <TableCell className={classes.tableHead}>CREADA</TableCell>
                    <TableCell className={classes.tableHead}>
                      CANTIDAD
                    </TableCell>
                    <TableCell className={classes.tableHead}>TIPO</TableCell>
                    <TableCell className={classes.tableHead}>ADEUDO</TableCell>
                    <TableCell className={classes.tableHead}>ESTADO</TableCell>
                    <TableCell className={classes.tableHead}>
                      ACCIONES
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? Array.from(new Array(5)).map((_, index) => (
                        <TableRow key={index}>
                          <TableCell colSpan={7}>
                            <Skeleton className={classes.skeleton} />
                          </TableCell>
                        </TableRow>
                      ))
                    : tableInvoices
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((invoice) => (
                          <TableRow key={invoice.id_invoice}>
                            <TableCell className={classes.tableBody}>
                              {invoice.id_invoice}
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              {moment(invoice.created_at)
                                .tz("America/Mexico_City")
                                .locale("es")
                                .format("D [de] MMMM [del] YYYY")}
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              ${invoice.total_amount}
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              {invoice.description === "CREDITO"
                                ? "Crédito"
                                : "Contado"}
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              {invoice.remaining_payment}
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              <span
                                className={`${classes.status} ${getStatusClass(
                                  invoice.status
                                )}`}
                              >
                                {getStatusText(invoice.status)}
                                {/* {invoice.status_description} */}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              <Tooltip title="Ver información">
                                <Button
                                  className={classes.iconButton}
                                  onClick={() => {
                                    handleOpenUserInvoicesInformation(
                                      invoice.id_invoice
                                    );
                                  }}
                                >
                                  <img src={show} alt="ver" />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Abonar">
                                <Button
                                  className={classes.iconButton}
                                  onClick={() =>
                                    handleOpenPayInvoice(invoice, invoice.name)
                                  }
                                  disabled={
                                    invoice.status === 3 || invoice.status === 2
                                  }
                                >
                                  <img src={paid} alt="abonar" />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Cancelar">
                                <Button
                                  className={classes.iconButton}
                                  onClick={() =>
                                    handleClickOpenCancelInvoiceModal(invoice)
                                  }
                                  disabled={
                                    invoice.status === 3 || invoice.status === 2
                                  }
                                >
                                  <img src={cancel} alt="cancelar" />
                                </Button>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={tableInvoices.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20]}
                labelRowsPerPage="Filas por página"
              />
            </TableContainer>
          </Box>
          {/* View Invoices */}
          <ModalComponent
            handleClose={handleCloseUserInvoicesInformation}
            open={openPaymentInvoicesInfo}
            body={
              <InvoicesPayments
                idPayment={idPaymentSelected}
                payments={payments}
                handleClose={handleCloseUserInvoicesInformation}
                loaderInvoiceInfo={loaderInvoiceInfo}
              />
            }
          />
          {/* Modal Pay Invoice */}
          <ModalComponent
            handleClose={handleClosePayInvoice}
            open={openPayInvoice}
            body={
              <PayInvoice
                handleClose={handleClosePayInvoice}
                invoiceInfo={currentInvoiceSelected}
                dispatchSnackBar={dispatchSnackBar}
                openAlert={open}
                handleOpenAlert={handleOpenAlert}
                handleCloseAlert={handleCloseAlert}
                handleSubmitPayment={handleSubmitPayment}
                loading={loadingPay}
                clientName={clientName}
              />
            }
          />
          <ModalComponent
            handleClose={handleClickCloseCancelInvoiceModal}
            open={openCancelInvoiceModal}
            body={
              <CancelInvoiceByIdModal
                handleClickCloseModal={handleClickCloseCancelInvoiceModal}
                invoiceinfo={currentInvoice}
                clientName={clientName}
                setfinishCancel={setfinishCancel}
              />
            }
          />
          {/* Modals */}
          <AlertMessage
            open={open}
            handleClose={handleCloseAlert}
            {...snackBarState}
          />
        </Box>
      </DrawerMenu>
    </div>
  );
};

export default CompanyDetails;
