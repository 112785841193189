import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  availableRoutes: [],
  routesCatalog: [],
};

export const routesInformationReducer = createSlice({
  name: "routes",
  initialState,
  reducers: {
    setAvailableRoutes(state, { payload }) {
      state.availableRoutes = payload;
    },
    setRoutesCatalog(state, { payload }) {
      state.routesCatalog = payload;
    },
  },
});

export const { setAvailableRoutes } = routesInformationReducer.actions;
export const { setRoutesCatalog } = routesInformationReducer.actions;

export const selectAvailableRoutes = (state) =>
  state.routes ? state.routes.availableRoutes : null;

export const selectRoutesCatalog = (state) =>
  state.routes ? state.routes.routesCatalog : null;

export default routesInformationReducer.reducer;
