import { makeStyles } from "@material-ui/core";

const StatusTabsInvoiceStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 1065,
        marginTop:30,
        height:60
    },
    tab: {
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.light,
          color: '#fff',
          borderRadius: '10px',
        },
    },

}))
export default StatusTabsInvoiceStyles;