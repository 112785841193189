import { makeStyles } from "@material-ui/core/styles";

const FilterComponentStyles = makeStyles((theme) => ({
  filterComponent: {
    display: "flex",
    width: "90%",
    marginBottom: 50,
    marginTop: theme.spacing(2),
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2), // Aplica margen a todos los hijos excepto el último
    },
  },
  paramsDropdown: {
    width: 200,
  },
}));

export default FilterComponentStyles;
