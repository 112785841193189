import { removeCookie } from "../utils/sessionCookie";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import { setIsAuth } from "../store/slices/user";
import { setAccessToken } from "../store/slices/clients";
import { ROUTES_CONSTANTS } from "../constants/routesConstants";
import {
  setClientsHome,
  setInvoicesHome,
  setLastInvoices,
  setLastUpdateInfo,
  setMonthlySales,
  setTotalInvoices,
  setWeeklyCollection,
} from "../store/slices/home";

const useLogout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logout = () => {
    removeCookie("accessToken");
    dispatch(setIsAuth(false));
    dispatch(setAccessToken(""));
    dispatch(setClientsHome(""));
    dispatch(setWeeklyCollection(""));
    dispatch(setInvoicesHome(""));
    dispatch(setTotalInvoices(""));
    dispatch(setMonthlySales(""));
    dispatch(setLastInvoices(""));
    dispatch(setLastUpdateInfo(""));
    history.push(ROUTES_CONSTANTS.LOGIN);
  };

  return [logout];
};

export default useLogout;
