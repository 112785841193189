import React, { useState } from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { AiFillHome, AiOutlineLogout, AiFillIdcard } from "react-icons/ai";
import DrawerMenuStyles from "./DrawerMenu.styles";
import { MdOutlinePayment } from "react-icons/md";
import { MdOutlinePayments } from "react-icons/md";
import { GiArchiveResearch } from "react-icons/gi";

//icons
import { ImUsers } from "react-icons/im";
import { ImUserPlus } from "react-icons/im";
import { BsBarChartFill } from "react-icons/bs";
import { SiGooglemaps } from "react-icons/si";
import { FaFileInvoiceDollar, FaUserFriends } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { selectLogedUserType } from "../../store/slices/user";
import { MdExpandLess, MdExpandMore, MdRule } from "react-icons/md";
import { Collapse } from "@material-ui/core";
import useLogout from "../../hooks/useLogout";

const DrawerMenu = (props) => {
  const { children } = props;
  const classes = DrawerMenuStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [logout] = useLogout();

  const userTypeId = useSelector(selectLogedUserType);

  const [openList, setOpenList] = useState([false, false, false]);

  const handleOpenCollapse = (pos) => {
    setOpenList((list) => {
      const a = list.map((list, index) => {
        if (pos === index) {
          return !list;
        }
        return list;
      });
      return a;
    });
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const adminItems = [
    {
      name: "Inicio",
      icon: <AiFillHome className={classes.icon} />,
      redirectTo: ROUTES_CONSTANTS.ADMIN,
      type: "item",
    },
    {
      name: "Clientes",
      icon: <ImUsers className={classes.icon} />,
      redirectTo: ROUTES_CONSTANTS.CLIENTS,
      type: "item",
    },
    {
      name: "Subir clientes",
      icon: <ImUserPlus className={classes.icon} />,
      redirectTo: ROUTES_CONSTANTS.ADD_CLIENTS,
      type: "item",
    },
    {
      name: "Rutas",
      icon: <SiGooglemaps className={classes.icon} />,
      redirectTo: ROUTES_CONSTANTS.ROUTES,
      type: "item",
    },

    {
      name: "Usuarios",
      icon: <FaUserFriends className={classes.icon} />,
      redirectTo: ROUTES_CONSTANTS.USERS,
      type: "item",
    },

    {
      name: "Consultas",
      icon: <GiArchiveResearch className={classes.icon} />,
      type: "dropdown",
      itemListPosition: 0,
      listItems: [
        {
          id: 1,
          label: "Auditoria",
          redirectTo: ROUTES_CONSTANTS.AUDITS,
          icon: <MdRule className={classes.subIcon} />,
        },
        {
          id: 2,
          label: "Fichas",
          icon: <AiFillIdcard className={classes.subIcon} />,
          redirectTo: ROUTES_CONSTANTS.REMAINING_PAYMENTS,
        },
        {
          id: 3,
          label: "Facturas",
          icon: <FaFileInvoiceDollar className={classes.subIcon} />,
          redirectTo: ROUTES_CONSTANTS.INVOICE,
        },
        {
          id: 4,
          label: "Cobranza",
          icon: <MdOutlinePayment className={classes.subIcon} />,
          redirectTo: ROUTES_CONSTANTS.PAYMENTS,
        },
        {
          id: 5,
          label: "Cobranza diaria",
          icon: <MdOutlinePayments className={classes.subIcon} />,
          redirectTo: ROUTES_CONSTANTS.DAILY_COLLECTION,
        },
      ],
    },
    {
      name: "Cobranza",
      icon: <GiArchiveResearch className={classes.icon} />,
      type: "dropdown",
      itemListPosition: 1,
      listItems: [
        {
          id: 1,
          label: "Alta empresa",
          redirectTo: ROUTES_CONSTANTS.COMPANIES,
          icon: <MdRule className={classes.subIcon} />,
        },
        {
          id: 2,
          label: "Alta cliente",
          icon: <AiFillIdcard className={classes.subIcon} />,
          redirectTo: ROUTES_CONSTANTS.REMAINING_PAYMENTS,
        },
      ],
    },
    {
      name: "Dashboards",
      icon: <BsBarChartFill className={classes.icon} />,
      type: "dropdown",
      itemListPosition: 2,
      listItems: [
        {
          id: 1,
          label: "Cobranza Zona",
          redirectTo: ROUTES_CONSTANTS.PAYMENTS_ZONE,
          icon: <MdRule className={classes.subIcon} />,
        },
      ],
    },
    {
      name: "Cerrar Sesión",
      icon: <AiOutlineLogout className={classes.icon} />,
      handleClick: logout,
      type: "item",
    },
  ];

  const partnerItems = [
    {
      name: "Inicio",
      icon: <AiFillHome className={classes.icon} />,
      redirectTo: ROUTES_CONSTANTS.PARTNER_DETAILS,
      type: "item",
    },
    {
      name: "Cerrar Sesión",
      icon: <AiOutlineLogout className={classes.icon} />,
      handleClick: logout,
      type: "item",
    },
  ];

  const iconStyles = {
    fontSize: 22,
    color: "#fff",
  };

  const items = userTypeId === 2 ? adminItems : partnerItems;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      ></AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon style={{ color: "white" }} fontSize="large" />
              ) : (
                <ChevronLeftIcon style={{ color: "white" }} fontSize="large" />
              )}
            </IconButton>
          ) : (
            <IconButton
              style={{ color: "white" }}
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
        </div>
        <List>
          {open && (
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    variant="h1"
                    align="center"
                    className={classes.NavickDrawerItem}
                  >
                    NAVICK
                  </Typography>
                }
              />
            </ListItem>
          )}
          {items.map((item, index) =>
            item.type === "dropdown" ? (
              <List key={index}>
                <ListItem
                  button
                  className={classes.listItem}
                  onClick={() => {
                    handleOpenCollapse(item.itemListPosition);
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h1" className={classes.listItemText}>
                        {item.name}
                      </Typography>
                    }
                  />
                  {openList[item.itemListPosition] ? (
                    <MdExpandLess style={iconStyles} />
                  ) : (
                    <MdExpandMore style={iconStyles} />
                  )}
                </ListItem>
                <Collapse
                  in={openList[item.itemListPosition]}
                  timeout="auto"
                  unmountOnExit
                >
                  {item.listItems.map((itemList) => (
                    <List component="div" disablePadding key={itemList.id}>
                      <ListItem
                        button
                        className={classes.listSubItemText}
                        onClick={() => history.push(itemList.redirectTo)}
                      >
                        <ListItemIcon>{itemList.icon}</ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="h1"
                              className={classes.listSubItemText}
                            >
                              {itemList.label}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  ))}
                  ;
                </Collapse>
              </List>
            ) : (
              <ListItem
                button
                className={classes.listItem}
                key={index}
                onClick={() =>
                  item.handleClick
                    ? item.handleClick()
                    : history.push(item.redirectTo)
                }
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h1" className={classes.listItemText}>
                      {item.name}
                    </Typography>
                  }
                />
              </ListItem>
            )
          )}
        </List>
      </Drawer>
      {/* Rendering all the other pages */}
      {children}
    </div>
  );
};

export default DrawerMenu;
