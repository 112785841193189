export const headCells = [
  { id: "username", numeric: false, disablePadding: false, label: "Usuario" },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "id_route",
    numeric: true,
    disablePadding: false,
    label: "Ruta designada",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Estado" },
];
