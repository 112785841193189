import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  changeUserStatus,
  createUser,
  getUsers,
  updateUserInformation,
} from "../../api/apiUsers";
import DrawerMenu from "../../components/DrawerMenu/Index";
import UserinformationBodyModal from "../../components/ModalBodies/UserInformationBodyModal";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import UserStylesPage from "./Users.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAvailableUsers,
  selectCurrentUser,
  setAvailableUsers,
  setIsAuth,
  setSelectedUser,
} from "../../store/slices/user";
import DialogBodyModal from "../../components/ModalBodies/DialogBodyModal";
import { MdAddCircleOutline } from "react-icons/md";
import { ERROR_MESSAGES } from "../../constants/constantMessages";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { notification } from "../../utils/notifications";
import { headCells } from "./utils";
import ChangePassword from "../../components/ModalBodies/ChangePassword";
import UsersDataTable from "./UsersDataTable";
import { errors } from "../../utils/ErrorAxios";
import { getRoutes } from "../../api/ApiRoutes";
import { getCompaniesCatalog } from "../../api/apiCompanies";
import { selectAccessToken, setAccessToken } from "../../store/slices/clients";
import useLogout from "../../hooks/useLogout";

const UsersPage = () => {
  const classes = UserStylesPage();

  const currentUser = useSelector(selectCurrentUser);
  const accessToken = useSelector(selectAccessToken);
  const [loadingUsersInfo, setLoadingUsersInfo] = useState(false);
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const history = useHistory();
  const availableUsers = useSelector(selectAvailableUsers);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [logout] = useLogout();

  useEffect(() => {
    getCompaniesCatalog(accesToken)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCompanies(res.data.body);
        } else {
          console.log("ocurrió un error al obtener las compañias");
        }
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      });
  }, []);

  useEffect(() => {
    getRoutes()
      .then((res) => {
        if (res.data.statusCode === 200) {
          setAvailableRoutes(res.data.body);
        } else {
          console.log("ocurrió un error al obtener las rutas");
        }
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      });
  }, []);

  useEffect(() => {
    setLoadingUsersInfo(true);
    getUsers(accessToken)
      .then((res) => {
        dispatch(setAvailableUsers(res.body));
        setLoadingUsersInfo(false);
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
        setLoadingUsersInfo(false);
      });
  }, [accessToken, dispatch, history]);

  const isThisUserActive = currentUser.status === 1;

  //Modal states
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [openUserInformation, setopenUserInformation] = useState(false);
  const [openUpdateUserInformation, setOpenUpdateUserInformation] =
    useState(false);
  const accesToken = useSelector(selectAccessToken);
  const [openDeleteUser, setopenDeleteUser] = useState(false);
  const [successCreateUser, setSuccessCreateUser] = useState(false);
  const [loadingCreateUser, setLoadingCreateUser] = useState(false);
  const [loadingChangeUserStatus, setLoadingChangeUserStatus] = useState(false);

  const handleOpenCreateUser = (user) => {
    setOpenCreateUser(true);
  };

  const handleCloseCreateUser = () => {
    setOpenCreateUser(false);
  };

  const handleOpenUpdateUserInformation = (user) => {
    setOpenUpdateUserInformation(true);
    dispatch(setSelectedUser(user));
  };

  const handleCloseChangeUserPassword = () => {
    setOpenUpdateUserInformation(false);
  };

  const handleOpenViewUserInformation = (user) => {
    setopenUserInformation(true);
    dispatch(setSelectedUser(user));
  };

  const handleCloseViewUserInformation = () => {
    setopenUserInformation(false);
  };

  const handleOpenDeleteUser = (user) => {
    setopenDeleteUser(true);
    dispatch(setSelectedUser(user));
  };

  const handleCloseDeleteUser = () => {
    setopenDeleteUser(false);
  };

  const handleCreateNewUser = async (user) => {
    const userInformation = {
      username: user.username,
      name: user.name,
      idUserType: user.idUserType,
      password: user.password,
      /* status: user.status, */
      idRoute: user.idRoute,
      company_id: user.company_id,
    };

    await createUser(userInformation)
      .then((res) => {
        setLoadingCreateUser(true);
        if (res.status === 200) {
          setLoadingCreateUser(false);
          notification(
            "El usuario que intentas crear ya existe, por favor elige otro",
            "error"
          );
          return;
        }
        if (res.status === 201) {
          setLoadingCreateUser(false);
          notification("Usuario creado", "success");
          getUsers(accessToken).then((res) => {
            dispatch(setAvailableUsers(res.body));
          });
          /* getUsers().then((res) => {
            setAvailableUsers(res.data.result);
            handleCloseCreateUser();
            return;
          }); */
        }
        handleCloseCreateUser();
      })
      .catch((err) => {
        if (err.response) {
          setLoadingCreateUser(false);
          setSuccessCreateUser(false);
          if (err.response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
            dispatch(setIsAuth(false));
            dispatch(setAccessToken(""));
            history.push(ROUTES_CONSTANTS.LOGIN);
          } else if (err.response.status === 409) {
            // Manejo del caso donde el usuario ya existe
            notification(
              "El username ya existe, por favor elige otro",
              "error"
            );
          } else if (err.response.status === 400) {
            // Manejo del caso donde faltan campos
            notification(
              "Todos los campos son obligatorios y no deben estar en blanco",
              "error"
            );
          } else {
            // Otros errores de respuesta HTTP
            console.error("Error inesperado:", err.response.data);
            notification(
              "Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.",
              "error"
            );
          }
        } else {
          // Errores sin respuesta HTTP (problemas de red, etc.)
          notification(
            "Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.",
            "error"
          );
        }
      });
  };

  const handleChangeUserStatus = async () => {
    if (currentUser) {
      const statusToChange = currentUser.status === 1 ? 0 : 1;
      const idUser = currentUser.id_user;

      setLoadingChangeUserStatus(true);

      await changeUserStatus(idUser, statusToChange)
        .then((res) => {
          if (res.status === 204) {
            notification("El usuario fue actualizado", "success");
            setopenDeleteUser(false);
            getUsers(accessToken).then((res) => {
              dispatch(setAvailableUsers(res.body));
            });
            /* if (res?.data?.result?.users) {
              dispatch(setAvailableUsers(res.data.result.users));
            } */
          }
        })
        .catch((err) => {
          notification("No se pudo actualizar el usuario", "failed");
          if (err.response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
            dispatch(setIsAuth(false));
            dispatch(setAccessToken(""));
            history.push(ROUTES_CONSTANTS.LOGIN);
          }
        })
        .finally(() => {
          setLoadingChangeUserStatus(false);
        });
    }
  };

  const handleSaveInformation = async (user) => {
    const payload = {
      userId: parseInt(user.idUser),
      name: user.name.toLowerCase(),
      username: user.username.toLowerCase(),
      idUserType: user.idUserType,
      status: user.status,
      idRoute: user.idRoute,
      company_id: user.idUserType === 7 ? user.company_id : 0,
    };
    await updateUserInformation(payload)
      .then((res) => {
        if (res.status === 204) {
          notification("Usuario actualizado", "success");
          handleCloseViewUserInformation();

          getUsers(accessToken).then((res) => {
            dispatch(setAvailableUsers(res.body));
          });
        }
      })
      .catch((err) => {
        if (err.response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      });
  };

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="h2" className={classes.usersSectionTitle}>
          Listado de Usuarios
        </Typography>
        <Typography variant="subtitle2">
          {availableUsers?.length} usuarios disponibles
        </Typography>

        <Button
          startIcon={<MdAddCircleOutline />}
          variant="contained"
          color="primary"
          className={classes.saveUserButton}
          onClick={handleOpenCreateUser}
        >
          <Typography className={classes.addUserBtn}>
            Agregar Usuario
          </Typography>
        </Button>

        {availableUsers && (
          <UsersDataTable
            users={availableUsers}
            loading={loadingUsersInfo}
            headCells={headCells}
            openViewUserInformation={handleOpenViewUserInformation}
            openDeleteUser={handleOpenDeleteUser}
            openChangePassword={handleOpenUpdateUserInformation}
          />
        )}
      </Box>

      <ModalComponent
        handleClose={handleCloseViewUserInformation}
        open={openUserInformation}
        body={
          <UserinformationBodyModal
            userInformation={currentUser}
            handleClose={handleCloseViewUserInformation}
            saveInformationHandler={handleSaveInformation}
            type="view"
            disabled={true}
            companies={companies}
            animationTitle="Actualizando usuario"
            animationSubtitle="Por favor, no cierre la ventana"
            availableRoutes={availableRoutes}
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseChangeUserPassword}
        open={openUpdateUserInformation}
        body={<ChangePassword closeModal={handleCloseChangeUserPassword} />}
      />
      <ModalComponent
        handleClose={handleCloseCreateUser}
        open={openCreateUser}
        body={
          <UserinformationBodyModal
            handleClose={handleCloseCreateUser}
            saveInformationHandler={handleCreateNewUser}
            type="new"
            disabled={false}
            companies={companies}
            loading={loadingCreateUser}
            success={successCreateUser}
            animationTitle="Creando el nuevo usuario"
            animationSubtitle="Por favor, no cierre la ventana"
            availableRoutes={availableRoutes}
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseDeleteUser}
        open={openDeleteUser}
        body={
          <DialogBodyModal
            // dataInformation={currentUser}
            titleDialog="¿Estas Seguro?"
            subtitleDialog={`¿Estas seguro de ${
              isThisUserActive ? "desactivar" : "activar"
            } este usuario? Esta acción no puede ser revocada después.`}
            handleCancel={handleCloseDeleteUser}
            handleConfirm={handleChangeUserStatus}
            loading={loadingChangeUserStatus}
          />
        }
      />
    </DrawerMenu>
  );
};

export default UsersPage;
