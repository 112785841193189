import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DrawerMenu from "../../components/DrawerMenu/Index";
import ClientStyles from "./Clients.styles";
import { BsSearch } from "react-icons/bs";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import ViewClientInfo from "../../components/ModalBodies/ViewClientInfo";
import DialogBodyModal from "../../components/ModalBodies/DialogBodyModal";
import { MdFilterList, MdFilterListOff } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import {
  getClients,
  updateClientsRoutesMassive,
  updateClientStatus,
} from "../../api/apiClients";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccessToken,
  selectClientInformation,
  selectClients,
  selectClientsToUpdateRoute,
  setAccessToken,
  setClientInformation,
  setClients,
  setClientsToUpdateRoute,
} from "../../store/slices/clients";
import ChooseRouteBodyModal from "../../components/ModalBodies/ChooseRouteBodyModal";
import { ERROR_MESSAGES } from "../../constants/constantMessages";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { setIsAuth } from "../../store/slices/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InvoicesPaymentsInformation from "../../components/ModalBodies/InvoicesPaymentsInformation";
import ClientsListDataTable from "./ClientsDataTable";
import { notification } from "../../utils/notifications";
import FilterComponent from "../../components/sharedComponents/FilterComponent";
import { errors } from "../../utils/ErrorAxios";
import useLogout from "../../hooks/useLogout";
import { getRoutes } from "../../api/ApiRoutes";
import { getPriceList } from "../../api/apiPriceList";
import { getPaymentsFrecuency } from "../../api/apiPayment_frequency";

const Clients = () => {
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [searchBarvalue, setSearchBarvalue] = useState("");
  const clientsInfo = useSelector(selectClients);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [paymentFrecuency, setPaymentFrecuency] = useState([]);

  //View Modal variables

  const currentClient = useSelector(selectClientInformation);
  const isThisClientActive = currentClient?.status === 1;

  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [openViewInfo, setopenViewInfo] = useState(false);
  const [openPaymentInvoicesInfo, setopenPaymentInvoicesInfo] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openDeleteClient, setOpenDeleteClient] = useState(false);
  const [openChooseRouter, setOpenChooseRoute] = useState(false);
  const [filteredClients, setFilteredClients] = useState([]);
  const [massiveUpdate, setMassiveUpdate] = useState(false);
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [successLoad, setSuccessLoad] = useState(false);
  const [clientIdValue, setClientIdValue] = useState("");
  const [parameterToSearch, setParameterToSearch] = useState("name");
  const clientsMassiveUpdate = useSelector(selectClientsToUpdateRoute);
  const accesToken = useSelector(selectAccessToken);
  const history = useHistory();

  const [getClientFlag, setgetClientFlag] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [logout] = useLogout();

  const [filters, setFilters] = useState({
    id: "",
    municipaly: "",
    cp: "",
    status: 1,
  });

  // Actualiza el filtro en el estado
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  useEffect(() => {
    getRoutes()
      .then((res) => {
        if (res.data.statusCode === 200) {
          setAvailableRoutes(res.data.body);
        } else {
          console.log("ocurrió un error al obtener las rutas");
        }
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      });
  }, []);

  useEffect(() => {
    getPaymentsFrecuency(accesToken)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setPaymentFrecuency(res.data.body);
        } else {
          console.log("ocurrió un error al obtener las frecuencias de pago");
        }
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      });
  }, []);

  useEffect(() => {
    getPriceList()
      .then((res) => {
        if (res.data.statusCode === 200) {
          setPriceList(res.data.body);
        } else {
          console.log("ocurrió un error al obtener las rutas");
        }
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      });
  }, []);

  useEffect(() => {
    if (clientsInfo.length > 0) {
      let filteredClients = clientsInfo;
      if (filters.id) {
        filteredClients = filteredClients.filter((cliente) =>
          cliente.id_client
            .toString()
            .toLowerCase()
            .includes(filters.id.toLowerCase())
        );
      }

      if (filters.municipaly) {
        filteredClients = filteredClients.filter((cliente) =>
          cliente.city.toLowerCase().includes(filters.municipaly.toLowerCase())
        );
      }

      if (filters.cp) {
        filteredClients = filteredClients.filter((cliente) =>
          cliente.zip_code
            .toString()
            .toLowerCase()
            .includes(filters.cp.toLowerCase())
        );
      }

      if (filters.status !== "") {
        filteredClients =
          filteredClients.length > 0 &&
          filteredClients.filter(
            (cliente) => cliente.status === filters.status
          );
      }
      setFilteredClients(filteredClients);
    }
  }, [filters, clientsInfo]);

  //filtrado
  const handleFilterClick = () => {
    setFilterActive((prev) => !prev);

    // Limpia los filtros cuando se oculta el filtrado
    if (filterActive) {
      setFilters({
        id: "",
        municipaly: "",
        cp: "",
        status: 1,
      });
    }
  };

  //dispatch
  const dispatch = useDispatch();

  const handleOpenCreateClient = () => {
    setOpenCreateClient(true);
  };

  const handleOpenViewInfo = (client) => {
    dispatch(setClientInformation(client));
    setopenViewInfo(true);
  };

  const handleOpenEditUser = (client) => {
    dispatch(setClientInformation(client));
    setOpenEditUser(true);
  };
  const handleOpenChooseRoute = () => setOpenChooseRoute(true);
  const handleCloseChooseRoute = () => setOpenChooseRoute(false);

  const handleOpenDeleteClient = (client) => {
    setOpenDeleteClient(true);
    dispatch(setClientInformation(client));
  };

  const handleCloseViewInfo = () => setopenViewInfo(false);
  const handleCloseCreateClient = () => setOpenCreateClient(false);
  const handleCloseEdituser = () => setOpenEditUser(false);
  const handleCloseDeleteClient = () => setOpenDeleteClient(false);
  const handleOpenUserInvoicesInformation = (clientId, name) => {
    history.push(`/administrador/clientes/facturas/${name}/${clientId}`);
  };
  const handleCloseUserInvoicesInformation = () => {
    setopenPaymentInvoicesInfo(false);
    setClientIdValue("");
  };

  const handleUpdateRoutes = async (idRoute) => {
    setLoadUpdate(true);
    const updatedClientsRoutes =
      (await clientsMassiveUpdate) &&
      clientsMassiveUpdate.length > 0 &&
      clientsMassiveUpdate.map((client) => ({
        idClient: parseInt(client),
        idRoute: parseInt(idRoute),
      }));
    updateClientsRoutesMassive(updatedClientsRoutes, accesToken)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setTimeout(() => {
            setLoadUpdate(false);
            setSuccessLoad(true);
            getClients(accesToken)
              .then((res) => {
                dispatch(setClients(res.data.result));
                window.location.reload();
              })
              .catch(({ response }) => {
                if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
                  dispatch(setIsAuth(false));
                  dispatch(setAccessToken(""));
                  history.push(ROUTES_CONSTANTS.LOGIN);
                }
              });
          }, 5000);
        }
      })
      .catch(({ response }) => {
        if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      })
      .finally((error) => {
        setMassiveUpdate(false);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoadingInfo(true);
      try {
        const res = await getClients(accesToken);
        if (res.data.statusCode === 200) {
          setFilteredClients(res.data.body);
          dispatch(setClients(res.data.body));
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingInfo(false);
    };
    fetchData();
  }, [accesToken, dispatch, getClientFlag]);

  useEffect(() => {
    dispatch(setClientsToUpdateRoute([]));
  }, [dispatch]);
  const headCells = [
    { id: "check", numeric: true, disablePadding: false, label: "" },
    { id: "ID", numeric: true, disablePadding: false, label: "ID Cliente" },
    { id: "name", numeric: false, disablePadding: false, label: "Nombre" },
    { id: "route", numeric: false, disablePadding: false, label: "Ruta" },
    {
      id: "Municipality",
      numeric: true,
      disablePadding: false,
      label: "Municipio",
    },
    { id: "cp", numeric: true, disablePadding: false, label: "Código Postal" },
    { id: "estado", numeric: false, disablePadding: false, label: "Estado" },
  ];

  const handleSearch = async (field_name) => {
    setSearchBarvalue(field_name);
    const filteredClients = !field_name
      ? clientsInfo
      : await clientsInfo.filter((cliente) =>
          cliente.name.toLowerCase().includes(field_name.toLowerCase())
        );

    setFilteredClients(filteredClients);
  };

  const classes = ClientStyles();

  const handleChangeClientStatus = async () => {
    if (currentClient) {
      const statusToChange = currentClient.status === 1 ? 0 : 1;
      const idClient = currentClient.id_client;

      await updateClientStatus(idClient, statusToChange)
        .then((res) => {
          setgetClientFlag(uuidv4());
          notification(
            `Este usuario fue ${
              isThisClientActive ? "desactivado" : "activado"
            }`,
            "success"
          );
          setOpenDeleteClient(false);
          setSearchBarvalue("");
        })
        .catch((err) => {
          errors(err, "No se pudo actualizar el estado del cliente", logout);
        });
    }
  };

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="h1" className={classes.clientsTitle}>
          Clientes
        </Typography>

        <Box
          className={classes.searchBarContainer}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <TextField
              name="searchbar"
              variant="outlined"
              placeholder="Buscar clientes"
              value={searchBarvalue}
              onChange={(e) => handleSearch(e.target.value.toLowerCase())}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="contrasena" edge="start">
                      <BsSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              className={
                parameterToSearch === "route"
                  ? classes.searchBarWithDropdown
                  : classes.searchBarInputfield
              }
            />
          </Box>

          {!massiveUpdate && (
            <Box>
              <Tooltip title="ver filtros" placement="bottom">
                <Button
                  className={classes.filterButton}
                  onClick={handleFilterClick}
                >
                  {filterActive ? (
                    <MdFilterListOff className={classes.icon} />
                  ) : (
                    <MdFilterList className={classes.icon} />
                  )}
                </Button>
              </Tooltip>

              <Button
                variant="contained"
                color="primary"
                className={classes.addClientButton}
                onClick={handleOpenCreateClient}
              >
                Nuevo Cliente
              </Button>
            </Box>
          )}

          {massiveUpdate && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  clientsMassiveUpdate && clientsMassiveUpdate.length <= 0
                }
                className={classes.chooseRouteButton}
                onClick={handleOpenChooseRoute}
              >
                Elegir ruta
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.cancelButton}
                onClick={() => setMassiveUpdate(false)}
              >
                Cancelar
              </Button>
            </Box>
          )}
        </Box>

        {filterActive && (
          <FilterComponent
            filters={filters}
            handleInputChange={handleInputChange}
          />
        )}

        <ClientsListDataTable
          clients={filteredClients}
          loading={loadingInfo}
          headCells={headCells}
          openViewClientInfo={handleOpenViewInfo}
          openEditUser={handleOpenEditUser}
          openDeleteClients={handleOpenDeleteClient}
          openPaymentInvoicesInfo={handleOpenUserInvoicesInformation}
          massiveUpdate={massiveUpdate}
        />
      </Box>

      {/* Modals */}
      <ModalComponent
        handleClose={handleCloseCreateClient}
        open={openCreateClient}
        body={
          <ViewClientInfo
            disabled={false}
            handleClose={handleCloseCreateClient}
            createNew={true}
            type="new"
            setFilteredClients={setFilteredClients}
            availableRoutes={availableRoutes}
            paymentFrecuency={paymentFrecuency}
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseViewInfo}
        open={openViewInfo}
        body={
          <ViewClientInfo
            disabled={true}
            handleClose={handleCloseViewInfo}
            type="view"
            availableRoutes={availableRoutes}
            priceList={priceList}
            paymentFrecuency={paymentFrecuency}
          />
        }
      />

      <ModalComponent
        handleClose={handleCloseEdituser}
        open={openEditUser}
        body={
          <ViewClientInfo disabled={false} handleClose={handleCloseEdituser} />
        }
      />

      <ModalComponent
        handleClose={handleCloseDeleteClient}
        open={openDeleteClient}
        body={
          <DialogBodyModal
            titleDialog="¿Estas Seguro?"
            subtitleDialog={`¿Estas seguro de ${
              isThisClientActive ? "desactivar" : "activar"
            } este usuario?`}
            handleCancel={handleCloseDeleteClient}
            handleConfirm={handleChangeClientStatus}
          />
        }
      />

      <ModalComponent
        handleClose={handleCloseChooseRoute}
        open={openChooseRouter}
        body={
          <ChooseRouteBodyModal
            handleClose={handleCloseChooseRoute}
            handleUpdateRoutes={handleUpdateRoutes}
            loading={loadUpdate}
            success={successLoad}
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseUserInvoicesInformation}
        open={openPaymentInvoicesInfo}
        body={
          <InvoicesPaymentsInformation
            handleClose={handleCloseUserInvoicesInformation}
            clientId={clientIdValue}
          />
        }
      />
      {/* Modals */}
    </DrawerMenu>
  );
};

export default Clients;
