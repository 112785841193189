import { makeStyles } from "@material-ui/core/styles";
import { FONT_CONSTANTS } from "../../../constants/styleConstants";
import { MONTSERRAT_FONTS } from "../../../constants/styleConstants";

const ViewClientInfoStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 450,
    maxHeight:600,
    minHeight:600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[1],
    paddingBottom: 30,
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
      borderRadius: 10,
    },
  },
  content: {
    paddingLeft: 50,
    paddingRight: 50,
  },
  titleModal: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: theme.palette.primary.deep_pine,
    fontSize: 24,
    marginTop: 35,
  },
  title: {
    fontSize: 14,
    color: theme.palette.primary.text_disable_medium,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    marginTop: 1,

    // fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    // color: theme.palette.primary.text_dark_md,
    // fontSize: 18,
    // marginTop: 30,
    // marginBottom: 10,
  },
  inputTitleDisabled: {
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    color: theme.palette.primary.text_dark_disabled,
    fontSize: 14,
    marginLeft: 5,
    marginTop: 10,
  },
  inputTitleEnabled: {
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    color: theme.palette.primary.text_dark_disabled,
    fontSize: 14,
    marginLeft: 5,
    marginTop: 10,
  },
  textFieldAll: {
    "& .MuiInputLabel-root": {
      fontSize: 13,
      color: theme.palette.primary.text_disable_medium,
      fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 3,
      height: 45, // Ajusta la altura del TextField
      marginBottom: 8,
      "&.MuiInputLabel-root": {
        fontSize: 10,
        color: theme.palette.primary.disable_text,
        fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
        top: '10%', // Alinea verticalmente el label al centro
        transform: 'translateY(-50%)', // Ajusta el label para que esté centrado
        left: 10, // Ajusta la alineación horizontal del label
      },
      "& fieldset": {
        borderColor: "#E3D4D4", // Cambia el color del borde aquí
      },
      "&:hover fieldset": {
        borderColor: "#E3D4D4", // Cambia el color del borde al hacer hover aquí
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E3D4D4", // Cambia el color del borde cuando el campo está enfocado
      },
    },

    // width: "100%",
  },
  textFieldLarge: {
    width: "100%",
  },
  textFieldMultiline: {
    width: 300,
  },
  textFieldMedium: {
    maxWidth: 270,
  },
  textFieldSmall: {
    maxWidth: 100,
  },
  input: {
    textAlign: "left",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: "12px !important",
  },
  inputMultiline: {
    textAlign: "left",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: "12px !important",
  },
  addressInformation: {
    marginTop: 10,
  },
  googleMapsContainer: {
    height: 200,
    width: 600,
    marginTop: 20,
  },
  googleMapsElement: {
    height: "100%",
    borderRadius: 20,
  },
  closeIconContainer: {
    width: "100%",
  },
  closeIcon: {
    float: "right",
    marginTop: -55,
    marginLeft: 340,
    position:"absolute;"
  },
  saveButton: {
    // height: (props) => (props.createNew ? 50 : 45),
    // width: (props) => (props.createNew ? 180 : 150),
    // borderRadius: 10,
    // marginTop: 30,

    width: '323px',
    height: '40px',
    borderRadius: '2px 0px 0px 0px',
    background:theme.palette.primary.main,
    color:"#fff",
    fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize:14,
    marginTop:15,
    "&:hover": {
    background: theme.palette.primary.dark, // Cambia el color de fondo cuando se hace hover
    color: "#fff", // Cambia el color del texto cuando se hace hover
  }

    
  },
  savingData: {
    color: theme.palette.primary.dark,
  },
  googleMapNotAssigned: {
    height: 200,
    width: 600,
    marginTop: 20,
    background: theme.palette.primary.gray_40,
    borderRadius: 10,
    color: theme.palette.primary.gray_30,
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    width: 150,
    height: 45,
    borderRadius: 10,
    marginTop: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  updateInfoBtn: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 14,
  },
// New
  helperText: {
    marginLeft: 0, // Alinea el helperText a la izquierda
    marginTop: "-5px", // Reduce el margen superior
  },
  formControl: {
    "& .MuiInputLabel-root": {
      fontSize: 12,  // Tamaño de la fuente del label
      color: theme.palette.primary.disable_text, // Color del label
      fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR, // Familia de la fuente del label
      // top: '60%', // Alinea verticalmente el label al centro
      transform: 'translateY(-50%)', // Ajusta el label para que esté centrado
      left: 10, // Ajusta la alineación horizontal del label
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 3, // Radio del borde del Select
      height: 40, // Altura del Select
      "& fieldset": {
        borderColor: "#E3D4D4", // Color del borde
      },
      "&:hover fieldset": {
        borderColor: "#E3D4D4", // Color del borde al hacer hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E3D4D4", // Color del borde cuando está enfocado
      },
    },
    "& .MuiSelect-root": {
      fontSize: 14, // Tamaño de la fuente del Select
      color: theme.palette.text.primary, // Color del texto dentro del Select
      fontFamily: "Montserrat, sans-serif", // Familia de la fuente del Select
    },
  },  
  botonContent:{
    display: "flex",
    justifyContent:"center",
    alignItems:"center"
  },

}));

export default ViewClientInfoStyles;
