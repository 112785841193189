import { Box, Typography, Grid } from "@material-ui/core";
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getClientInvoicesByIdClient } from "../../api/apiClients";
import { selectAccessToken, setAccessToken } from "../../store/slices/clients";
import ClientDetailsInformationStyles from "./ClientsDetailsInformation.styles";
import InvoicesDetailInformation from "./InvoicesDetailInformation";
import { GrClose } from "react-icons/gr";
import { errors } from "../../utils/ErrorAxios";
import useLogout from "../../hooks/useLogout";

const ClientDetailsInformation = (props) => {
  const classes = ClientDetailsInformationStyles();
  const { idClient, handleClose } = props;
  const accessToken = useSelector(selectAccessToken);
  const [availableInvoices, setAvailableInvoices] = useState([]);
  const [userInformation, setUserInformation] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [logout] = useLogout();

 
  useLayoutEffect(() => {
    getClientInvoicesByIdClient(idClient, accessToken)
      .then((res) => {
        let invoices = res.data.facturas;
        setAvailableInvoices(invoices);
        setUserInformation(res.data.usuario[0]);
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, dispatch, history, idClient]);

  return (
    <Box className={classes.mainContainer}>
      <GrClose
        color="primary"
        className={classes.closeIcon}
        onClick={handleClose}
      />
      {availableInvoices && availableInvoices.length > 0 && (
        <>
          <Typography variant="h3" className={classes.headerTitleSection}>
            Informacion del cliente
          </Typography>
          <Grid container className={classes.clientInformationContainer}>
            <Grid item xs={4}>
              <Box>
                <Typography
                  variant="subtitle1"
                  className={classes.headerSection}
                >
                  Nombre
                </Typography>
                <Typography variant="h3" className={classes.clientName}>
                  {userInformation.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  variant="subtitle1"
                  className={classes.headerSection}
                >
                  Estado
                </Typography>
                <Typography variant="h3" className={classes.clientName}>
                  {userInformation.state}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  variant="subtitle1"
                  className={classes.headerSection}
                >
                  Ciudad
                </Typography>
                <Typography variant="h3" className={classes.clientName}>
                  {userInformation.city}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h3" className={classes.headerTitleSection}>
                  Listado de facturas
                </Typography>
              </Box>
            </Grid>

            <InvoicesDetailInformation invoicesList={availableInvoices} />
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ClientDetailsInformation;
