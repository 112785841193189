import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import InvoicesPaymentsStyles from "./InvoicesPayments.styles";
import { GrClose } from "react-icons/gr";
import { useSelector } from "react-redux";
import { selectAvailableUsers } from "../../../store/slices/user";
import { formatDateToUTC } from "../../../utils/formatUtils";
import { Skeleton } from "@material-ui/lab";

const InvoicesPayments = (props) => {
  const { handleClose, idPayment, payments, loaderInvoiceInfo } = props;
  const classes = InvoicesPaymentsStyles();

  const availableUsers = useSelector(selectAvailableUsers);

  const getUserName = (idUser) => {
    const user = availableUsers.filter(
      (user) => parseInt(user.id_user) === parseInt(idUser)
    );
    if (user.length === 0) {
      return "Sin usuario";
    } else {
      return user[0].name;
    }
  };

  return (
    <Box className={classes.main}>
      <GrClose
        color="primary"
        className={classes.closeIcon}
        onClick={handleClose}
      />
      <Box className={classes.data}>
        <Typography className={classes.titleTable}>
          Listado de abonos
        </Typography>
        <Typography className={classes.idInvoice}>ID Factura</Typography>
        <Typography className={classes.invoice}>{idPayment}</Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>ID ABONO</TableCell>
              <TableCell className={classes.tableHead}>FECHA</TableCell>
              <TableCell className={classes.tableHead}>CANTIDAD</TableCell>
              <TableCell className={classes.tableHead}>EMISOR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loaderInvoiceInfo ? (
              Array.from(new Array(2)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))
            ) : payments && payments.length > 0 ? (
              payments.map((pay) => (
                <TableRow key={pay.id_payment}>
                  <TableCell className={classes.tableBody}>
                    {pay.id_payment}
                  </TableCell>
                  <TableCell className={classes.tableBody}>
                    {formatDateToUTC(pay.created_at)}
                  </TableCell>
                  <TableCell className={classes.tableBody}>
                    ${pay.total_payment}
                  </TableCell>
                  <TableCell className={classes.tableBody}>
                    {getUserName(pay.id_user)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>Sin abonos</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InvoicesPayments;
