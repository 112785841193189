import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accesToken: "",
  clients: [],
  clientListToPrint: [],
  clientListToPrintDetails: [],
  clientInformation: {
    idClient: "",
    name: "",
    idRoute: "",
    street: "",
    externalNumber: "",
    internalNumber: "",
    neighborhood: "",
    city: "",
    state: "",
    zipCode: "",
    personalPhoneNumber: "",
    homePhoneNumber: "",
    email: "",
    idPriceList: "",
    status: "",
    payDays: "",
    latitude: "",
    longitude: "",
    comments: "",
    paymentFrecuencyId: "",
    preferenceDay: ""
  },
  auditInformation: [],
  auditRouteSelected: "",
  clientsToUpdateRoute: [],
};

export const clientsInformationReducer = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients(state, { payload }) {
      state.clients = payload;
    },
    setClientInformation(state, { payload }) {
      state.clientInformation = payload;
    },
    addClientRemaining: (state, { payload }) => {
      const itemIndex = state.clientListToPrint.findIndex(
        (client) =>
          parseInt(client.id_invoice) === parseInt(payload.id_invoice)
      );
      if (itemIndex < 0) {
        state.clientListToPrint = [...state.clientListToPrint, payload];
      }
    },
    removeClientRemaining: (state, { payload }) => {
      //We are sending the id_client on the payload
      state.clientListToPrint = state.clientListToPrint.filter(
        (b) => b.id_client !== payload
      );
    },
    setClientListToPrintDetails: (state, { payload }) => {
      state.clientListToPrintDetails = payload;
    },
    setAuditInformation: (state, { payload }) => {
      state.auditInformation = payload;
    },
    setAuditRouteSelected: (state, { payload }) => {
      state.auditRouteSelected = payload;
    },
    setClientsToUpdateRoute: (state, { payload }) => {
      state.clientsToUpdateRoute = payload;
    },
    setAccessToken: (state, { payload }) => {
      state.accesToken = payload;
    },
    resetAuditInformation: (state) => {
      state.auditInformation = [];
      state.auditRouteSelected = "";
    },
    resetClientListToPrint: (state) => {
      state.clientListToPrint = [];
    },
  },
});

export const {
  setClients,
  setClientInformation,
  addClientRemaining,
  removeClientRemaining,
  resetClientListToPrint,
  setClientListToPrintDetails,
  setAuditInformation,
  setAuditRouteSelected,
  setAccessToken,
  resetAuditInformation,
  setClientsToUpdateRoute,
} = clientsInformationReducer.actions;

export const selectClients = (state) =>
  state.clients ? state.clients.clients : null;
export const selectClientInformation = (state) =>
  state.clients ? state.clients.clientInformation : null;
export const selectClientsRemaining = (state) =>
  state.clients ? state.clients.clientListToPrint : null;
export const selectClientListToPrintDetails = (state) =>
  state.clients ? state.clients.clientListToPrintDetails : null;
export const selectAuditInformation = (state) =>
  state.clients ? state.clients.auditInformation : null;
export const selectAuditRouteSelected = (state) =>
  state.clients ? state.clients.auditRouteSelected : null;
export const selectClientsToUpdateRoute = (state) =>
  state.clients ? state.clients.clientsToUpdateRoute : null;
export const selectAccessToken = (state) =>
  state.clients ? state.clients.accesToken : null;

export default clientsInformationReducer.reducer;
