import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../constants/styleConstants";

const AdministratorStyles = makeStyles((theme) => ({
  mainContainer: {
   padding:30,
   background: "#E8E8E8",
   width: "100vw",
   height:"100%"
  },
  title:{
    fontSize: 32,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: theme.palette.primary.deep_pine,
    marginTop:16,
    marginLeft:15
  },
  lastUpdate:{
    fontSize:12,
    fontFamily:MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    color: theme.palette.primary.text_disable_medium,
    marginLeft:17,
    paddingTop:10
  },
  cardsContainer:{
    display: "flex",
    justifyContent:"space-around",
    alignItems:"center",
    marginTop:20,
    maxWidth: 920
  },
  chartContainer:{
    display: 'flex;'
  },
  pieContainer:{
    maxWidth:455, 
    minWidth:455,
    height: 270, 
    background: "#fff",
    borderRadius:5,
    display: 'flex;',
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    paddingRight:70
  },
  titlePie:{
    fontSize:16,
    color:theme.palette.primary.full_disable,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    paddingTop:15,
    paddingRight:100
  },
  barContainer:{
    maxWidth:400, 
    height: 270, 
    background: "#fff",
    borderRadius:5,
    display: 'flex',
    justifyContent:"center",
    alignItems:"center",
    marginRight: 10 // Añadir separación si es necesario
  },
  chartsContainer:{
    display: "flex",
    justifyContent:"space-between",
    alignItems:"center",
    maxWidth: 900,
    marginTop:26,
    marginLeft:18
  },
  title2:{
    fontSize: 20,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    color: theme.palette.primary.text_disable_medium,
    marginTop:16,
    marginLeft:15
  },
 
}));

export default AdministratorStyles;
