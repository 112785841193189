import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_NAVICK_API_URL } = process.env;

export const getRoutes = () => {
  const request = {
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/routes/routes_catalog`,
    headers: {
      "content-type": "application/json",
      token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const getPriceList = () => {
  const request = {
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/price_list`,
    headers: {
      "content-type": "application/json",
      token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const insertClients = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_NAVICK_API_URL}/clients/insert_massive`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      token: getCookie("accessToken"),
    },
  };

  return axios(request);
};
