import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PriceCard from "../../../components/sharedComponents/PricesCard";
import { selectClients } from "../../../store/slices/clients";
import {
  addAmount,
  addProductsQuantity,
  addProductToCart,
  removeAmount,
  removeProductFromCart,
  removeProductsQuantity,
  resetProductsInformation,
  selectClientForInvoice,
  selectProductsSelected,
  selectTotalProductAmount,
  setSelectedClient,

} from "../../../store/slices/products";
import CreateInvoicesStyles from "./CreateInvoice.styles";
import productImage from "../../../assets/images/ProductImage.png";
import CenteredContent from "../../../components/sharedComponents/CenteredContent";
import { formatNumber } from "../../../utils/formatUtils";
import { priceList } from "./data";
import {useDropzone} from 'react-dropzone'
import { BiCloudUpload } from "react-icons/bi";
import { compressImage, convertToBase64 } from "../../../utils/imageUtils";
import { notification } from "../../../utils/notifications";
const Createinvoice = (props) => {
  const {
    setSelectedClientS,
    paymentTypeS,
    setPaymentTypeS,
    setImageBase64,
    setComments
  } = props;
  const classes = CreateInvoicesStyles();
  const clients = useSelector(selectClients);
  const selectedUser = useSelector(selectClientForInvoice);
  const [selectedProducts, setselectedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const totalAmount = useSelector(selectTotalProductAmount);
  const dispatch = useDispatch();
  const carProducts = useSelector(selectProductsSelected);
  const typePaymentOptions = [
    { idPaymentType: 1, description: "Contado" },
    { idPaymentType: 2, description: "Crédito" },
  ];
  const mapingProducts = (user) => {
    let prodArray = priceList.filter(
      (list) => list.idPriceList === user.id_price_list
    );
    let products = prodArray.map((item) => ({
      ...item,
      quantity: 1,
    }));
    return products;
  };

  const handleSelectedUser = async (user) => {
    if (user) {
      dispatch(resetProductsInformation());
      dispatch(setSelectedClient(user));
      setSelectedClientS(user)
      let prods = mapingProducts(user);
      setAvailableProducts(prods);
    }
  };

  const handleSelectProducts = (product) => {
    dispatch(addProductToCart(product));
    dispatch(addAmount(product.price));
    setselectedProducts([...selectedProducts, product]);
  };

  const handleAddProductQuantity = async (productDetail) => {
    dispatch(addAmount(productDetail.price));
    dispatch(addProductsQuantity(productDetail.idArticle));
  };
  const handleRemoveProductQuantity = async (productDetail) => {
    if (totalAmount > 0) {
      dispatch(removeAmount(productDetail.price));
    }
    dispatch(removeProductsQuantity(productDetail.idArticle));
  };

  const handleRemoveProduct = (productDetail) => {
    if (totalAmount > 0) {
      let amountToRemove = productDetail.quantity * productDetail.price;
      dispatch(removeAmount(amountToRemove));
    }
    dispatch(removeProductFromCart(productDetail.idArticle));
  };
  

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const image = new Image();
      
      image.src = URL.createObjectURL(file);
      image.onload = async () => {
        try {
          const compressedBlob = await compressImage(image);
          const base64String = await convertToBase64(compressedBlob);
          setImageBase64(base64String)
        } catch (error) {
          notification('error al subir la imagen', error)
        }
      };
    }
  }, [setImageBase64]);
 
  const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone({onDrop,
    accept: {
      'image/png': ['.png', '.jpg'],
    }
  })

  // const handleChangePrice = (id) =>{
  //   const itemIndex = carProducts.clientListToPrint.findIndex(
  //     (prod) => prod.id_client === payload.id_client
  //   );
  //   if (itemIndex < 0) {
  //     state.clientListToPrint = [...state.clientListToPrint, payload];
  //   }
  // }

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={4}>
        <Typography variant="h5">Elige el cliente</Typography>
        <Autocomplete
          id="client-name"
          disableClearable
          defaultChecked={selectedUser.name}
          options={clients}
          getOptionLabel={(client) => client.name}
          className={classes.clientNameInput}
          onChange={(event, value) => handleSelectedUser(value)}
          renderInput={(params) => (
            <TextField {...params} label="Cliente" variant="outlined" />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography variant="h5">Selecciona los productos</Typography>
        <Autocomplete
          id="client-products"
          disableClearable
          options={availableProducts}
          getOptionLabel={(product) => product.idArticle}
          className={classes.clientNameInput}
          onChange={(event, value) => handleSelectProducts(value)}
          renderInput={(params) => (
            <TextField {...params} label="Productos" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={4}></Grid>
      
      <Grid item xs={4} >
        <FormControl className={classes.formControl}>
          <Typography variant="h5" className={classes.paymentTypeText}>
            Tipo de pago
          </Typography>
          <Select
            variant="outlined"
            labelId="type-payments"
            value={paymentTypeS}
            onChange={(e) => setPaymentTypeS(e.target.value)}
          >
            {typePaymentOptions.map((option) => (
              <MenuItem value={option.idPaymentType}>
                {option.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl  className={classes.formControl}>
          <Typography variant="h5" className={classes.paymentTypeText}>
            Agregar identificación
          </Typography>
            <Box className={classes.containerDropMain}>
              <Box {...getRootProps()}
                className={classes.containerDrop}
                >
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>Suelte los archivos aquí...</p> :
                    <p className={classes.textDrop} >Subir identificación <BiCloudUpload className={classes.iconDrop}  /></p>
                }
              </Box>   
              
              {acceptedFiles[0] && (
                <img src={URL.createObjectURL(acceptedFiles[0])} className={classes.imageFile} alt="img"/>
              )}
            </Box>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.paymentTypeText}>
            Comentarios
        </Typography>
        <TextField
          variant="outlined"
          id="outlined-multiline-flexible"
          label="opcional"
          multiline
          maxRows={4}
          onChange={(e) => setComments(e.target.value)}
        />
      </Grid> 
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.chooseProductsTitle}>
          Detalle de productos
        </Typography>
      </Grid>
     
      {carProducts && carProducts.length > 0 ? (
        carProducts.map((product) => (
          <Grid item xs={12} key={product.idArticle}>
            <PriceCard
              articleName={product.idArticle}
              price={product.price}
              quantityProducts={product.quantity}
              handleAddQuantity={handleAddProductQuantity}
              handleRemoveQuantity={handleRemoveProductQuantity}
              handleRemoveProduct={handleRemoveProduct}
              productDetail={product}
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <CenteredContent direction="column">
            <Typography
              variant="h2"
              align="center"
              className={classes.productsDetail}
            >
              Elija sus productos
            </Typography>
            <img
              src={productImage}
              alt="prd-img"
              className={classes.imageDisabled}
            />
          </CenteredContent>
        </Grid>
      )}

      {carProducts.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.totalInvoiceAmountText}>
            Total de la factura
          </Typography>
          <Typography
            variant="h2"
            className={classes.totalInvoiceAmountTNumber}
          >
            ${totalAmount && formatNumber(totalAmount)} MXN
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Createinvoice;
