import React, { useState } from "react";
import ChangePasswordModalStyles from "./ChangePassword.styles";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";
import { CiCircleCheck } from "react-icons/ci";
import { COLOR_CONSTANTS } from "../../../constants/styleConstants";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/slices/user";
import { changeUserPassword } from "../../../api/apiUsers";
import { notification } from "../../../utils/notifications";
import { errors } from "../../../utils/ErrorAxios";
import useLogout from "../../../hooks/useLogout";
const ChangePassword = (props) => {
  const { closeModal } = props;
  const userInformation = useSelector(selectCurrentUser);
  const classes = ChangePasswordModalStyles();
  const userId = userInformation.id_user;
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [logout] = useLogout();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [showPasswordErrorText, setShowPasswordErrorText] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const correctPasswordCheck = password.length >= 8;

  const handlePasswordValue = (e) => {
    const passValue = e.target.value;
    setPassword(passValue);
    if (passValue.length < 8) {
      setShowPasswordErrorText(true);
    } else {
      setShowPasswordErrorText(false);
    }
    if (passValue.length === 0) setShowPasswordErrorText(false);
  };

  const handleChangePasswordUpdate = () => {
    setLoading(true);
    changeUserPassword(userId, password)
      .then((res) => {
        if (res.status === 204) {
          notification("contraseña actualizada", "success");
          closeModal();
        }
        closeModal();
        setLoading(false);
      })
      .catch(({ err }) => {
        closeModal();
        errors(err, "No se pudo obtener la información", logout);
      });
  };

  return (
    <Box className={classes.changePasswordContainer}>
      <Box className={classes.closeIconContainerMain}>
        <IconButton onClick={closeModal}>
          <Close />
        </IconButton>
      </Box>
      <Typography className={classes.changePasswordTitle}>
        Cambio de contraseña
      </Typography>
      <Typography className={classes.cpSubheader}>
        Asegurate de que la contraseña tenga al menos 8 caracteres
      </Typography>

      <TextField
        variant="outlined"
        name="password"
        type={showPassword ? "text" : "password"}
        onChange={(e) => handlePasswordValue(e)}
        value={password}
        inputProps={{ className: classes.input }}
        className={classes.passwordInput}
        label="Contraseña"
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={showPasswordErrorText}
        helperText={
          showPasswordErrorText && (
            <Typography className={classes.helperTextError}>
              {" "}
              La contraseña no cumple con los requisitos
            </Typography>
          )
        }
      />
      <Typography
        className={`${classes.correctPassword} ${
          correctPasswordCheck ? classes.correctPasswordVisible : ""
        }`}
      >
        <CiCircleCheck
          size={20}
          color={COLOR_CONSTANTS.SECONDARY_GREEN}
          className={classes.checkIconPassword}
        />
        ¡Contraseña segura!
      </Typography>

      <Button
        variant="contained"
        color="primary"
        className={`${classes.changePasswordBtn} ${
          correctPasswordCheck ? classes.passwordButtonMargin : ""
        }`}
        disabled={!correctPasswordCheck || loading} // Deshabilitar mientras carga
        onClick={handleChangePasswordUpdate}
      >
        {loading ? ( // Mostrar loader cuando está cargando
          <CircularProgress size={24} className={classes.buttonProgress} />
        ) : (
          "Cambiar Contraseña"
        )}
      </Button>
    </Box>
  );
};

export default ChangePassword;
