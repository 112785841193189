import { makeStyles } from "@material-ui/core/styles";
import {MONTSERRAT_FONTS} from '../../../constants/styleConstants'

const CancelInvoiceByIdModalStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.primary.white,
    width: 300,
    height: 530,
    borderRadius: 10,
    padding: 15,
  },
  closeIcon: {
    display: "block;  ",
    float: "right",
    fontSize: 25,
    cursor: "pointer",
    marginTop:-20,
  },
  cancelInvoiceTitle: {
    marginTop: 20,
    color: theme.palette.primary.text_dark,
    fontSize:24, 
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    paddingLeft:27,
    paddingTop:5
  },
  idInvoiceHeader: {
    color: theme.palette.primary.text_disable_medium,
    fontSize:12, 
    fontFamily:MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    paddingLeft:27,
    marginTop:3
  },
  actualInvoice:{
    fontSize:15,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    color: theme.palette.primary.text_disable_medium,

  },
  invoiceActual:{
    fontSize: 24, 
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    marginTop:5
  },
  simpleText:{
    color: theme.palette.primary.text_disable_medium,
    fontSize:15, 
    fontFamily:MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    marginTop:15

  },
  formContainer: {
    marginTop: 30,
  },
  amountField: {
    marginTop: 10,
    width: 200,
  },
  cancelButtonContainer: {
    marginTop: 20,
  },
  cancelButton: {
    width: 200,
    height: 45,
    borderRadius: 10,
  },
  loadinHeader:{
    marginBottom:10,
    color:theme.palette.primary.dark
  },
  idBox:{
    marginLeft:10
  }
}));

export default CancelInvoiceByIdModalStyles;
