import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../constants/styleConstants";
const ClientInvoiceStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    paddingLeft:40,
    overflowX:"hidden" ,
    overflowY:"auto" 
  },
  headerTitleSection: {
    marginTop: 50,
    fontSize: 32,
    color: theme.palette.primary.deep_pine,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
  },
  containetUserName:{
    marginTop:40,
  },
  simpleText: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    color: theme.palette.primary.disable_text
  },
  clientName: {
    color: theme.palette.primary.text_dark_disabled,
    fontSize: 32,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD
  },
  containerInvoicesData: {
    width: 556,
    height: 133,
    background: "#fff",
    borderRadius:20,
    marginTop: 40,
    display: "flex;",
    justifyContent:"space-around;",
    alignItems:"center"
  },
  boxInvoiceNumber :{
    display: "flex;",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center",
    gap: "20px"
  },
  textInvoiceData:{
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    color: theme.palette.primary.gray_50,
    fontSize:16,

  },
  numberInvoiceData:{
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 48
  },
  boxInvoiceCommun:{
    display: "flex;",
    justifyContent:"center",
    flexDirection:"column"
  },
  communText:{
    fontSize:12,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    color: theme.palette.primary.gray_50,
  },
  communNumber:{
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize:14,
    color: theme.palette.primary.deep_pine
  },
  containerInvoiceCardStatus:{
    display:"flex;", 
    gap: 55,
    marginTop: 30,
    maxWidth: 920,
  },
  breadcrumbs:{
    marginTop: 50
  },
  // Table
  tableComponent:{
    maxWidth: 1065,
    marginTop:20,
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
    marginBottom: 100
  },
  table: {
    maxWidth: 1065,
  },
  tableHead:{
    textAlign:"center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    color: theme.palette.primary.gray_30,
    textTransform: "uppercase;",
    borderBottom: `1px solid ${theme.palette.primary.divider}`,
  },

  tableBody:{
    textAlign:"center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.primary.divider}`,
    borderRadius: 20,
  },
  status: {
    height: 30,
    width: 73,
    borderRadius: '5px',
    fontSize:8,
    display: 'flex',
    justifyContent:"center",
    alignItems:"center",
    marginLeft:23
  },
  paid: {
    backgroundColor: theme.palette.primary.main_green_b,
    border: `1px solid ${theme.palette.primary.main_green}`,
    color: theme.palette.primary.main_green,
    textAlign: "center"
  },
  pending: {
    backgroundColor: theme.palette.primary.honey_gold_b,
    border: `1px solid ${theme.palette.primary.honey_gold}`,
    color: theme.palette.primary.honey_gold
  },
  cancelled: {
    backgroundColor: theme.palette.primary.main_red_b,
    border: `1px solid ${theme.palette.primary.main_red}`,
    color: theme.palette.primary.main_red
  },
  skeleton: {
    width: '98%',
    height: '30px',
    marginBottom: theme.spacing(1),
  },
  iconButton:{
    borderRadius: '50%',
    minWidth: '40px',
    minHeight: '40px',
    padding: '8px',
    boxShadow: '-1px 3px 6px rgba(0, 0, 0, 0.1)',
    margin: '0px 8px',
    "&:disabled": {
      opacity: 0.3
    },
  }
 
}));

export default ClientInvoiceStyles;
