import DrawerMenu from "../../components/DrawerMenu/Index";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import AddClientsStyles from "./AddClients.styles";
import TableAddClients from "../../components/addClients/TableAddClients";
import UploadClients from "../../components/addClients/UploadClients";
import AddClientsFilters from "../../components/addClients/AddClientsFilters";
import { Fragment, useState } from "react";
import { weeklyDays } from "../../constants/general";
import { insertClients } from "../../api/apiAddClients";
import { notification } from "../../utils/notifications";
import { errors } from "../../utils/ErrorAxios";
import useLogout from "../../hooks/useLogout";

const AddClients = () => {
  const classes = AddClientsStyles();
  const [logout] = useLogout();

  const [loader, setLoader] = useState(false);
  const [loaderUploadInfo, setLoaderUploadInfo] = useState(false);
  const [showTable, setshowTable] = useState(false);
  const [clients, setClients] = useState([]);
  const [state, setState] = useState({
    woorksheets: [],
    rows: [],
    headers: [],
    status: false,
  });
  const [priceValue, setPriceValue] = useState("");
  const [routeValue, setRouteValue] = useState("");
  const [paymentValue, setPaymentValue] = useState("");
  const [paymentDayValue, setPaymentDayValue] = useState("");
  const [errorUploadInfo, setErrorUploadInfo] = useState(false);
  const [paymentsFrequency, setPaymentsFrequency] = useState([]);

  const handleClick = () => {
    setLoaderUploadInfo(true);

    let clientsFormat = [];

    clients.forEach((client) => {
      let getDayId = 0;
      let getPaymentFrequencyId = 0;

      weeklyDays.forEach((day) => {
        if (client.payDays === day.label) getDayId = day.value;
      });

      paymentsFrequency.forEach((value) => {
        if (client.paymentFrequency === value.frequency_option) {
          getPaymentFrequencyId = value.payment_frequency_id;
        }
      });

      clientsFormat.push({
        ...client,
        paymentFrequency:
          getPaymentFrequencyId === 0 ? paymentValue : getPaymentFrequencyId,
        payDays:
          getDayId === 0
            ? paymentDayValue === ""
              ? 0
              : paymentDayValue
            : getDayId,
      });
    });

    let payload = {
      clients: clientsFormat,
      idRoute: routeValue,
      idPriceList: priceValue,
    };

    insertClients(payload)
      .then((res) => {
        if (res.status === 201) {
          notification(res.data.statusMessage, "success");
          setState({
            woorksheets: [],
            rows: [],
            headers: [],
            status: false,
          });
          setClients([]);
          setshowTable(false);
          setPriceValue("");
          setRouteValue("");
          setPaymentValue("");
          setPaymentDayValue("");
          setErrorUploadInfo(false);
          setPaymentsFrequency([]);
        } else {
          notification(res.data.statusMessage);
        }
      })
      .catch((err) => {
        errors(err, "No se pudieron crear los clientes", logout);
      })
      .finally(() => setLoaderUploadInfo(false));
  };

  const validInformation = () => {
    let flag = true;

    flag = !errorUploadInfo;

    if (clients.length === 0) flag = false;

    if (priceValue === "") flag = false;
    if (routeValue === "") flag = false;
    if (paymentValue === "") flag = false;

    if (paymentValue === "1") {
      if (paymentDayValue === "") flag = false;
    }

    if (loaderUploadInfo) flag = false;

    return flag;
  };

  const showButton = validInformation();

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography className={classes.title}>
          Agregar clientes por excel
        </Typography>
        <Box className={classes.contentFilters}>
          <AddClientsFilters
            priceValue={priceValue}
            setPriceValue={setPriceValue}
            routeValue={routeValue}
            setRouteValue={setRouteValue}
            paymentValue={paymentValue}
            setPaymentValue={setPaymentValue}
            paymentDayValue={paymentDayValue}
            setPaymentDayValue={setPaymentDayValue}
            paymentsFrequency={paymentsFrequency}
            setPaymentsFrequency={setPaymentsFrequency}
          />
          <UploadClients
            setLoader={setLoader}
            setState={setState}
            state={state}
            setshowTable={setshowTable}
            setClients={setClients}
            setErrorUploadInfo={setErrorUploadInfo}
          />
        </Box>
        <TableAddClients loader={loader} state={state} showTable={showTable} />
        <Box className={classes.contentButton}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={!showButton}
            onClick={handleClick}
          >
            {loaderUploadInfo ? (
              <Fragment>
                Cargando..
                <CircularProgress size={20} style={{ marginLeft: 20 }} />
              </Fragment>
            ) : (
              "Agregar clientes"
            )}
          </Button>
        </Box>
      </Box>
    </DrawerMenu>
  );
};

export default AddClients;
