import axios from "axios";
// import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_NAVICK_API_URL } = process.env;

// const accessToken = getCookie("accessToken");

export const getInfoHome = (accessToken) => {

    const request = {
      host: `${REACT_APP_NAVICK_API_URL}`,
      method: "GET",
      url: `${REACT_APP_NAVICK_API_URL}/home/get_information_home`,
      headers: {
        "content-type": "application/json",
        token: accessToken,
        'Cache-Control': 'no-cache',
      },
    };
    
  
    return axios(request);
  };