import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { Skeleton } from '@mui/material';
import { Box } from '@material-ui/core';

const PieActiveArc = ({ loading, totalInvoices }) => {
    
    const data = [
        { id: 0, value: totalInvoices.pending , color: '#F0CD6E', label: 'Pendientes' },
        { id: 1, value: totalInvoices.paid, color: '#159E60', label: 'Pagadas' },
        { id: 2, value: totalInvoices.canceled, color: '#F97E6D', label: 'Canceladas' },
    ];

    return loading ? (
      
      <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
        <Skeleton variant="circular" width={200} height={200} />
        <Box style={{marginLeft:20}}>
          <Skeleton variant="text" width={120} height={20} />
          <Skeleton variant="text" width={120} height={20} />
          <Skeleton variant="text" width={120} height={20} />
        </Box>
      </Box>
   
      
    ) : (
        <PieChart
            series={[
                {
                    data,
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 3,
                    cornerRadius: 6,
                    startAngle: -90,
                    
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                },
            ]}
            height={200}
        />
    );
};

export default PieActiveArc;
