import {
  Box,
  Button,
  CircularProgress, // Importa el componente CircularProgress para el loader
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DrawerMenu from "../../components/DrawerMenu/Index";
import DailyCollectionsStyles from "./DailyCollections.styles";
import { selectAvailableRoutes } from "../../store/slices/routes";
import { CreateTablePDFDailyInstallments } from "../../utils/generalUtils";
import { getDailyCollectionsByRouteAndDate } from "../../api/apiClients";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import {
  resetAuditInformation,
  selectAccessToken,
  selectAuditInformation,
  selectAuditRouteSelected,
  setAccessToken,
  setAuditInformation,
  setAuditRouteSelected,
} from "../../store/slices/clients";
import { ERROR_MESSAGES } from "../../constants/constantMessages";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { setIsAuth } from "../../store/slices/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Skeleton } from "@mui/material";

const DailyCollections = () => {
  const classes = DailyCollectionsStyles();
  const availableRoutes = useSelector(selectAvailableRoutes);
  const auditInformation = useSelector(selectAuditInformation);
  const selectedAuditRoute = useSelector(selectAuditRouteSelected);
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedRoute, setSelectedRoute] = useState();
  const [loading, setLoading] = useState(false);

  const handleSelectedRoute = (route) => {
    setSelectedRoute(route);
    
    dispatch(setAuditRouteSelected(route.id_route));
  };

  const fetchDailyCollections = () => {
    setLoading(true);
    getDailyCollectionsByRouteAndDate(selectedAuditRoute || 0, selectedDate, accessToken)
      .then((res) => {
        dispatch(setAuditInformation(res.data.body));
      })
      .catch(({ response }) => {
        if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(resetAuditInformation());
  }, [dispatch]);

  const formattedDate = format(selectedDate, "EEEE, d 'de' MMMM 'del' yyyy", {
    locale: es,
  });

  const getPaymentFrequency = (id) => {
    switch (id) {
      case 1:
        return "Semanal";
      case 2:
        return "Quincenal";
      case 3:
        return "Mensual";
      default:
        return "Desconocido";
    }
  };

  const getDayOfWeek = (day) => {
    switch (day) {
      case 1:
        return "Lunes";
      case 2:
        return "Martes";
      case 3:
        return "Miércoles";
      case 4:
        return "Jueves";
      case 5:
        return "Viernes";
      case 6:
        return "Sábado";
      case 7:
        return "Domingo";
      default:
        return "Desconocido";
    }
  };

  const renderSkeleton = (rows) => (
    Array.from({ length: rows }).map((_, index) => (
      <TableRow key={index}>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
      </TableRow>
    ))
  );


  return (
    <DrawerMenu>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <Box className={classes.mainContainer}>
          <Typography variant="h1" className={classes.auditTitle}>
            Cobranza diaria
          </Typography>

          <Typography variant="h6" className={classes.subtitle}>
            Selecciona la ruta y la fecha para el reporte de la cobranza
          </Typography>

          {/* Filtros y botón de buscar en la misma fila */}
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
            <Box>
              <FormControl style={{ minWidth: 150, marginRight: 12 }}>
                <Select
                  variant="outlined"
                  value={selectedRoute || ""}
                  /* value={selectedAuditRoute || ""} */
                  onChange={(e) => handleSelectedRoute(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">Todas</MenuItem>
                  {availableRoutes.length > 0 ? (
                    availableRoutes.map((route) => (
                      <MenuItem key={route.id_route} value={route}>
                        {route.description}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="0">No hay rutas disponibles</MenuItem>
                  )}
                </Select>
              </FormControl>

              <DatePicker
                value={selectedDate}
                onChange={(newDate) => setSelectedDate(newDate)}
                renderInput={(params) => <input {...params} />}
                minDate={new Date()}  // Configura el día de hoy como la fecha mínima
                disablePast  // Deshabilita días pasados
              />


            </Box>

            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={fetchDailyCollections}
                disabled={loading}
                style={{ marginRight: 12 }}
              >
                {loading ? (
                  <>
                    Buscando... <CircularProgress size={20} style={{ marginLeft: 10 }} />
                  </>
                ) : (
                  "Buscar"
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => CreateTablePDFDailyInstallments("#audit-table", selectedDate, selectedRoute)}
                style={{ marginRight: 12 }}
                disabled={auditInformation && auditInformation.length <= 0}
              >
                Imprimir
              </Button>
            </Box>
          </Box>

          <Typography variant="h6" className={classes.dateText}>
            Fecha seleccionada: {formattedDate}
          </Typography>

          <TableContainer>
            <Table id="audit-table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Ruta</TableCell>
                  <TableCell>Frecuencia de pago</TableCell>
                  <TableCell>Día de pago</TableCell>
                  <TableCell>Domicilio</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading
                  ? renderSkeleton(5) // Renderizar 5 filas de skeleton mientras carga
                  : auditInformation && auditInformation.length > 0
                  ? auditInformation.map((info) => (
                      <TableRow key={info.id_client}>
                        <TableCell>{info.id_client}</TableCell>
                        <TableCell>{info.name || "SN"}</TableCell>
                        <TableCell>{info.description}</TableCell>
                        <TableCell>{getPaymentFrequency(info.payment_frequency_id)}</TableCell>
                        <TableCell>{getDayOfWeek(info.preference_day)}</TableCell>
                        <TableCell>{`${info.street} ${info.external_number}, ${info.neighborhood}, ${info.city}, ${info.state}, ${info.zip_code}`}</TableCell>
                      </TableRow>
                    ))
                  : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No hay datos
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </LocalizationProvider>
    </DrawerMenu>
  );
};

export default DailyCollections;
