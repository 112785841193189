import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  productsSelected: [],
  totalProductAmount: 0,
  selectedClient: {},
  paymentType: 2,
  fileBase64: '',
  comments: ''
};

export const productsInformationReducer = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts(state, { payload }) {
      state.clients = payload;
    },
    setProductsSelected(state, { payload }) {
      state.productsSelected = payload;
    },
    updateProductSelected(state, { payload }) {
      const itemProductIndex = state.productsSelected.findIndex(
        (item) => item.idArticle === payload.idArticle
      );
      if (itemProductIndex < 0) {
        return false;
      } else {
        state.productsSelected[itemProductIndex].price = payload.price;
      }
    },
    addProductToCart: (state, { payload }) => {
      const itemIndex = state.productsSelected.findIndex(
        (item) => item.idArticle === payload.idArticle
      );
      if (itemIndex < 0) {
        state.productsSelected = [...state.productsSelected, payload];
      } else {
        state.productsSelected[itemIndex].quantity += 1;
      }
    },
    removeProductFromCart: (state, { payload }) => {
      state.productsSelected = state.productsSelected.filter(
        (b) => b.idArticle !== payload
      );
    },
    addProductsQuantity(state, { payload }) {
      const itemIndex = state.productsSelected.findIndex(
        (item) => item.idArticle === payload
      );
      if (itemIndex >= 0) {
        state.productsSelected[itemIndex].quantity += 1;
      }
    },
    removeProductsQuantity(state, { payload }) {
      const itemIndex = state.productsSelected.findIndex(
        (item) => item.idArticle === payload
      );
      if (itemIndex >= 0) {
        if (state.productsSelected[itemIndex].quantity > 1) {
          state.productsSelected[itemIndex].quantity -= 1;
        }
      }
    },
    addAmount: (state, { payload }) => {
      state.totalProductAmount += payload;
    },
    setNewAmount: (state, { payload }) => {
      state.totalProductAmount = payload;
    },
    removeAmount: (state, { payload }) => {
      state.totalProductAmount -= payload;
    },
    setSelectedClient: (state, { payload }) => {
      state.selectedClient = payload;
    },
    setPaymentType: (state, { payload }) => {
      state.paymentType = payload;
    },
    setFileBase64: (state, { payload }) => {
      state.fileBase64 = payload;
    },
    setComments: (state, { payload }) => {
      state.comments = payload;
    },
    resetProductsInformation: (state) => (state = initialState),
  },
});

export const {
  setProducts,
  setProductsSelected,
  addProductToCart,
  removeProductFromCart,
  addProductsQuantity,
  addAmount,
  removeAmount,
  removeProductsQuantity,
  resetProductsInformation,
  setSelectedClient,
  setPaymentType,
  setFileBase64,
  updateProductSelected,
  setNewAmount
} = productsInformationReducer.actions;

export const selectProducts = (state) =>
  state.products ? state.products.clients : null;
export const selectProductsSelected = (state) =>
  state.products ? state.products.productsSelected : null;
export const selectTotalProductAmount = (state) =>
  state.products ? state.products.totalProductAmount : null;
export const selectClientForInvoice = (state) =>
  state.products ? state.products.selectedClient : null;
export const selectAreProductsOnCart = (state) =>
  state.products.productsSelected && state.products.productsSelected.length > 0
    ? true
    : false;
export const selectPaymentType = (state) =>
  state.products ? state.products.paymentType : null;
export const selectFileBase64 = (state) =>
  state.products ? state.products.fileBase64 : null;
export const selectComments = (state) =>
  state.products ? state.products.comments : null;

export default productsInformationReducer.reducer;
