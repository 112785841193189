import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  IconButton,
  Button,
  Chip,
  Box,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { LuUserX2, LuUserCheck2 } from "react-icons/lu";
import { RiLockPasswordLine } from "react-icons/ri";

import UsersDataTableStyles from "./UsersDataTable.styles";
import { getComparator, stableSort } from "../utils/dataTableUtils";

const UserListDataTable = (props) => {
  const {
    openViewUserInformation,
    openDeleteUser,
    openChangePassword,
    users,
    loading,
  } = props;

  const classes = UsersDataTableStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("username");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState("active");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setPage(0);
  };

  const handleState = (state) => {
    if (state === 1) {
      return <Chip label="Activo" className={classes.activeChipState} />;
    } else {
      return <Chip label="Inactivo" className={classes.inactiveChipState} />;
    }
  };

  const filteredUsers =
    filter === "all"
      ? users
      : users.length > 0
      ? users.filter((user) =>
          filter === "active" ? user.status === 1 : user.status !== 1
        )
      : [];

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const renderSkeletonRows = (count) => {
    return Array.from({ length: count }).map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="rect" width={48} height={24} />
          <Skeleton
            variant="rect"
            width={48}
            height={24}
            style={{ marginLeft: 8 }}
          />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <div className={classes.userFiltersSection}>
        <Button
          className={filter === "all" ? classes.activeButton : classes.button}
          onClick={() => handleFilterChange("all")}
        >
          Todos
        </Button>
        <Button
          className={
            filter === "active" ? classes.activeButton : classes.button
          }
          onClick={() => handleFilterChange("active")}
        >
          Activos
        </Button>
        <Button
          className={
            filter === "inactive" ? classes.activeButton : classes.button
          }
          onClick={() => handleFilterChange("inactive")}
        >
          Inactivos
        </Button>
      </div>
      <Paper>
        <TableContainer component={Paper} style={{ overflowX: "auto" }}>
          <Table style={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "username"}
                    direction={orderBy === "username" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "username")}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      Usuario
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "name")}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      Nombre
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "route"}
                    direction={orderBy === "route" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "route")}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      Ruta
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "status"}
                    direction={orderBy === "status" ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, "status")}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      Estado
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    className={classes.iconActionsContainer}
                  >
                    <Typography
                      className={classes.headerColumnTitle}
                      align="center"
                    >
                      {" "}
                      Acciones{" "}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? renderSkeletonRows(rowsPerPage)
                : stableSort(filteredUsers, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => (
                      <TableRow key={user.id_user}>
                        <TableCell align="center">
                          <Typography className={classes.infoDataItem}>
                            {user.username.toLowerCase()}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.infoDataItem}>
                            {user.name.toLowerCase()}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography className={classes.infoDataItem}>
                            {user.route?.toLowerCase()}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {handleState(user.status)}
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            justifyContent="space-around"
                            alignItems="center"
                            className={classes.iconActionsContainer}
                          >
                            <Tooltip title="ver" placement="bottom">
                              <IconButton
                                onClick={() => openViewUserInformation(user)}
                              >
                                <MdOutlineRemoveRedEye
                                  className={classes.actionIcons}
                                />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title={`${
                                user.status === 1 ? "desactivar" : "activar"
                              }`}
                              placement="bottom"
                            >
                              <IconButton
                                aria-label={`${
                                  user.status === 1 ? "desactivar" : "activar"
                                }`}
                                onClick={() => openDeleteUser(user)}
                              >
                                {user.status === 1 ? (
                                  <LuUserX2 className={classes.actionIcons} />
                                ) : (
                                  <LuUserCheck2
                                    className={classes.actionIcons}
                                  />
                                )}
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="cambiar password"
                              placement="bottom"
                            >
                              <IconButton
                                disabled={user.status === 0}
                                onClick={() => openChangePassword(user)}
                              >
                                <RiLockPasswordLine
                                  className={`${
                                    user.status === 0
                                      ? classes.actionIconsDisabled
                                      : classes.actionIcons
                                  }`}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
              {!loading && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página:"
        />
      </Paper>
    </>
  );
};

export default UserListDataTable;
