import { makeStyles } from "@material-ui/core/styles";
import {
  FONT_CONSTANTS,
  MONTSERRAT_FONTS,
} from "../../constants/styleConstants";

const ClientStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: 80,
    width: "100%",
    maxWidth: 1200,
  },
  clientsTitle: {
    color: theme.palette.primary.deep_pine,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 32,
    marginTop: 30,
  },

  searchBarContainer: {
    height: 92,
    width: "100%",
    clear: "both",
    marginTop: 75,
    marginBottom: 20,
  },

  searchBarInputfield: {
    width: 600,
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: 50,
    "& .MuiOutlinedInput-root": {
      borderRadius: 50,
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
    },
  },

  searchBarWithDropdown: {
    width: "45%",
    backgroundColor: theme.palette.primary.contrastText,
    "& .MuiOutlinedInput-root": {
      borderRadius: 50,
      border: "1px solid",
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  },

  chooseRouteButton: {
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    fontSize: 16,
    float: "right",
    marginRight: 140,
    height: 40,
    width: 140,
    marginTop: 20,
    textTransform: "none",
    borderRadius: 5,
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderRadius: 5,
    marginTop: 20,
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    fontSize: 16,
    float: "right",
    marginRight: 80,
    height: 40,
    width: 120,
    textTransform: "none",
  },
  massiveUpdate: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 16,
    float: "right",
    marginRight: 50,
    height: 50,
    width: 200,
    textTransform: "none",
    borderRadius: 10,
    borderColor: theme.palette.primary.honey_gold,
    border: "1px solid",
    background: theme.palette.primary.white,
    color: theme.palette.primary.honey_gold,
  },
  addClientButton: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 16,
    float: "right",
    marginRight: 50,
    height: 50,
    width: 200,
    textTransform: "none",
    borderRadius: 10,
    background: theme.palette.primary.soft,
  },
  paramsDropdown: {
    width: 150,
  },
  filterButton: {
    backgroundColor: "white",
    float: "right",
    borderRadius: "50%",
    padding: theme.spacing(1),
    minWidth: 0,
    width: 50,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0), 0px 1px 18px 0px rgba(0,0,0,0)", // Añade una sombra para resaltar el botón
    "&:hover": {
      backgroundColor: "#F8EDED",
    },
  },
  icon: {
    color: "black",
    width: 25,
    height: 25,
  },
  filterComponent: {
    display: "flex",
    width: "90%",
    marginBottom: 50,
    marginTop: theme.spacing(2),
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2), // Aplica margen a todos los hijos excepto el último
    },
  },
}));

export default ClientStyles;
