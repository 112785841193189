import React, { useState } from "react";

import { Tabs, Tab, Box } from "@material-ui/core";
import StatusTabsInvoiceStyles from "./StatusTabsInvoice.Styles";

const StatusTabsInvoice = ({ onChangeStatus }) => {
  const classes = StatusTabsInvoiceStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChangeStatus(newValue);
  };

  return (
    <Box className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="none"
        textColor="primary"
      >
        <Tab label="TODAS" className={classes.tab} />
        <Tab label="PAGADAS" className={classes.tab} />
        <Tab label="PENDIENTES" className={classes.tab} />
        <Tab label="CANCELADAS" className={classes.tab} />
        <Tab label="CRÉDITO" className={classes.tab} />
        <Tab label="CONTADO" className={classes.tab} />
      </Tabs>
    </Box>
  );
};

export default StatusTabsInvoice;
