import { makeStyles } from "@material-ui/core/styles";
import {
  COLOR_CONSTANTS,
  FONT_CONSTANTS,
  MONTSERRAT_FONTS,
} from "../../../constants/styleConstants";

const ChangePasswordModalStyles = makeStyles((theme) => ({
  changePasswordContainer: {
    backgroundColor: theme.palette.primary.white,
    width: 400,
    borderRadius: 10,
    padding: 40,
    position: "relative", 
  },
  changePasswordTitle: {
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD,
    fontSize: 22,
  },
  cpSubheader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 13,
    color: COLOR_CONSTANTS.TEXT_DARK_DISABLED,
    marginTop: 10,
    maxWidth: 320,
  },
  passwordInput: {
    marginTop: 40,
    width: 320,
  },
  label: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
  },
  helperTextError: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 12,
  },
  correctPassword: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 10,
    color: COLOR_CONSTANTS.TEXT_DARK_DISABLED,
    opacity: 0,
  },
  checkIconPassword: {
    marginRight: 5,
  },
  correctPasswordVisible: {
    opacity: 1,
    transition: "opacity 0.5s ease-in-out",
  },
  passwordButtonMargin: {
    marginTop: 20,
  },
  changePasswordBtn: {
    height: 45,
    borderRadius: 10,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
  },
  closeIconContainerMain: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  buttonProgress: {
    color: 'white',
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default ChangePasswordModalStyles;
