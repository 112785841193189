import React, { Fragment } from "react";
import UploadClientsStyles from "./UploadClients.styles";
import { Box, Typography } from "@material-ui/core";
import * as XLSX from "xlsx";
import { TYPE_FILE } from "../../../constants/fileFormats";
import imgSubirArchivo from "../../../assets/images/img-Excel.svg";
import { headersFields } from "../Functions/headersFields";
import { notification } from "../../../utils/notifications";
import { validateRow } from "../Functions";

const UploadClients = (props) => {
  const {
    setLoader,
    state,
    setState,
    setshowTable,
    setClients,
    setErrorUploadInfo,
  } = props;

  const classes = UploadClientsStyles();

  const getRows = async () => {
    let sheet = state.woorksheets[0],
      rowsExcel = XLSX.utils.sheet_to_row_object_array(sheet.data);

    let newRows = [];

    await Promise.all(
      rowsExcel.map(async (row) => {
        let claves = Object.keys(row);
        console.log(claves);

        if (claves.length > 5) newRows.push({ ...row });
      })
    );

    state.rows = newRows;
  };

  const getHeaders = () => {
    let sheet = state.woorksheets[0];
    state.headers = [];
    for (let key in sheet.data) {
      let regEx = new RegExp("^(\\w)(1){1}$");
      if (regEx.test(key) === true) {
        state.headers.push(sheet.data[key].v);
      }
    }
  };

  const readExcel = async (e, isFileButton) => {
    e.preventDefault();

    setLoader(true);

    let excel =
      isFileButton === true ? e.target.files[0] : e.dataTransfer.files[0];
    let listWorksheet = [];

    if (excel) {
      if (excel.type === TYPE_FILE.EXCEL) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(excel);
        reader.onloadend = async (e) => {
          let data = new Uint8Array(e.target.result);
          let excelRead = XLSX.read(data, { type: "array" });
          if (excelRead.SheetNames[0]) {
            listWorksheet.push({
              data: excelRead.Sheets[excelRead.SheetNames[0]],
              name: excelRead.SheetNames[0],
            });
          }

          state.woorksheets = listWorksheet;

          getHeaders();
          await getRows();

          await verifyDataTrustedUser();

          setState({
            woorksheets: state.woorksheets,
            rows: state.rows,
            headers: state.headers,
            status: true,
          });

          setLoader(false);
        };
      } else {
        setState({
          woorksheets: [],
          rows: [],
          headers: [],
          status: false,
        });
        setLoader(false);
      }
    } else {
      setState({
        woorksheets: [],
        rows: [],
        headers: [],
        status: false,
      });
      setLoader(false);
    }
  };

  const verifyDataTrustedUser = async () => {
    let flagError = false,
      message = "",
      getClients = [],
      errorCounter = 0;

    await Promise.all(
      state.rows.map(async (row) => {
        await Promise.all(
          state.headers.map(async (header) => {
            if (validateRow(header, row[header])) {
              errorCounter++;
              flagError = true;
              message = "El campo ${header} es incorrecto o se encuentra vacío";
            }
          })
        );

        getClients.push({
          name: row.Nombre,
          street: row.Calle,
          externalNumber: row.Numero_Exterior,
          internalNumber:
            row.Numero_Interior === undefined ? "" : row.Numero_Interior,
          neighborhood: row.Colonia,
          city: row.Ciudad,
          state: row.Estado,
          zipCode: row.CP,
          personalPhoneNumber: row.Calular,
          homePhoneNumber: row.Telefono_Casa,
          email: "",
          status: 1,
          payDays: row.Dia_Pago === undefined ? "" : row.Dia_Pago,
          paymentFrequency:
            row.Frecuencia_Pago === undefined ? "" : row.Frecuencia_Pago,
          latitude: "",
          longitude: "",
          comments: row.Referencias,
        });
      })
    );

    if (state.headers.length > headersFields.length) {
      setshowTable(false);
    } else {
      setshowTable(true);
    }

    if (state.headers.length !== headersFields.length) {
      flagError = true;
      message = "Los nombres de las columnas son incorrectos";
    }

    if (state.rows.length === 0) {
      flagError = true;
      message = "El archivo no contiene información";
    }

    if (flagError) {
      errorCounter > 1
        ? notification("La información del archivo de excel es incorrecta")
        : notification(message);

      setErrorUploadInfo(true);
    } else {
      notification("La información se cargo correctamente", "success");

      setClients(getClients);
      setErrorUploadInfo(false);
    }
  };

  return (
    <Fragment>
      <Box className={classes.container}>
        <Box className={classes.containerForm}>
          <Box
            className={classes.containerUploadFile}
            onDrop={(e) => readExcel(e, false)}
          >
            <Typography className={classes.text}>
              Sube el archivo aquí
            </Typography>
            <img src={imgSubirArchivo} alt="" className={classes.img} />
            <input
              type="file"
              className={classes.input}
              accept={TYPE_FILE.EXCEL}
              name="excel"
              onChange={(e) => readExcel(e, true)}
              onClick={(e) => (e.target.value = null)}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default UploadClients;
