import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentsByDay } from "../../api/ApiPayments";
import { getUsers } from "../../api/apiUsers";
import DrawerMenu from "../../components/DrawerMenu/Index";
import {
  resetDailyPayments,
  selectDailyPayments,
  selectFilteredDailyPayments,
  setDailyPayments,
  setFilterDailyPayments,
} from "../../store/slices/payments";
import {
  selectAvailableUsers,
  setAvailableUsers,
} from "../../store/slices/user";
import PaymentsStyles from "./Payments.styles";
import CollapedTable from "./CollapsedTable";
import DatePicker from "../../components/sharedComponents/DatePicker";
import { selectAccessToken } from "../../store/slices/clients";
import { errors } from "../../utils/ErrorAxios";
import useLogout from "../../hooks/useLogout";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const Payments = () => {
  const classes = PaymentsStyles();
  const dispatch = useDispatch();
  const availableUsers = useSelector(selectAvailableUsers);
  const rows = useSelector(selectFilteredDailyPayments);
  const dailyPayments = useSelector(selectDailyPayments);
  const accessToken = useSelector(selectAccessToken);
  const today = new Date();

  const [selectedDate, setSelectedDate] = useState(today);

  const handleChangeDate = (date) => {
    setSelectedDate(date);
  };

  const [logout] = useLogout();

  useEffect(() => {
    getUsers(accessToken)
      .then((res) => {
        dispatch(setAvailableUsers(res.data.body));
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      });
  }, [accessToken, dispatch]);

  useEffect(() => {
    // Formatea selectedDate a 'YYYY-MM-DD' para enviarlo al servidor
    const formattedDate = formatDate(selectedDate);

    getPaymentsByDay(formattedDate, accessToken)
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.body.length > 0) {
            dispatch(setDailyPayments(res.data.body));
          } else {
            dispatch(resetDailyPayments());
          }
        }
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      });
  }, [accessToken, dispatch, selectedDate]); // Dependemos de selectedDate, cuando cambia, hacemos la petición

  useEffect(() => {
    async function getInvoicesInformation() {
      const totalInvoicesFiltered = [];
      await availableUsers.forEach(async (user) => {
        let filteredInvoices = dailyPayments.filter(
          (payment) => payment.id_user === parseInt(user.id_user)
        );
        let amount = filteredInvoices.reduce(
          (sum, item) => sum + parseInt(item.total_payment),
          0
        );
        const date =
          dailyPayments.length > 0 ? dailyPayments[0]["created_at"] : "";

        let data = {
          userId: user.id_user,
          username: user.username,
          payments: filteredInvoices.length,
          amount: amount,
          date: date,
        };
        totalInvoicesFiltered.push(data);
      });
      dispatch(setFilterDailyPayments(totalInvoicesFiltered));
    }

    if (dailyPayments.length > 0) {
      getInvoicesInformation();
    }
  }, [availableUsers, dailyPayments, dispatch]);

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="h2" className={classes.paymentsTitle}>
          Abonos
        </Typography>
        <Typography variant="subtitle2" className={classes.paymentsTitle}>
          Elige la fecha que quieres visualizar
        </Typography>
        <DatePicker
          selectedDate={selectedDate}  // Pasa el objeto Date directamente
          handleChangeDate={handleChangeDate}
          dateStyles={classes.datePicker}
        />
        <CollapedTable
          rows={rows}
          dailyPayments={dailyPayments}
          selectedDate={selectedDate}
        />
      </Box>
    </DrawerMenu>
  );
};

export default Payments;
