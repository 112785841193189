import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../../constants/styleConstants";

const chooseBodyModalStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.primary.white,
    width: 500,
    height: 350,
    borderRadius: 10,
    padding: 30,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  mainTitle: {
    marginTop: 15,
  },
  mainSubtitle: {
    marginTop: 10,
    color: theme.palette.primary.dark,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },
  routesDropdown: {
    width: 220,
    marginTop: 20,
  },
  updateData: {
    marginTop: 40,
    width: 140,
    borderRadius: 5,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: 320,
  },
  cancelButton: {
    marginTop: 40,
    width: 140,
    borderRadius: 5,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },

}));

export default chooseBodyModalStyles;
