import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@material-ui/core";
import TableAddClientsStyles from "./TableAddClients.styles";
import TableSkeleton from "../../sharedComponents/TableSkeleton";
import { headers } from "./header";
import { validateRow } from "../Functions";
import NotRows from "../../../assets/images/no-results.png";

const TableAddClients = (props) => {
  const { loader, state, showTable } = props;

  const classes = TableAddClientsStyles();

  return (
    <div className={classes.content}>
      <Paper className={classes.container}>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <TableHead>
              <TableRow style={{ background: "#159e608a" }}>
                {headers.map((header, index) => {
                  return (
                    <TableCell
                      key={index}
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      <Typography>{header.label}</Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loader ? (
                showTable ? (
                  state?.rows.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        {state.headers.map((header, indexHeader) => {
                          if (validateRow(header, row[header])) {
                            return (
                              <TableCell
                                key={indexHeader}
                                className={classes.textRowError}
                              >
                                {row[header]}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                className={classes.textRow}
                                key={indexHeader}
                              >
                                {row[header]}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={13}>
                      <Box className={classes.containerNoRecords}>
                        <img
                          src={NotRows}
                          alt="Sin registros"
                          className={classes.imageNoRecords}
                        />
                        <Typography className={classes.textNoRecords}>
                          No se han cargado clientes aún
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableSkeleton rowsNum={5} columnsNum={13} showHeader={true} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default TableAddClients;
