import { notification } from "./notifications";

export const errors = (err, text,  logout,) => {
  let message = "";

  if (err.response) {
    if (err.response.status === 401) {
      message = err.response.data.message;
      logout();
    } else if (err.response.status === 404) {
      message = "La ruta no se encontro, " + text ;
    } else if (err.response.data) {
      message = err.response.data.message + ", " + text ;
    } else {
      message = err.message  + ", " + text;
    }
  } else {
    message = "Error de conexión, revisa tu acceso a internet";
  }
  notification(message, "error")
};

