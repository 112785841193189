import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import CancelInvoiceByIdModalStyles from "./CancelInvoiceByIdModal.styles";
import { MdClose } from "react-icons/md";
import CenteredContent from "../../sharedComponents/CenteredContent";
import { useSelector } from "react-redux";
import {
  selectUserId,
  selectUserName,
} from "../../../store/slices/user";
import {
  CANCEL_INVOICE_TEXT_TICKET,
} from "../../../constants/constantMessages";
import AnimationContent from "../../sharedComponents/AnimationContent";
import LoadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import successAnimation from "../../../assets/lottieFiles/successAnimation.json";
import { cancelInvoice } from "../../../api/ApiInvoices";
import { selectAccessToken } from "../../../store/slices/clients";
import useGetFolioNumber from "../../../hooks/useGetFolioNumber";
import useGetRouteName from "../../../hooks/useGetRouteName";
import { errors } from "../../../utils/ErrorAxios";
import useLogout from "../../../hooks/useLogout";
const CancelInvoiceByIdModal = (props) => {
  const { invoiceinfo, handleClickCloseModal, clientName, setfinishCancel } = props;
  const idUser = useSelector(selectUserId);
  const [localization, setLocalization] = useState("");
  const [payment, setPayment] = useState(1);
  const [comments, setComments] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const username = useSelector(selectUserName);
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector(selectAccessToken);
  const idPaymentFormat = useGetFolioNumber();
  const routeName = useGetRouteName();
  const [logout] = useLogout();
 
  useEffect(() => {
    if (navigator.geolocation) {
      //check if geolocation is available
      navigator.geolocation.getCurrentPosition(function (position) {
        let location = position.coords;
        let { latitude, longitude } = location;
        setLocalization(`${latitude}, ${longitude}`);
      });
    } else {
      setLocalization("20.000948124862976, -102.27041548703968");
    }
  }, []);

  /* const handleChangeAmountValue = (value) => {
    const isNum = /^[0-9]+$/;

    if (!value || value < 0 || !value.match(isNum)) {
      setAmountError(true);
    } else {
      amountError && setAmountError(false);
    }
    setPayment(value);
  }; */

  const handleChangeAmountValue = (value) => {
    const isNum = /^[0-9]+$/;
  
    // Verificar si el valor es válido y menor o igual al adeudo
    if (!value || !value.match(isNum) || parseInt(value) < 0) {
      setAmountError(true);
    } else if (parseInt(value) > parseInt(invoiceinfo.remaining_payment)) {
      setAmountError(true);
      setErrorMessage("La cantidad debe ser menor o igual al adeudo");
    } else {
      setAmountError(false);
    }
    setPayment(value);
  };
  const handleSubmitCancelInvoice = () => {
    setfinishCancel(true)
    setLoading(true);
    // cancelInvoice

    const tiket_cancel_info = CANCEL_INVOICE_TEXT_TICKET(
      clientName ,
      invoiceinfo.id_invoice,
      routeName,
      idPaymentFormat,
      invoiceinfo.remaining_payment,
      payment,
      username
    );

    const payload = {
      idPayment: idPaymentFormat,
      idInvoice: invoiceinfo.id_invoice,
      idUser: parseInt(idUser),
      amount: !payment ? 0 : parseInt(payment),
      locationGPS: localization,
      comments: comments,
      textTicket: tiket_cancel_info,
      printedTicket: false,
    };

    cancelInvoice(payload, accessToken)
      .then((res) => {
        setfinishCancel(false)
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          // window.location.reload();
          handleClickCloseModal();
        }, 3000);
      })
      .catch(({ err }) => {
        errors(err, "No se pudo cancelar", logout);
      })
  };

  const classes = CancelInvoiceByIdModalStyles();
  return (
    <Box className={classes.mainContainer}>
      {loading || success ? (
        <CenteredContent direction="column">
          <AnimationContent
            animationFile={success ? successAnimation : LoadingAnimation}
            height={300}
            width={300}
          />
          <Typography variant="h6" className={classes.loadinHeader}>
            {success ? "Factura Cancelada" : "Cancelando Factura"}
          </Typography>
          <Typography variant="subtitle2">No cierre la pantalla</Typography>
        </CenteredContent>
      ) : (
        <div>
          <MdClose
            className={classes.closeIcon}
            onClick={handleClickCloseModal}
          />
          {invoiceinfo && (
            <>
                <Box className={classes.idBox}>
                <Typography
                  className={classes.cancelInvoiceTitle}
                >
                  Cancelación
                </Typography>
                <Typography
                  className={classes.idInvoiceHeader}
                >
                  Id de Factura: {invoiceinfo.id_invoice}
                </Typography>
              </Box>

              <CenteredContent>
                <Box className={classes.formContainer}>
                  <Typography className={classes.actualInvoice}>Su adeudo actual</Typography>
                  <Typography className={classes.invoiceActual}>$
                    {invoiceinfo.remaining_payment}
                    
                    </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.simpleText}
                  >
                    Cantidad por cancelación
                  </Typography>
                  <TextField
                    value={payment}
                    onChange={(e) => handleChangeAmountValue(e.target.value)}
                    variant="outlined"
                    className={classes.amountField}
                    error={amountError}
                    helperText={amountError && errorMessage}
                    //helperText={amountError && "Ingrese una cantidad válida"}
                  />
                  <Typography
                    variant="subtitle2"
                    className={classes.simpleText}
                  >
                    Comentarios (opcional)
                  </Typography>
                  <TextField
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    variant="outlined"
                    className={classes.amountField}
                    multiline={true}
                    rows={5}
                    maxRows={5}
                  />
                </Box>
              </CenteredContent>
              <CenteredContent className={classes.cancelButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.cancelButton}
                  disabled={amountError}
                  onClick={() => handleSubmitCancelInvoice()}
                >
                  Cancelar
                </Button>
              </CenteredContent>
            </>
          )}
        </div>
      )}
    </Box>
  );
};

export default CancelInvoiceByIdModal;
