import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_NAVICK_API_URL } = process.env;

export const getInfoCompanies = (accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/companies/get_companies_information`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
      "Cache-Control": "no-cache",
    },
  };

  return axios(request);
};

export const getCompaniesCatalog = (accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/companies/get_companies_catalog`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
      "Cache-Control": "no-cache",
    },
  };

  return axios(request);
};

export const insertCompany = (accessToken, payload) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    url: `${REACT_APP_NAVICK_API_URL}/companies/insert`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const getTotalDataCompany = (companyId) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/companies/get_company_information/${companyId}`,
    headers: {
      "content-type": "application/json",
      token: getCookie("accessToken"),
      "Cache-Control": "no-cache",
    },
  };

  return axios(request);
};

export const updateCompanyStatus = (payload) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "PUT",
    url: `${REACT_APP_NAVICK_API_URL}/companies/update_status`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const getCompanyById = (accessToken, companyId) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/companies/get_company/${companyId}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const updateCompany = (accessToken, payload) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "PUT",
    url: `${REACT_APP_NAVICK_API_URL}/companies/update`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
