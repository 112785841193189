import { makeStyles } from "@material-ui/core/styles";

const CreateInvoicesStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: -40,
    marginBottom:100
  },
  clientNameInput: {
    width: 300,
    marginTop: 25,
  },
  productsDetail:{
    marginTop:50,
    color:theme.palette.primary.gray_30,
    marginBottom:20
  },
  chooseProductsTitle:{
    marginTop:20,
  },
  imageDisabled:{
    height:120,
    width:120,    
    fill:theme.palette.primary.gray_30,
  },
  totalInvoiceAmountText:{
    marginTop:40
  },
  totalInvoiceAmountTNumber:{
    marginTop:10
    ,marginBottom:20
  },
  paymentTypeText:{
    marginTop:10,
    marginBottom:15
  },
  containerDropMain:{
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center", 
    flexDirection:"column"
  },
  containerDrop:{
    background: "#e3e3e3",
    borderRadius:"10px",
    cursor:"pointer",
    width:"300px",
    height:"80px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    flexDirection:"column"
  },
  textDrop:{
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center", 
    opacity:"0.5", 
    flexDirection:"column-reverse"
  },
  iconDrop:{
    fontSize:"40px", 
    marginLeft:"10px"
  },
  imageFile:{
    width:"300px", 
    height:"170px", 
    marginTop:"10px",
  }
}));

export default CreateInvoicesStyles;
