import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS, COLOR_CONSTANTS } from "../../constants/styleConstants";

const CompaniesStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 30,
    background: "#E8E8E8",
    width: "100vw",
    height: "100%",
  },
  title: {
    fontSize: 32,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: theme.palette.primary.deep_pine,
    marginTop: 16,
    marginLeft: 15,
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 29,
    maxWidth: 920,
  },
  //   Filtro
  searchBarContainer: {
    height: 92,
    maxWidth: 910,
    width: "100%",
    clear: "both",
    marginLeft:17,
    marginBottom: 20,
    marginTop:45
  },
  searchBarWithDropdown: {
    width: "45%",
    backgroundColor: theme.palette.primary.contrastText,
    "& .MuiOutlinedInput-root": {
      borderRadius: 50,
      border: "1px solid",
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  },
  searchBarInputfield: {
    width: 540,
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: 50,
    "& .MuiOutlinedInput-root": {
      borderRadius: 50,
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
    },
  },
  filterButton: {
    backgroundColor: "white",
    float: "right",
    borderRadius: "50%",
    padding: theme.spacing(1),
    minWidth: 0,
    width: 50,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0), 0px 1px 18px 0px rgba(0,0,0,0)", // Añade una sombra para resaltar el botón
    "&:hover": {
      backgroundColor: "#F8EDED",
    },
  },
  icon: {
    color: "black",
    width: 25,
    height: 25,
  },
  addClientButton: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 16,
    float: "right",
    marginRight: 10,
    height: 50,
    width: 200,
    textTransform: "none",
    borderRadius: 10,
    background: theme.palette.primary.soft,
  },
  paramsDropdown: {
    width: 120,
    marginRight:10,
    background:"#fff"
  },
  // Table
  tableComponent:{
    maxWidth: 890,
    marginTop:20,
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
    marginBottom: 30,
    marginLeft:17
  },
  table: {
    maxWidth: 1065,
  },
  tableHead:{
    textAlign:"center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    color: theme.palette.primary.gray_30,
    textTransform: "uppercase;",
    borderBottom: `1px solid ${theme.palette.primary.divider}`,
  },

  tableBody:{
    textAlign:"center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.primary.divider}`,
    borderRadius: 20,
  },
  status: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize:14,
  },
  paid: {
    backgroundColor: theme.palette.primary.main_green_b,
    border: `1px solid ${theme.palette.primary.main_green}`,
    color: theme.palette.primary.main_green,
    textAlign: "center"
  },
  pending: {
    backgroundColor: theme.palette.primary.honey_gold_b,
    border: `1px solid ${theme.palette.primary.honey_gold}`,
    color: theme.palette.primary.honey_gold
  },
  cancelled: {
    backgroundColor: theme.palette.primary.main_red_b,
    border: `1px solid ${theme.palette.primary.main_red}`,
    color: theme.palette.primary.main_red
  },
  skeleton: {
    width: '98%',
    height: '30px',
    marginBottom: theme.spacing(1),
  },
  iconButton:{
    borderRadius: '50%',
    minWidth: '40px',
    minHeight: '40px',
    padding: '8px',
    boxShadow: '-1px 3px 6px rgba(0, 0, 0, 0.1)',
    margin: '0px 8px',
    "&:disabled": {
      opacity: 0.3
    },
  },
  switchBase: {
    marginRight:5,
    "&$checked": {
      color: "white",
      transform: "translateX(17px)",
    },
    "&$checked + $track": {
      backgroundColor: COLOR_CONSTANTS.DANGER,
    },
    "&:not($checked)": {
      color: "white",
    },
    "&:not($checked) + $track": {
      backgroundColor: COLOR_CONSTANTS.HONEY_GOLD,
    },
  },
  checked: {},
  track: {
    height: 18,
    width: 50,
    borderRadius: 34 / 2,
  },
  thumb: {
    width: 12,
    height: 12,
    marginTop:6,  
  },  
}));

export default CompaniesStyles;
