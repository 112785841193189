import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { getCookie } from "../../utils/sessionCookie";

const PrivateRoute = (props) => {
  const accessToken = getCookie("accessToken");

  if (!accessToken) {
    return <Redirect to={ROUTES_CONSTANTS.LOGIN} />;
  }

  return <Route {...props} />;
};
export default PrivateRoute;
