import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NotFoundPageStyles from "./NotFoundPage.styles";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import NotFoundPageImg from "../../assets/images/NotFoundPage.svg";
import { useSelector } from "react-redux";
import { selectLogedUserType } from "../../store/slices/user";

const NotFoundPage = () => {
  const userType = useSelector(selectLogedUserType);

  const classes = NotFoundPageStyles();
  const history = useHistory();

  const handleGoBack = () => {
    if (userType === 2) {
      history.push(ROUTES_CONSTANTS.ADMIN);
    } else if (userType === 7) {
      history.push(ROUTES_CONSTANTS.PARTNER_DETAILS);
    }
  };

  return (
    <div className={classes.container}>
      <img
        src={NotFoundPageImg}
        alt="Página no encontrada"
        className={classes.image}
      />
      <Typography className={classes.title}>
        ¡Ups! Ruta no encontrada
      </Typography>
      <Typography className={classes.message}>
        No tienes permiso para acceder a esta página.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleGoBack}
      >
        Regresar al inicio
      </Button>
    </div>
  );
};

export default NotFoundPage;
