import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_NAVICK_API_URL } = process.env;

export const getAvailableRoutes = (accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/routes`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const getRoutes = () => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/routes/routes_catalog`,
    headers: {
      "content-type": "application/json",
      token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const deleteRouteById = (idRoute, accessToken) => {
  let payload = { idRoute };
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "DELETE",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_NAVICK_API_URL}/rutas/${idRoute}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const addRoute = (description, accessToken) => {
  let payload = { description };

  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_NAVICK_API_URL}/routes/insert`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const getClientsByRoute = (idRoute, accessToken) => {
  let payload = { idRoute };
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_NAVICK_API_URL}/clients/only/${idRoute}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
