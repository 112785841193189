import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
import { format } from "date-fns";
const { REACT_APP_NAVICK_API_URL } = process.env;

const accessToken = getCookie("accessToken");

export const getClients = (accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/clients`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
export const deleteClient = (idClient, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "DELETE",
    url: `${REACT_APP_NAVICK_API_URL}/clientes/${idClient}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const updateClientStatus = (idCliente, status) => {
  const payload = {
    idCliente,
    status,
  };
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_NAVICK_API_URL}/clients/change_status`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const updateClient = (clientInfo, accessToken) => {
  const payload = {
    ...clientInfo,
  };

  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "PUT",
    url: `${REACT_APP_NAVICK_API_URL}/clients/update`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const createNewClient = (clientInfo, accessToken) => {
  const payload = {
    ...clientInfo,
  };

  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    url: `${REACT_APP_NAVICK_API_URL}/clients/insert`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const getClientsRaminingPayment = (accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/clients/remaining_payment_by_client`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const getAuditReportsByRoute = (idRoute, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/clients/auditReports/${idRoute}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const getDailyCollectionsByRouteAndDate = (
  idRoute,
  selectedDate,
  accessToken
) => {
  const formattedDate = format(selectedDate, "yyyy-MM-dd");

  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/clients/clients_payment_day/${idRoute}/${formattedDate}`, // Cambia la URL para que coincida con el endpoint
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const updateClientsRoutesMassive = (payload, accessToken) => {
  const data = {
    clients: [...payload],
  };

  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    data: data,
    body: JSON.stringify(payload),
    url: `${REACT_APP_NAVICK_API_URL}/clientes/massiveUpdate`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const getClientInvoicesByIdClient = (idClient, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/clients/get_client/${idClient}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const getClientBillWeekly = async (accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/invoices/summary_weekly_invoices`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return await axios(request);
};

export const getInvoicesFromClient = (idClient, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `${REACT_APP_NAVICK_API_URL}/clients/get_invoices_from_client/${idClient}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
