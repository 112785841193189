import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../constants/styleConstants";


const NotFoundPageStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
    overflow: "hidden",
  },
  image: {
    width: "80%", 
    maxWidth: "600px", 
    height: "auto",
  },
  title: {
    fontSize: "24px",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: theme.palette.primary.deep_pine,
    marginBottom: "10px",
  },
  message: {
    fontSize: "16px",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    color: theme.palette.primary.full_disable,
    marginBottom: "20px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "15px",
  },
}));

export default NotFoundPageStyles;
