import React from "react";
import InvoiceCardStatusStyle from "./InvoiceCardStatus.styles";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@mui/material";

const InvoiceCardStatus = (props) => {
  const { img, title, total, value, status, loading, totalCanceled } = props;

  const classes = InvoiceCardStatusStyle({ status });
  return (
    <Box className={`${classes.card} ${classes[status]}`}>
      {loading ? (
        <Skeleton variant="circular" width={60} height={60} />
      ) : (
        <img src={img} alt="status" />
      )}

      <Box className={classes.container}>
        {loading ? (
          <>
            <Skeleton variant="text" width={100} height={30} />
            <Skeleton variant="text" width={50} height={30} />
          </>
        ) : (
          <>
            <Box className={classes.boxTitle}>
              <Typography className={classes.title}>{title}</Typography>
            </Box>
            <Box className={classes.boxInfo}>
              <Typography className={classes.total}>Total: {total}</Typography>
              <Typography
                className={
                  status === "cancel" ? classes.cancelValue : classes.value
                }
              >
                Valor: ${value}
              </Typography>
            </Box>
            {totalCanceled && <Typography className={classes.total}>Saldado: <span className={classes.green}> ${totalCanceled}</span> </Typography>}
          </>
        )}
      </Box>
    </Box>
  );
};

export default InvoiceCardStatus;
