import { makeStyles } from "@material-ui/core/styles";
import {
  COLOR_CONSTANTS,
  MONTSERRAT_FONTS,
} from "../../../constants/styleConstants";

const UsersDataTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    minWidth: 900,
    maxWidth: "90%",
    marginBottom: theme.spacing(2),
    marginTop: 80,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  dataTableheads: {
    ...theme.typography.itemsTypography,
  },
  dataTableItems: {
    ...theme.typography.itemsTableTypography,
  },
  tableCellItem: {
    border: "none",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover, &:focus, &:active,  &:visited": {
      backgroundColor: theme.palette.primary.apple_green + " !important",
    },
  },
  iconActionsContainer: {
    // paddingLeft: 10,
    // paddingRight: 10,
  },
  actionIcons: {
    fontSize: 20,
    color: theme.palette.primary.green_60,
    cursor: "pointer",
  },
  actionIconsDisabled: {
    color: COLOR_CONSTANTS.GRAY_30,
    fontSize: 20,
  },
  activeChipState: {
    width: 110,
    height: 35,
    color: `${theme.palette.primary.white} !important`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    fontWeight: 700,
    fontSize: 14,
  },
  inactiveChipState: {
    width: 110,
    height: 35,
    color: `${theme.palette.primary.white} !important`,
    backgroundColor: `${theme.palette.secondary.inactive} !important`,
    fontWeight: 700,
    fontSize: 14,
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: 50,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    width: 100,
    "&:hover": {
      backgroundColor: COLOR_CONSTANTS.SOFT_MINT,
      color: theme.palette.primary.contrastText,
    },
  },
  activeButton: {
    margin: theme.spacing(1),
    backgroundColor: COLOR_CONSTANTS.SOFT_MINT,
    color: theme.palette.primary.contrastText,
    borderRadius: 50,
    textTransform: "none",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    width: 100,
    "&:hover": {
      backgroundColor: COLOR_CONSTANTS.SOFT_MINT,
    },
  },
  userFiltersSection: {
    marginTop: 40,
    marginBottom: 30,
  },
  headerColumnTitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    textTransform: "uppercase",
    fontSize: 13,
  },
  infoDataItem: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
  },
}));

export default UsersDataTableStyles;
