import axios from "axios";
const { REACT_APP_NAVICK_API_URL } = process.env;

export const loginUser = (username, password) => {
  const payload = {
    username,
    password,
    app: "web",
  };
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_NAVICK_API_URL}/login`,
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};
