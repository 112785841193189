export const headersFields = [
  "Nombre",
  "Calular",
  "Telefono_Casa",
  "Calle",
  "Numero_Exterior",
  "Numero_Interior",
  "Colonia",
  "Ciudad",
  "Estado",
  "CP",
  "Referencias",
  "Frecuencia_Pago",
  "Dia_Pago",
];
