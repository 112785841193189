// import { makeStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../constants/styleConstants";


const PartnerDetailsStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    paddingLeft: 45,
    overflowX: "hidden",
    overflowY: "auto",
    background: "#E8E8E8",
  },
  titleSection: {
    fontSize: 20,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: theme.palette.primary.text_disable_medium,
    marginTop: 40,
  },
  companyName: {
    marginBottom: 30,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    color: theme.palette.primary.deep_pine,
    fontSize: 42,
    marginTop: 30,
  },
  companyInfoContainer: {
    marginBottom: 40,
    marginTop: 40,
    maxWidth: 1550,
  },
  information: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 50,
    gap: 30,
    maxWidth: 1100,
  },
  section: {
    flex: 1,
  }, 
  verticalDivider: {
  height: "100%", // El Divider debe tomar la altura completa del contenido
  alignSelf: "center", // Centrar verticalmente
},
  infoCard: {
    padding: 20,
    paddingLeft: 40,
    backgroundColor: "#fff",
    borderRadius: 8,
    borderLeftStyle: "solid",
    borderLeftWidth: "3px",
    borderLeftColor: theme.palette.primary.soft,
    maxWidth: 920,
  },

  invoiceSection: {
    marginTop: 40,
  },
  containerInvoiceCardStatus: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 50,
    gap: 30,
    maxWidth: 1100,
  },
  invoiceTableContainer: {
    marginTop: 30,
    borderRadius: 8,
    backgroundColor: "#fff",
    padding: 20,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12)",
  },
  cardsContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: 920,
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    maxWidth: 365,
    flexWrap: "wrap",
  },

  paid: {
    backgroundColor: theme.palette.primary.main_green_b,
    border: `1px solid ${theme.palette.primary.main_green}`,
    color: theme.palette.primary.main_green,
    textAlign: "center",
  },
  pending: {
    backgroundColor: theme.palette.primary.honey_gold_b,
    border: `1px solid ${theme.palette.primary.honey_gold}`,
    color: theme.palette.primary.honey_gold,
  },
  cancelled: {
    backgroundColor: theme.palette.primary.main_red_b,
    border: `1px solid ${theme.palette.primary.main_red}`,
    color: theme.palette.primary.main_red,
  },
  tableComponent: {
    maxWidth: 1065,
    marginTop: 20,
    borderRadius: 10,
    overflow: "hidden",
    boxShadow: theme.shadows[3],
    marginBottom: 100,
  },
  table: {
    maxWidth: 1065,
  },
  tableHead: {
    textAlign: "center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    color: theme.palette.primary.gray_30,
    textTransform: "uppercase;",
    borderBottom: `1px solid ${theme.palette.primary.divider}`,
  },
  tableBody: {
    textAlign: "center",
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.primary.divider}`,
    borderRadius: 20,
  },
  iconButton: {
    borderRadius: "50%",
    minWidth: "40px",
    minHeight: "40px",
    padding: "8px",
    boxShadow: "-1px 3px 6px rgba(0, 0, 0, 0.1)",
    margin: "0px 8px",
    "&:disabled": {
      opacity: 0.3,
    },
  },
  status: {
    height: 30,
    width: 73,
    borderRadius: "5px",
    fontSize: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 23,
  },
  txtInfoCompany: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: theme.palette.primary.text_disable_medium,
    fontSize: 25,
  },
  txtSeccion: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: theme.palette.primary.full_disable,
    fontSize: 18,
    marginTop: 5,
  },
  txtKey: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: theme.palette.primary.text_disable_medium,
    fontSize: 15,
    marginTop: 5,
  },
  txtValue: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    color: theme.palette.primary.text_disable_medium,
    fontSize: 15,
    marginTop: 5,
  },
  divider: {
    margin: "20px 0",
    backgroundColor: theme.palette.primary.full_disable,
    height: "2px",
    maxWidth: 400,
  },
  skeleton: {
    width: "98%",
    height: "30px",
    marginBottom: theme.spacing(1),
  },
  skeletonInfoCompany: {
    width: "100%",
    height: "20px",
    marginBottom: theme.spacing(1),
  },
}));

export default PartnerDetailsStyles;
