import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
import { format } from "date-fns";
import { es } from "date-fns/locale";

export const printDocument = async (printRef) => {
  const element = printRef.current;
  const canvas = await html2canvas(element);
  const imgData = canvas.toDataURL("image/png");
  var imgWidth = 210;
  var pageHeight = 285;
  var imgHeight = (canvas.height * imgWidth) / canvas.width;
  var heightLeft = imgHeight;

  const unit = "mm";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape
  const doc = new jsPDF(orientation, unit, size);
  var position = 0;

  doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }
  doc.save("fichas.pdf");
};

export const CreateTablePDFAudits = (cssLocator, selectedAuditRoute) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);

  // Título del documento
  let title = "";

  // Si selectedAuditRoute no está presente, no se intenta acceder a description
  if (selectedAuditRoute && selectedAuditRoute.description) {
    title = `Auditoria - ${selectedAuditRoute.description}`;
  } else {
    title = `Auditoria`;
  }

  // Centrar el título
  doc.setFontSize(15);
  doc.setFont("Helvetica", "bold");
  const titleWidth = doc.getTextWidth(title);
  const pageWidth = doc.internal.pageSize.width;
  const titleX = (pageWidth - titleWidth) / 2; // Calcular posición centrada
  doc.text(title, titleX, 40);

  doc.setFontSize(10);

  autoTable(doc, {
    html: `${cssLocator}`,
    startY: 90,
  });

  // Guardar el PDF
  doc.save("report.pdf");
};

export const CreateTablePDFDailyInstallments = (
  cssLocator,
  selectedDate,
  selectedAuditRoute
) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);

  // Definir la fecha actual si no se pasa 'selectedDate'
  const today = new Date();
  const validSelectedDate =
    selectedDate instanceof Date && !isNaN(selectedDate) ? selectedDate : today;

  // Formatear las fechas
  const formattedToday = format(today, "EEEE, d 'de' MMMM 'del' yyyy", {
    locale: es,
  });
  const formattedSelectedDate = format(
    validSelectedDate,
    "EEEE, d 'de' MMMM 'del' yyyy",
    { locale: es }
  );

  // Título del documento
  let title = "Cobranza diaría ruta {Nombre de la ruta}";

  // Si selectedAuditRoute no está presente, no se intenta acceder a description
  if (selectedAuditRoute && selectedAuditRoute.description) {
    title = `Cobranza diaría de la ruta ${selectedAuditRoute.description}`;
  } else {
    title = `Cobranza diaría`;
  }

  // Centrar el título
  doc.setFontSize(15);
  doc.setFont("Helvetica", "bold");
  const titleWidth = doc.getTextWidth(title);
  const pageWidth = doc.internal.pageSize.width;
  const titleX = (pageWidth - titleWidth) / 2; // Calcular posición centrada
  doc.text(title, titleX, 40);

  const generatedText = "Reporte generado el ";
  const collectedText = "\nReporte del día ";

  doc.setFontSize(10);

  // Primer texto en gris
  doc.setFont("Helvetica", "normal");
  doc.setTextColor(150, 150, 150);
  doc.text(generatedText, 40, 60);

  // Fecha actual en negrita y negro
  doc.setFont("Helvetica", "bold");
  doc.setTextColor(0, 0, 0);
  doc.text(formattedToday, doc.getTextWidth(generatedText) + 35, 60);

  // Segundo texto en gris
  doc.setFont("Helvetica", "normal");
  doc.setTextColor(150, 150, 150);
  doc.text(collectedText, 40, 60);

  // Fecha seleccionada en negrita y negro
  doc.setFont("Helvetica", "bold");
  doc.setTextColor(0, 0, 0);
  doc.text(formattedSelectedDate, doc.getTextWidth(collectedText) + 35, 72);

  autoTable(doc, {
    html: `${cssLocator}`,
    startY: 90,
  });

  // Guardar el PDF
  doc.save("report.pdf");
};
