import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../../constants/styleConstants";

const PayInvoiceStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 300,
    minHeight:600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    paddingBottom:10
  },
  title: {
    fontSize: 24,
    color: theme.palette.primary.text_dark,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    paddingRight:32
  },
  message:{
    fontSize: 12,
    marginBottom: 15,
    marginTop:5,
    color: theme.palette.primary.text_disable_medium,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    textAlign: "initial"
  },
  amountTitle: {
    marginBottom: 5,
    fontSize:15,
    color: theme.palette.primary.text_disable_medium,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    
  },
  amount: {
    fontSize:24,
    color: theme.palette.primary.text_dark,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    paddingRight:100,
    marginBottom:15
  },
  pay: {
    marginBottom: 5,
    fontSize:15,
    color: theme.palette.primary.text_disable_medium,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    paddingRight:75
  },
  restAmount: {
    marginBottom: 5,
    fontSize:15,
    color: theme.palette.primary.text_disable_medium,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    paddingRight:110, 
    marginTop: 10
  },
  comments:{
    marginBottom: 5,
    fontSize:15,
    color: theme.palette.primary.text_disable_medium,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    paddingRight:37, 
  },
  paymentButton: {
    width: 150,
    height: 40,
    borderRadius: 5,
    marginTop: 20,
  },
  closeIconContainerMain: {
    marginLeft: 250,
    marginTop: 5,
  },
  commentsField: {
    marginTop: 10,
  },
  commentsMultiline: {
    maxHeight: 70,
    minHeight: 70,
    maxWidth: 210,
    minWidth: 190,
  },
  containerBox:{
    display: "flex",
    justifyContent: 'flex-start',
    alignItems:"flex-start;",
    flexDirection:"column",
    paddingLeft:37
  }
}));

export default PayInvoiceStyles;
