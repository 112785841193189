import React, { useEffect, useState } from "react";
import DrawerMenu from "../../components/DrawerMenu/Index";
import {
  Box,
  Typography,
  TextField,
  Tooltip,
  IconButton,
  InputAdornment,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Switch,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import CustomBreadcrumbs from "../../components/sharedComponents/CustomBreadcrumbs";
import AdminCard from "../../components/sharedComponents/AdminCard";
import imageDevice from "../../assets/images/homeDevice.svg";
import imageTotalClients from "../../assets/images/totaClients.svg";
import imageCompanies from "../../assets/images/companies.svg";
import show from "../../assets/images/show.svg";
import CompaniesStyles from "./Companies.styles";
import { BsSearch } from "react-icons/bs";
import { Skeleton } from "@mui/material";
import { getInfoCompanies, updateCompanyStatus } from "../../api/apiCompanies";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../store/slices/clients";
import {
  selectClientsCompanies,
  selectCompanies,
  selectDataTableCompanies,
  selectInvoicesCompanies,
  setClientsCompanies,
  setCompanies,
  setDataTableCompanies,
  setInvoicesCompanies,
} from "../../store/slices/companies";
import CreateCompany from "../../components/ModalBodies/CreateCompany";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import DialogBodyModal from "../../components/ModalBodies/DialogBodyModal";
import { notification } from "../../utils/notifications";
import edit from "../../assets/images/paid.svg";
import EditCompany from "../../components/ModalBodies/EditCompany";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { errors } from "../../utils/ErrorAxios";
import useLogout from "../../hooks/useLogout";

const Companies = () => {
  const classes = CompaniesStyles();
  const history = useHistory();
  const breadcrumbLinks = [
    { href: "/administrador", text: "Administrador" },
    { href: "/administrador", text: "Cobranza" },
    { href: "/administrador/cobranza/altaEmpresa", text: "Alta empresa" },
  ];
  const [loading, setLoading] = useState(false);
  const [changeCompleted, setChangeCompleted] = useState(false);
  const isUp = true;

  // Modal crear
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //Modal Editar
  const [companyId, setcompanyId] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenEdit = (companyId) => {
    setOpenEdit(true);
    setcompanyId(companyId);
  };
  // Redux
  const companies = useSelector(selectCompanies);
  const clients = useSelector(selectClientsCompanies);
  const invoices = useSelector(selectInvoicesCompanies);
  const dataTableCompanies = useSelector(selectDataTableCompanies);

  // table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const accesToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  // Logica para el cambio de status
  const [openChangeStatusCompany, setOpenChangeStatusCompany] = useState(false);
  const [isThisCompanyActive, setIsThisCompanyActive] = useState(false);
  const handleCloseChangeStatusCompany = () =>
    setOpenChangeStatusCompany(false);
  const [idCompanyChange, setIdCompanyChange] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [logout] = useLogout();

  useEffect(() => {
    setLoading(true);
    getCompanies();
  }, [accesToken, dispatch, changeCompleted]);

  const getCompanies = () => {
    getInfoCompanies(accesToken)
      .then((res) => {
        if (res.data.statusCode === 200) {
          const data = res.data.body;
          dispatch(setCompanies(data.companies));
          dispatch(setClientsCompanies(data.clients));
          dispatch(setInvoicesCompanies(data.invoices));
          dispatch(setDataTableCompanies(data.companiesDataTable));
          setLoading(false);
        }
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      });
  };

  const filteredCompanies =
    dataTableCompanies.length > 0 &&
    dataTableCompanies
      .filter((empresa) =>
        empresa?.legal_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .filter((empresa) => {
        if (statusFilter === "") return true; // Si no hay filtro, mostrar todos
        return empresa.status !== Boolean(statusFilter); // Filtrar por estado
      });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStateChange = (company_id, status) => {
    setOpenChangeStatusCompany(true);
    setIsThisCompanyActive(status);
    setIdCompanyChange(company_id);
  };

  const handleChangeCompanyStatus = async () => {
    setChangeCompleted(true);
    const payload = {
      idCompany: idCompanyChange,
      status: !isThisCompanyActive,
    };
    updateCompanyStatus(payload)
      .then((res) => {
        handleCloseChangeStatusCompany();
        notification("estado actualizado correctamente", "success");
        setChangeCompleted(false);
      })
      .catch(({ err }) => {
        errors(err, "No se puede actualizar", logout);
        handleCloseChangeStatusCompany();
      });
  };
  const handleOpenCompanyDetails = (companyName, companyId) => {
    history.push(
      `/administrador/cobranza/altaEmpresa/detalles/${companyName}/${companyId}`
    );
  };

  // mandar como prop cliente y estatus
  const renderSwitch = (company_id, status) => (
    <div style={{ display: "flex", alignItems: "center", marginLeft: 12 }}>
      <span>Si</span>
      <Switch
        checked={status === false}
        onChange={() => handleStateChange(company_id, status)}
        classes={{
          switchBase: classes.switchBase,
          checked: classes.checked,
          track: classes.track,
          thumb: classes.thumb,
        }}
        style={{ marginLeft: 5 }}
      />
      <span>No</span>
    </div>
  );

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  return (
    <div>
      {dataTableCompanies && (
        <DrawerMenu>
          <Box className={classes.mainContainer}>
            <Box style={{ marginLeft: 10 }}>
              <CustomBreadcrumbs links={breadcrumbLinks} />
            </Box>
            <Typography className={classes.title}>Empresas</Typography>
            {/* Cards */}
            <Box className={classes.cardsContainer}>
              <AdminCard
                title="Total empresas"
                number={companies.totalCompanies}
                newAvance={companies.weeklyCompanies}
                image={imageCompanies}
                message="nuevos esta semana"
                loading={loading}
                isUp={isUp}
              />
              <AdminCard
                title="Total clientes"
                number={clients.totalClients}
                newAvance={clients.newWeeklyClients}
                image={imageTotalClients}
                message="de alcance semanal"
                loading={loading}
                isUp={isUp}
              />
              <AdminCard
                title="Facturas"
                number={invoices.totalInvoices}
                newAvance={invoices.newWeeklyInvoices}
                image={imageDevice}
                message="nuevos esta semana"
                loading={loading}
                isUp={isUp}
              />
            </Box>
            {/* Filter */}
            <Box
              className={classes.searchBarContainer}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <TextField
                  name="searchbar"
                  variant="outlined"
                  placeholder="Buscar empresas"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="contrasena" edge="start">
                          <BsSearch />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={classes.searchBarInputfield}
                />
              </Box>
              <Box style={{ paddingRight: 23 }}>
                <FormControl>
                  <Select
                    className={classes.paramsDropdown}
                    variant="outlined"
                    name="status"
                    placeholder="status"
                    label="status"
                    value={statusFilter}
                    onChange={handleStatusChange}
                    displayEmpty
                  >
                    <MenuItem value="" className={classes.status}>
                      Todas
                    </MenuItem>
                    <MenuItem value={false}>Activo</MenuItem>
                    <MenuItem value={true}>Inactivo</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addClientButton}
                  onClick={handleOpen}
                >
                  Agregar empresa
                </Button>
              </Box>
            </Box>
            {/* Table */}
            <TableContainer
              component={Paper}
              className={classes.tableComponent}
            >
              <Table className={classes.table} aria-label="invoice table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>ID</TableCell>
                    <TableCell className={classes.tableHead}>EMPRESA</TableCell>
                    <TableCell className={classes.tableHead}>CIUDAD</TableCell>
                    <TableCell className={classes.tableHead}>
                      CLIENTES
                    </TableCell>
                    <TableCell className={classes.tableHead}>ACTIVA</TableCell>
                    <TableCell className={classes.tableHead}>
                      FACTURAS
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      ACCIONES
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? Array.from(new Array(5)).map((_, index) => (
                        <TableRow key={index}>
                          <TableCell colSpan={7}>
                            <Skeleton className={classes.skeleton} />
                          </TableCell>
                        </TableRow>
                      ))
                    : filteredCompanies.length > 0 &&
                      filteredCompanies
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((empresa) => (
                          <TableRow key={empresa.id_company}>
                            <TableCell className={classes.tableBody}>
                              {empresa.company_id}
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              {empresa.legal_name}
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              {empresa.municipality}
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              {empresa.total_clients}
                            </TableCell>
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              {renderSwitch(empresa.company_id, empresa.status)}
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              {empresa.total_invoices}
                            </TableCell>
                            <TableCell className={classes.tableBody}>
                              <Tooltip title="Ver detalle">
                                <Button
                                  className={classes.iconButton}
                                  onClick={() =>
                                    handleOpenCompanyDetails(
                                      empresa.legal_name,
                                      empresa.company_id
                                    )
                                  }
                                >
                                  <img src={show} alt="ver" />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Editar">
                                <Button
                                  className={classes.iconButton}
                                  onClick={() =>
                                    handleOpenEdit(empresa.company_id)
                                  }
                                  disabled={empresa.status === false}
                                >
                                  <img src={edit} alt="ver" />
                                </Button>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredCompanies.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página"
              />
            </TableContainer>

            {/* Create company */}
            <ModalComponent
              handleClose={handleClose}
              open={open}
              body={<CreateCompany handleClose={handleClose} />}
            />

            {/* Edit company */}
            <ModalComponent
              handleClose={handleCloseEdit}
              open={openEdit}
              body={
                <EditCompany
                  handleClose={handleCloseEdit}
                  companyId={companyId}
                  setChangeCompleted={setChangeCompleted}
                />
              }
            />
            {/* status company */}

            <ModalComponent
              handleClose={handleCloseChangeStatusCompany}
              open={openChangeStatusCompany}
              body={
                <DialogBodyModal
                  titleDialog="¿Estas Seguro?"
                  subtitleDialog={`¿Estas seguro de ${
                    isThisCompanyActive ? "desactivar" : "activar"
                  } esta empresa?`}
                  handleCancel={handleCloseChangeStatusCompany}
                  handleConfirm={handleChangeCompanyStatus}
                />
              }
            />
          </Box>
        </DrawerMenu>
      )}
    </div>
  );
};

export default Companies;
