import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import FilterComponentStyles from "./FilterComponent.styles";

const FilterComponent = ({ filters, handleInputChange }) => {
  const classes = FilterComponentStyles();
  return (
    <Box>
      <Typography>Filtra la información correspondiente</Typography>
      <Box className={classes.filterComponent}>
        <TextField
          variant="outlined"
          name="id"
          placeholder="ID cliente"
          value={filters.id}
          onChange={handleInputChange}
        />
        <TextField
          variant="outlined"
          name="municipaly"
          placeholder="Municipio"
          value={filters.municipaly}
          onChange={handleInputChange}
        />

        <TextField
          variant="outlined"
          name="cp"
          placeholder="Código Postal"
          value={filters.cp}
          onChange={handleInputChange}
        />
        <FormControl>
          <Select
            className={classes.paramsDropdown}
            variant="outlined"
            name="status"
            value={filters.status}
            onChange={handleInputChange}
          >
            <MenuItem value={1}>Activo</MenuItem>
            <MenuItem value={0}>Inactivo</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default FilterComponent;
