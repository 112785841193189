export const INVOICES_STATUS = {
  1: "Pendiente",
  2: "Saldada",
  3: "Cancelada",
};

export const INVOICES_STATUS_NEW = {
  1: "Pendiente",
  2: "Pagado",
  3: "Cancelado",
};

// Definir los estados disponibles
export const INVOICE_STATES = [
  "TODAS",
  "Pagada",
  "Pendiente",
  "Cancelada",
  "CREDITO",
  "CONTADO",
];

export const weeklyDays = [
  { value: 1, label: "LUNES" },
  { value: 2, label: "MARTES" },
  { value: 3, label: "MIERCOLES" },
  { value: 4, label: "JUEVES" },
  { value: 5, label: "VIERNES" },
  { value: 6, label: "SABADO" },
];
