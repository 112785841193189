import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    companies: [],
    clients: [],
    invoices: [],
    dataTableCompanies:[]
};
export const companiesReducer = createSlice({
    name: "companies",
    initialState,
    reducers:{
        setCompanies(state,{payload}){
            state.companies = payload;
        },
        setClientsCompanies(state,{payload}){
            state.clients = payload;
        },
        setInvoicesCompanies(state,{payload}){
            state.invoices = payload;
        },
        setDataTableCompanies(state,{payload}){
            state.dataTableCompanies = payload;
        },
    }
})
export const {
    setCompanies,
    setClientsCompanies,
    setInvoicesCompanies,
    setDataTableCompanies
} = companiesReducer.actions;

export const selectCompanies = (state) => state.companies.companies;
export const selectClientsCompanies = (state) => state.companies.clients;
export const selectInvoicesCompanies = (state) => state.companies.invoices;
export const selectDataTableCompanies = (state) => state.companies.dataTableCompanies;


export default companiesReducer.reducer;