import React, { useEffect, useReducer, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../store/slices/clients";
import {
  getClientInvoicesByIdClient,
  getInvoicesFromClient,
} from "../../api/apiClients";
import DrawerMenu from "../../components/DrawerMenu/Index";
import { Box, TablePagination, Tooltip, Typography } from "@material-ui/core";
import ClientInvoiceStyles from "./ClientInvoice.styles";
import InvoiceCardStatus from "../../components/sharedComponents/InvoiceCardStatus";
import imgPaid from "../../assets/images/Check.svg";
import imgDebt from "../../assets/images/Clock.svg";
import imgCancel from "../../assets/images/cancel.svg";
import StatusTabsInvoice from "../../components/sharedComponents/StatusTabsInvoice";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import show from "../../assets/images/show.svg";
import paid from "../../assets/images/paid.svg";
import cancel from "../../assets/images/cancelPaid.svg";
import { formatNumber } from "../../utils/formatUtils";
import { INVOICE_STATES } from "../../constants/general";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import {
  getPaymentsByIdInvoice,
  insertPaymentInvoice,
} from "../../api/ApiPayments";
import InvoicesPayments from "../../components/ModalBodies/InvoicesPayments";
import PayInvoice from "../../components/ModalBodies/PayInvoice";
import AlertMessage from "../../components/sharedComponents/AlertMessage";
import { setTotalInvoicesInfo } from "../../store/slices/invoices";
import { getInvoices } from "../../api/ApiInvoices";
import CancelInvoiceByIdModal from "../../components/ModalBodies/CancelInvoiceByIdModal";
import CustomBreadcrumbs from "../../components/sharedComponents/CustomBreadcrumbs";
// moment
import moment from "moment-timezone";
import "moment/locale/es"; // Importa la localización en español
import { errors } from "../../utils/ErrorAxios";
import useLogout from "../../hooks/useLogout";
moment.locale("es"); // Configura moment para usar español

const ClientInvoice = () => {
  const { clientId, userNameClient } = useParams();
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = ClientInvoiceStyles();
  const [availableInvoices, setAvailableInvoices] = useState([]);
  // const [userInformation, setUserInformation] = useState([]);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalPending, setTotalPending] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalCanceled, setTotalCanceled] = useState(0);
  const [remainingAmountCanceled, setRemainingAmountCanceled] = useState(0);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [pendingCount, setPendingCount] = useState("");
  const [paidCount, setPaidCount] = useState("");
  const [paidTotal, setpaidTotal] = useState(0);
  const [canceledCount, setCanceledCount] = useState(0);
  const [openPaymentInvoicesInfo, setopenPaymentInvoicesInfo] = useState(false);
  const [payments, setPayments] = useState([]);
  const [idPaymentSelected, setIdPaymentSelected] = useState("");
  // Esto es del modal de pay
  const handleClosePayInvoice = () => setOpenPayInvoice(false);
  const [openPayInvoice, setOpenPayInvoice] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentInvoiceSelected, setCurrentInvoiceSelected] = useState({});
  const [loadingPay, setLoadingPay] = useState(false);
  // Esto es de modal cancel
  const [openCancelInvoiceModal, setOpenCancelInvoiceModal] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  // table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [finishCancel, setfinishCancel] = useState(false);
  const [logout] = useLogout();

  useEffect(() => {
    getClientInvoicesByIdClient(clientId, accessToken).then((res) => {
      const invoices = res.data.body;
      setAvailableInvoices(invoices);
      setFilteredInvoices(invoices);
      setLoading(false);

      getInvoicesFromClient(clientId, accessToken)
        .then((res) => {
          const dataGeneral = res.data.body.general_InvoicesByClient[0];
          setInvoiceCount(
            parseInt(dataGeneral.total_invoices_pending) +
              parseInt(dataGeneral.total_invoices_paid) +
              parseInt(dataGeneral.total_invoices_canceled)
          );
          const totalFormat = formatNumber(
            parseInt(dataGeneral.total_amount_invoices)
          );
          setTotalAmount(totalFormat);
          setTotalPending(
            formatNumber(parseInt(dataGeneral.remaining_payment_pending))
          );
          setTotalPaid(formatNumber(parseInt(dataGeneral.paid_amount)));

          // Paid
          const dataCardPaid = res.data.body.paid_Invoice[0];
          setPaidCount(dataCardPaid.total_invoices);
          setpaidTotal(dataCardPaid.total_amount);

          // Pending
          const dataCardPending = res.data.body.pendig_Invoice[0];
          setPendingCount(
            formatNumber(parseInt(dataCardPending.total_invoices))
          );

          // Cancel
          const dataCardCanceled = res.data.body.cancel_Invoice[0];

          setTotalCanceled(
            formatNumber(parseInt(dataCardCanceled.total_amount))
          );
          setCanceledCount(
            formatNumber(parseInt(dataCardCanceled.total_invoices))
          );
          setRemainingAmountCanceled(
            formatNumber(parseInt(dataCardCanceled.remaining_amount))
          );

          // Segunda API
          getClientInvoicesByIdClient(clientId, accessToken)
            .then((res) => {
              const invoices = res.data.body;
              setAvailableInvoices(invoices);
              setFilteredInvoices(invoices);
              setLoading(false);
            })
            .catch(({ err }) => {
              errors(err, "No se pudo obtener la información", logout);
            });
        })
        .catch(({ err }) => {
          errors(err, "No se pudo obtener la información", logout);
        });
    });
  }, [
    clientId,
    accessToken,
    dispatch,
    history,
    totalAmount,
    loadingPay,
    finishCancel,
  ]);

  const getStatusClass = (status) => {
    switch (status) {
      case "PENDIENTE":
        return classes.pending;
      case "PAGADA":
        return classes.paid;
      case "CANCELADA":
        return classes.cancelled;
      default:
        return "";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "PENDIENTE":
        return "Pendiente";
      case "PAGADA":
        return "Pagada";
      case "CANCELADA":
        return "Cancelada";
      default:
        return "TODAS";
    }
  };
  const getPaymentTypeText = (order_type) => {
    switch (order_type) {
      case "CONTADO":
        return "CONTADO";
      case "CREDITO":
        return "CREDITO";
      default:
        return "TODAS";
    }
  };

  const handleStatusChange = (statusIndex) => {
    const selectdStatus = INVOICE_STATES[statusIndex];

    if (selectdStatus === "TODAS") {
      setFilteredInvoices(availableInvoices);
    } else if (selectdStatus === "CREDITO" || selectdStatus === "CONTADO") {
      const filtered = availableInvoices.filter(
        (invoice) => getPaymentTypeText(invoice.order_type) === selectdStatus
      );
      setFilteredInvoices(filtered);
    } else {
      const filtered = availableInvoices.filter(
        (invoice) => getStatusText(invoice.status) === selectdStatus
      );
      setFilteredInvoices(filtered);
    }
  };
  const [loaderInvoiceInfo, setloaderInvoiceInfo] = useState(false);

  // View
  const handleOpenUserInvoicesInformation = (InvoiceId) => {
    setloaderInvoiceInfo(true);
    setIdPaymentSelected(InvoiceId);
    setopenPaymentInvoicesInfo(true);
    getPaymentsByIdInvoice(InvoiceId, accessToken)
      .then((res) => {
        setPayments(res.data.body);
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      })
      .finally(() => {
        // Ocultar el skeleton después de recibir la respuesta
        setloaderInvoiceInfo(false);
      });
  };

  const handleCloseUserInvoicesInformation = () => {
    setopenPaymentInvoicesInfo(false);
    setIdPaymentSelected("");
    setPayments([]);
  };
  // Pay
  const snackBarReducer = (state, action) => {
    switch (action.type) {
      case "SUCCESS":
        return { ...state, ...action.value };
      case "ERROR":
        return { ...state, ...action.value };
      case "INITIAL":
        return { ...state, open: false };
      default:
        return { ...state };
    }
  };

  const [snackBarState, dispatchSnackBar] = useReducer(snackBarReducer, {
    open: false,
    type: "",
    message: "",
  });

  const handleOpenPayInvoice = (invoice) => {
    setCurrentInvoiceSelected(invoice);
    setOpenPayInvoice(true);
  };

  const handleOpenAlert = () => {
    setOpen(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleSubmitPayment = (payload) => {
    setLoadingPay(true);
    insertPaymentInvoice(payload, accessToken)
      .then((res) => {
        dispatchSnackBar({
          type: "SUCCESS",
          value: {
            open: open,
            type: "success",
            message: "El abono se realizó con éxito",
          },
        });
        setLoadingPay(false);
        handleClosePayInvoice();
        setTimeout(() => {
          dispatchSnackBar({
            type: "INITIAL",
            value: {
              open: false,
              type: "",
              message: "",
            },
          });
        }, 6000);
        getInvoices(accessToken).then((res) => {
          dispatch(setTotalInvoicesInfo(res.data.body));
        });
      })
      .catch(({ err }) => {
        errors(err, "No se pudo obtener la información", logout);
      })
      .finally(() => {
        setLoadingPay(false);
      });
  };

  // Cancel
  const handleClickOpenCancelInvoiceModal = (invoice) => {
    setOpenCancelInvoiceModal(true);
    setCurrentInvoice(invoice);
  };

  const handleClickCloseCancelInvoiceModal = () => {
    setOpenCancelInvoiceModal(false);
    setCurrentInvoice(null);
  };
  // Breadcrumblinks
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const breadcrumbLinks = [
    { href: "/administrador", text: "Administrador" },
    { href: "/administrador/clientes/", text: "Clientes" },
  ];

  return (
    <div>
      <DrawerMenu>
        <Box className={classes.mainContainer}>
          <CustomBreadcrumbs links={breadcrumbLinks} current={userNameClient} />
          <Typography className={classes.headerTitleSection}>
            Facturas
          </Typography>
          <Box className={classes.containetUserName}>
            <Typography className={classes.simpleText}>Cliente</Typography>
            <Typography variant="h3" className={classes.clientName}>
              {loading ? (
                <Skeleton variant="rectangular" width={500} height={30} />
              ) : (
                userNameClient
              )}
            </Typography>
          </Box>
          {/* Data */}
          <Box className={classes.containerInvoicesData}>
            <Box className={classes.boxInvoiceNumber}>
              <Typography className={classes.textInvoiceData}>
                Facturas
              </Typography>
              <Typography className={classes.numberInvoiceData}>
                {loading ? (
                  <Skeleton variant="circle" width={40} height={40} />
                ) : (
                  invoiceCount
                )}
              </Typography>
            </Box>
            <Box className={classes.boxInvoiceCommun}>
              <Typography className={classes.communText}>
                Total Facturas
              </Typography>
              <Typography className={classes.communNumber}>
                {loading ? (
                  <Skeleton variant="text" width={60} height={20} />
                ) : (
                  `$${totalAmount}`
                )}
              </Typography>
              <Typography className={classes.communText}>
                {" "}
                Adeudo Cliente
              </Typography>
              <Typography className={classes.communNumber}>
                {loading ? (
                  <Skeleton variant="text" width={60} height={20} />
                ) : (
                  `$${totalPending}`
                )}
              </Typography>
            </Box>
            <Box className={classes.boxInvoiceCommun}>
              <Typography className={classes.communText}>Saldado</Typography>
              <Typography className={classes.communNumber}>
                {loading ? (
                  <Skeleton variant="text" width={60} height={20} />
                ) : (
                  `$${totalPaid}`
                )}
              </Typography>
            </Box>
          </Box>
          {/* Cards */}
          <Box className={classes.containerInvoiceCardStatus}>
            <InvoiceCardStatus
              img={imgPaid}
              title="Pagadas"
              total={paidCount}
              value={paidTotal}
              loading={loading}
              status="paid"
            />
            <InvoiceCardStatus
              img={imgDebt}
              title="Sin pagar"
              total={pendingCount}
              value={totalPending}
              loading={loading}
              status="pending"
            />
            <InvoiceCardStatus
              img={imgCancel}
              title="Canceladas"
              total={canceledCount}
              value={totalCanceled}
              loading={loading}
              totalCanceled={remainingAmountCanceled}
              status="cancel"
            />
          </Box>
          {/* Tabs */}
          <StatusTabsInvoice onChangeStatus={handleStatusChange} />

          {/* Table */}
          <TableContainer component={Paper} className={classes.tableComponent}>
            <Table className={classes.table} aria-label="invoice table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>
                    ID Factura
                  </TableCell>
                  <TableCell className={classes.tableHead}>Creada</TableCell>
                  <TableCell className={classes.tableHead}>Total</TableCell>
                  <TableCell className={classes.tableHead}>Tipo</TableCell>
                  <TableCell className={classes.tableHead}>Adeudo</TableCell>
                  <TableCell className={classes.tableHead}>Estado</TableCell>
                  <TableCell className={classes.tableHead}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading
                  ? Array.from(new Array(2)).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan={7}>
                          <Skeleton className={classes.skeleton} />
                        </TableCell>
                      </TableRow>
                    ))
                  : filteredInvoices
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((invoice) => (
                        <TableRow key={invoice.id_invoice}>
                          <TableCell className={classes.tableBody}>
                            {invoice.id_invoice}
                          </TableCell>
                          <TableCell className={classes.tableBody}>
                            {moment(invoice.created_at)
                              .tz("America/Mexico_City")
                              .locale("es")
                              .format("D [de] MMMM [del] YYYY")}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                          >{`$${invoice.total_amount}`}</TableCell>
                          <TableCell className={classes.tableBody}>
                            {invoice.type_payment === 1 ? "Contado" : "Crédito"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                          >{`$${invoice.remaining_payment}`}</TableCell>
                          <TableCell className={classes.tableBody}>
                            <span
                              className={`${classes.status} ${getStatusClass(
                                invoice.status
                              )}`}
                            >
                              {getStatusText(invoice.status)}
                            </span>
                          </TableCell>
                          <TableCell className={classes.tableBody}>
                            <Tooltip title="Ver">
                              <Button
                                className={classes.iconButton}
                                onClick={() =>
                                  handleOpenUserInvoicesInformation(
                                    invoice.id_invoice
                                  )
                                }
                              >
                                <img src={show} alt="ver" />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Abonar">
                              <Button
                                className={classes.iconButton}
                                onClick={() => handleOpenPayInvoice(invoice)}
                                disabled={
                                  invoice.status === "CANCELADA" ||
                                  invoice.status === "PAGADA"
                                }
                              >
                                <img src={paid} alt="pagar" />{" "}
                              </Button>
                            </Tooltip>
                            <Tooltip title="Cancelar">
                              <Button
                                className={classes.iconButton}
                                onClick={() =>
                                  handleClickOpenCancelInvoiceModal(invoice)
                                }
                                disabled={
                                  invoice.status === "CANCELADA" ||
                                  invoice.status === "PAGADA"
                                }
                              >
                                <img src={cancel} alt="cancelar" />{" "}
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredInvoices.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página"
            />
          </TableContainer>
        </Box>

        {/* View invoice */}
        <ModalComponent
          handleClose={handleCloseUserInvoicesInformation}
          open={openPaymentInvoicesInfo}
          body={
            <InvoicesPayments
              idPayment={idPaymentSelected}
              payments={payments}
              handleClose={handleCloseUserInvoicesInformation}
              loaderInvoiceInfo={loaderInvoiceInfo}
            />
          }
        />
        {/* pay invoice */}
        <ModalComponent
          handleClose={handleClosePayInvoice}
          open={openPayInvoice}
          body={
            <PayInvoice
              handleClose={handleClosePayInvoice}
              invoiceInfo={currentInvoiceSelected}
              dispatchSnackBar={dispatchSnackBar}
              openAlert={open}
              handleOpenAlert={handleOpenAlert}
              handleCloseAlert={handleCloseAlert}
              handleSubmitPayment={handleSubmitPayment}
              loading={loadingPay}
              clientName={userNameClient}
            />
          }
        />
        {/* Cancel Invoice Modal */}
        <ModalComponent
          handleClose={handleClickCloseCancelInvoiceModal}
          open={openCancelInvoiceModal}
          body={
            <CancelInvoiceByIdModal
              handleClickCloseModal={handleClickCloseCancelInvoiceModal}
              invoiceinfo={currentInvoice}
              clientName={userNameClient}
              setfinishCancel={setfinishCancel}
            />
          }
        />
        {/* Modals */}

        <AlertMessage
          open={open}
          handleClose={handleCloseAlert}
          {...snackBarState}
        />
      </DrawerMenu>
    </div>
  );
};

export default ClientInvoice;
