import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../../constants/styleConstants";

const UploadClientsStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  containerForm: {
    width: 200,
    height: 55,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px dashed black",
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.6,
    },
  },
  img: {
    width: 30,
    height: 30,
  },
  text: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.76)",
    marginRight: 15,
  },
  containerUploadFile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  input: {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
}));

export default UploadClientsStyles;
