import React from "react";

import ConfirmModal from "../../sharedComponents/ConfirmModal";
const DialogBodyModal = (props) => {
  const {
    titleDialog,
    subtitleDialog,
    handleCancel,
    handleConfirm,
    loading,
  } = props;

  return (
    <ConfirmModal
      title={titleDialog}
      description={subtitleDialog}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      loading={loading}
    />
  );
};

export default DialogBodyModal;
