import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../../constants/styleConstants";

const TableAddClientsStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    marginTop: 40,
    [theme.breakpoints.down(1060)]: {
      maxWidth: 680,
    },
    [theme.breakpoints.down(960)]: {
      maxWidth: 750,
    },
    [theme.breakpoints.down(880)]: {
      maxWidth: 680,
    },
    [theme.breakpoints.down(800)]: {
      width: 600,
    },
    [theme.breakpoints.down(700)]: {
      width: 500,
    },
    [theme.breakpoints.down(600)]: {
      width: 420,
    },
    [theme.breakpoints.down(500)]: {
      width: 370,
    },
    [theme.breakpoints.down(450)]: {
      width: 320,
    },
  },
  textRowError: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    borderLeft: "1px solid  #e0e0e0",
    background: "#ff000087",
    color: "white",
    padding: 10,
    "@media (max-width: 1300px)": {
      fontSize: 12,
    },
  },
  textRow: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    borderLeft: "1px solid  #e0e0e0",
    padding: 10,
    "@media (max-width: 1300px)": {
      fontSize: 12,
    },
  },
  containerNoRecords: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down(500)]: {
      alignItems: "start",
      marginLeft: 20,
      width: 170,
    },
  },
  imageNoRecords: {
    width: 70,
    height: 70,
    marginBottom: 10,
    [theme.breakpoints.down(500)]: {
      width: 50,
      height: 50,
    },
  },
  textNoRecords: {
    [theme.breakpoints.down(500)]: {
      fontSize: 14.5,
    },
  },
}));

export default TableAddClientsStyles;
