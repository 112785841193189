import { makeStyles } from "@material-ui/core/styles";
import {
  COLOR_CONSTANTS,
  MONTSERRAT_FONTS,
} from "../../../constants/styleConstants";

const UserInformationBodyModalStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 450,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: 20,
    overflow: "auto",
    paddingBottom: 40,
  },
  titleBodyModal: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    color: COLOR_CONSTANTS.TEXT_DARK_MEDIUM,
    fontSize: 24,
    marginTop: 10,
    marginBottom: 10,
  },
  userInfoSubheader: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    color: COLOR_CONSTANTS.TEXT_DARK_DISABLED,
    marginBottom: 40,
  },
  closeIconContainerMain: {
    float: "right",
    marginTop: -15,
  },
  input: {
    width: 340,
  },
  inputSmall: {
    width: 120,
  },
  inputMedium: {
    width: 170,
  },
  inputContainer: {
    marginBottom: 10,
  },
  contentContainer: {
    paddingLeft: 20,
  },
  inputEnabled: {
    marginBottom: 10,
    marginLeft: 3,
    color: theme.palette.primary.dark,
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    width: 150,
    height: 45,
    borderRadius: 10,
    marginTop: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  changePasswordCheck: {
    marginTop: 5,
    marginBottom: 5,
  },
  editSwitchOpen: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_REGULAR,
    fontSize: 14,
    color: COLOR_CONSTANTS.TEXT_DARK_DISABLED,
    marginTop:10
  },
  updateInfoBtn: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 14,
  },
}));

export default UserInformationBodyModalStyles;
