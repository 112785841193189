export const FONT_CONSTANTS = {
  FONT_FAMILY_POPPINS_NORMAL: "Poppins",
  FONT_FAMILY_POPPINS_BOLD: "PoppinsBold",
  FONT_FAMILY_POPPINS_EXTRABOLD: "PoppinsExtraBold",
  FONT_FAMILY_POPPINS_MEDIUM: "PoppinsMedium",
  FONT_FAMILY_POPPINS_SEMIBOLD: "PoppinsSemiBold",
};

export const MONTSERRAT_FONTS = {
  MONTSERRAT_BOLD: "MontserratBold",
  MONTSERRAT_EXTRABOLD: "MontserratExtraBold",
  MONTSERRAT_LIGHT: "MontserrratLight",
  MONTSERRAT_MEDIUM: "MontserratMedium",
  MONTSERRAT_REGULAR: "MontserratRegular",
  MONTSERRAT_SEMIBOLD: "MontserratSemibold",
};
export const COLOR_CONSTANTS = {
  MAIN_GREEN: "#159E60",
  DEEP_PINE: "#2a3f3f",
  EMERALD_GREEN: "#467750",
  SOFT_MINT: "#89c07e",
  PEARL_GRAY: "#e0e8e2",
  HONEY_GOLD: "#F0CD6E",
  SECONDARY_GREEN: "#00917C",
  LIGHT_GREEN: "#5FB572",
  DARK_GREEN: "#184D47",
  GREEN_60: "#035956",
  GREEN_30: "#A5F0C5",
  GREEN_10: "#A0D8C0",
  MAIN_GRAY: "#343D46",
  GRAY_50: "#343D4675",
  GRAY_40: "#fafafa",
  GRAY_30: "#BDBDBD",
  EXTRA_LIGHT: "#BAE5CC",
  APPLE_GREEN: "rgba(186, 229, 204, 0.37)",
  TEXT_DARK: "#020202",
  TEXT_DARK_MEDIUM: "#2C3130",
  TEXT_DARK_DISABLED: "rgba(2, 2, 2, 0.51)",
  TEXT_GRAY: "rgba(0, 0, 0, 0.48)",
  WHITE: "#FFFFFF",
  INACTIVE_ERROR: "#D83434",
  DANGER: "#F01E2C",
  DANGER_HOVER: "#FF2C2C",
  INACTIVE: "#F97E6D",
  DISABLE_TEXT: "#858585",
  MAIN_RED: "#F97E6D",
  DIVIDER: "#FCF5F5",
  MAIN_GREEN_B: "#E8F5EF",
  HONEY_GOLD_B: "#FCF5E2",
  MAIN_RED_B: "#FEECE9",
  TEXT_DISABLE_MEDIUM: "#666666",
  GRAY_70: "#6F6161",
  FULL_DISABLE: "#B5B5B5"
};
