import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../constants/styleConstants";

const AddClientsStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 30,
    width: "100%",
  },
  title: {
    fontSize: 32,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    color: theme.palette.primary.deep_pine,
    marginTop: 16,
    marginLeft: 15,
  },
  button: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_SEMIBOLD,
    fontSize: 15,
    marginRight: 50,
    height: 40,
    width: 180,
    textTransform: "none",
    borderRadius: 10,
    background: theme.palette.primary.soft,
  },
  contentFilters: {
    display: "flex",
    alignItems: "center",
    marginTop: 40,
  },
  contentButton: {
    width: "100%",
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default AddClientsStyles;
